.ApproveGroupTitBox{
    width: 970px;
    height: 74px;
    margin: 0 auto;
    border-bottom: 2px solid #E5E5E5;
}
.ApproveGroupName{
    float: left;
    width: auto;
    max-width: 700px;
    height: 20px;
    line-height: 20px;
    text-indent: 1em;
    text-align: left;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #000000;
    margin: 40px 10px 0 0;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.ApproveStateBtn{
    display: block;
    float: right;
    width: auto;
    min-width: 169px;
    height: 43px;
    line-height: 42px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    border: 0;
    outline: none;
    color: #FFFFFF;
    background-color: #6EC2FF;
    border-radius:10px;
    cursor: pointer;
    margin: 20px 25px 0 0;
    padding: 0 16px;
}
.ApproveMainBox{
    width: 970px;
    height: auto;
    margin: 0 auto;
}
.NotApproveConBox{
    width: auto;
    height: auto;
    min-height: 100px;
    margin: 112px auto;
}
.NotApproveConBoxTit{
    width: 100%;
    height: 18px;
    line-height: 18px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #868686;
    margin: 0 0 44px 0;
}
.ApproveHaveHeader{
    width: 100%;
    height: 18px;
    padding: 8px 0;
    line-height: 18px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #868686;
}
.ApproveHaveHeaderList{
    float: left;
}
.ApproveHaveConList{
    float: left;
    margin-top: 42px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.ApproveHaveCon{
    width: 100%;
    height: 120px;
    margin: 18px 0;
    line-height: 18px;
    text-align: center;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #000000;
}
.ApproveDeviceLogoBox{
    position: relative;
    width: 120px;
    height: 120px;
    margin: 0 auto;
}
img.ApproveDeviceLogo{
    display: block;
    width: 100%;
    height: 100%;
}
.ApproveDeviceLogoHoverBox{
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 120px;
    background-color: rgba(0,0,0,0.5);
    cursor: pointer;
    z-index: -1;
}
.ApproveDeviceLogoBox:hover .ApproveDeviceLogoHoverBox{
    z-index: 6;
}
.BrandName{
    width: 100px;
    height: 50px;
    padding: 10px;
    line-height: 18px;
    text-align: left;
    font-size: 12px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    word-break:break-all;
}
.ApproveDeviceReview{
    width: 100px;
    height: 20px;
    padding: 0 10px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-decoration: underline;
    color: #FFFFFF;
}
span.NotYearOrNotApp{
    color: #6EC2FF;
    text-decoration: underline;
    cursor: pointer;
}


/*流程Step的样式表*/
.ApproveMainStepTitle{
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #242424;
    padding: 32px 0;
}
.ApproveMainStepBox{
    width: 420px;
    height: 32px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.NewAppStep1{
    background-image: url("./approveimage/NewAppStep1.png");
}
.NewAppStep2{
    background-image: url("./approveimage/NewAppStep2.png");
}
.NewAppStep3{
    background-image: url("./approveimage/NewAppStep3.png");
}
.NewAppStep4{
    background-image: url("./approveimage/NewAppStep4.png");
}
.NewAppStep5{
    background-image: url("./approveimage/NewAppStep5.png");
}
.NewAppStep6{
    background-image: url("./approveimage/NewAppStep6.png");
}
.NewAppStep7{
    background-image: url("./approveimage/NewAppStep7.png");
}
.NewAppStepListBox{
    float: left;
    width: 60px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    text-decoration: underline;
    font-size: 20px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #999999;
}
.NewApproveStep1ConBox{
    width: 100%;
    height: auto;
    margin: 60px 0;
}
.NewAppStepDownloadBox{
    position: relative;
    display: table;
    width: auto;
    height: 20px;
    margin: 0 auto;
    line-height: 20px;
    text-align: center;
    text-decoration: underline;
    font-size: 18px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #6EC2FF;
    cursor: pointer;
}
a.NewAppStepDownload{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
}
.NewAppStepDownloadWord{
    width: 100%;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #818181;
    margin: 20px 0;
}
.NewApproveUploadBox{
    position: relative;
    width: 128px;
    height: 42px;
    margin: 60px auto;
}
.ActiveUploadFileBtn{
    position: absolute;
    top: 0;
    left: 0;
    width: 128px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    border-radius:5px;
    background-color: #6EC2FF;
}
.NewApproveUploadBox .FileNameBox{
    width: 180px;
    float: right;
    margin-right: -20px;
}
.NewAppStepNext{
    display: block;
    width:269px;
    height:48px;
    margin: 10px auto;
    background-color: #1C7DC4;
    box-shadow:0 2px 10px 0 rgba(0, 0, 0, 0.82);
    border-radius:5px;
    line-height: 48px;
    text-align: center;
    font-size: 22px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    border: 0;
    outline: none;
    cursor: pointer;
}
.UploadFileExplain{
    width: 600px;
    height: auto;
    min-height: 20px;
    margin: 0 auto;
    line-height: 20px;
    text-indent: 2em;
    text-align: left;
    font-size:14px;
    font-family: MicrosoftYaHei,sans-serif;
    font-weight:bold;
    letter-spacing: 0.15em;
    color: #818181;
}
.NewApproveStepExplanationBox{
    width: 100%;
    height: auto;
    margin: 100px auto;
}
.NewAppExplanationTit{
    width: auto;
    height: 20px;
    margin: 60px auto;
    line-height: 20px;
    text-align: center;
    font-size:18px;
    font-family: MicrosoftYaHei,sans-serif;
    font-weight:bold;
    letter-spacing: 0.2em;
    color: #818181;
}

.NewApproveStepEndBox{
    width: 100%;
    height: auto;
    margin: 60px auto;
}
.NewAppStepEndTitBox{
    display: table;
    width: auto;
    height: 53px;
    margin: 0 auto;
}
.NewAppStepEndTitIco{
    float: left;
    width: 53px;
    height: 53px;
    margin-right: 24px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../style/image/SuccessIco.png");
}
.NewAppStepEndTitWord{
    float: left;
    width: auto;
    height: 53px;
    line-height: 52px;
    text-align: center;
    font-size:24px;
    font-family: MicrosoftYaHei,sans-serif;
    font-weight:500;
    letter-spacing: 0.15em;
    color: #888888;
}
.WriteAddress{
    display: table;
    width: auto;
    height: 20px;
    margin: 80px auto;
    line-height: 20px;
    text-align: center;
    text-decoration: underline;
    font-size:14px;
    font-family: MicrosoftYaHei,sans-serif;
    font-weight:bold;
    letter-spacing: 0.15em;
    color: #6EC2FF;
    cursor: pointer;
}
.BackTableInfo{
    display: table;
    width: auto;
    height: 24px;
    margin: 0 auto;
    line-height: 24px;
    text-align: center;
    text-decoration: underline;
    font-size:21px;
    font-family: MicrosoftYaHei,sans-serif;
    font-weight:500 ;
    letter-spacing: 0.15em;
    color: #575757;
    cursor: pointer;
}
.AllYearStep1{
    background-image: url("./approveimage/AllYearStep1.png");
}
.AllYearStep2{
    background-image: url("./approveimage/AllYearStep2.png");
}
.AllYearStep3{
    background-image: url("./approveimage/AllYearStep3.png");
}
.AllYearStep4{
    background-image: url("./approveimage/AllYearStep4.png");
}
.AppRecordMainBox{
    width: 100%;
    height: 540px;
}



.NewApproveUpDataBox{
    width: 480px;
    height: 28px;
    margin: 60px auto;
}
.NewApproveUpDataWord{
    float: left;
    width: auto;
    height: 28px;
    line-height: 28px;
    text-align: left;
    font-size:17px;
    font-family: PingFangBold,sans-serif;
    font-weight:bold ;
    color: #22ac38;
    cursor: pointer;
}
.NewApproveUpDataIco{
    float: right;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background-color: #22ac38;
    cursor: pointer;
}
.NewApproveFileName{
    float: left;
    width: 450px;
    height: 28px;
    line-height: 28px;
    text-align: left;
    font-size:14px;
    font-family: PingFangBold,sans-serif;
    font-weight:500 ;
    color: #222;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.NewApproveUpDataBtn{
    width:98px;
    height:32px;
    margin: 16px auto;
    background-color: #22ac38;
    border-radius:4px;
    line-height: 32px;
    text-align: center;
    font-size: 17px;
    font-family:PingFangBold,sans-serif;
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
}


















.icon-upward::before {
    height: .65em; width: .65em;
    border-style: solid;
    border-width: 2px 0 0 2px;
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
}
.icon-upward::after {
    height: .8em;
    border-left: 2px solid;
    top: 55%;
}




















