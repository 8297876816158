.ActiveSignUpIco{
    width: 200px;
    height: 224px;
    margin: 38px auto;
    cursor: pointer;
}
.ActiveSignUpIco:hover{
    width: 230px;
    height: 260px;
    margin: 20px auto;
}
.ActiveSignUpIcoImg{
    display: block;
    width: 100%;
    height: auto;
    padding: 20px 0;
}
.BackBottomMain{
    width: 100%;
    height: auto;
    min-height: 678px;
}
.ActiveSingUpExplanation{
    width: 600px;
    height: auto;
    min-height: 160px;
    margin: 0 auto;
    padding-top: 20px;
}





.ErCodeBtn{
    width: 200px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    font-weight: bold;
    margin: 10px auto;
    cursor: pointer;
}
.ActiveCodeTipsIco{
    float: left;
    width: 30px;
    height: 30px;
    margin: 0 10px 0 10px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E  %3Cg fill='none' fill-rule='evenodd'%3E    %3Cpath fill='%23FFF' d='M0 40h39.852V0H0z' opacity='0'/%3E    %3Cpath fill='%2307C160' d='M15.382 23.872a1.11 1.11 0 0 1-1.47-.449l-.072-.159-3.034-6.658a.555.555 0 0 1 .835-.68l3.58 2.55a1.658 1.658 0 0 0 1.48.17L33.54 11.15c-3.018-3.558-7.989-5.883-13.614-5.883-9.205 0-16.666 6.218-16.666 13.89 0 4.184 2.244 7.952 5.758 10.499a1.108 1.108 0 0 1 .408 1.258c-.28 1.047-.73 2.723-.75 2.802-.036.131-.09.269-.09.406 0 .307.248.555.556.555a.62.62 0 0 0 .32-.103l3.65-2.107c.274-.158.564-.256.884-.256.171 0 .336.026.49.074 1.702.49 3.54.761 5.44.761 9.205 0 16.667-6.218 16.667-13.89 0-2.322-.688-4.51-1.898-6.435l-19.19 11.08-.122.07z'/%3E  %3C/g%3E%3C/svg%3E");
}

.ActiveCodeTipsWord{
    float: left;
    width: 140px;
    height: 30px;
    line-height: 30px;
    text-align: left;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    font-weight: bold;
    color: #333333;
}






