@font-face {
  font-family: MicrosoftYaHei;
  src: url("http://oss.asfcyy.com/richTextImage/792348e053ea11eb9dc700163e3521a2.ttf");
}
@font-face {
  font-family: MicrosoftYaHeiBold;
  src: url("http://oss.asfcyy.com/richTextImage/8f0820e053ea11eb9dc700163e3521a2.ttf");
}
@font-face {
  font-family: SimSun;
  src: url("http://oss.asfcyy.com/richTextImage/a2f4df5853ea11eb9dc700163e3521a2.ttf");
}
@font-face {
  font-family: AdobeHeitiStd;
  src: url("http://oss.asfcyy.com/richTextImage/be6dbbc453ea11eb9dc700163e3521a2.otf");
}
@font-face {
  font-family: PingFangRegular;
  src: url("http://oss.asfcyy.com/richTextImage/db6eb67e53ea11eb9dc700163e3521a2.ttf");
}
@font-face {
  font-family: PingFangBold;
  src: url("http://oss.asfcyy.com/richTextImage/28f0635c53eb11eb9dc700163e3521a2.ttf");
}
@font-face {
  font-family: DFPSongW9;
  src: url("http://oss.asfcyy.com/richTextImage/4b36d05e53eb11eb9dc700163e3521a2.ttf");
}
@font-face {
  font-family: FZRUIZHJW;
  src: url("http://oss.asfcyy.com/richTextImage/b9aeb83a691c11eb9dc700163e3521a2.TTF");
}
.clearfix:after{
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
body,ul{
  font-family: Arial, serif;
  margin:0;
  padding:0;
}
div{
  outline:none;
  word-break: break-all;
}
ul,li{
  display:block;
  list-style: none;
}
p{
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 32px;
  font-family: SimSun,sans-serif;
  font-weight: bold;
}
hr{
  margin: 18px 0;
}
h3{
  height: 26px;
  line-height: 26px;
  font-size: 20px;
  margin: 26px 0;
}
video{
  display: block;
  margin: 20px auto;
}
.App {
  width: 100%;
  min-width: 1200px;
  height: 100%;
}

.MainBox{
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  background-size: 100% 100%;
  background-position: revert;
  background-repeat: no-repeat;
  background-image: url("http://oss.asfcyy.com/richTextImage/70c4a2c853ec11eb9dc700163e3521a2.png");
}
.MainContent{
  width: 1200px;
  margin: 0 auto;
}





/* 滚动条整体，可以设置宽度等① */
::-webkit-scrollbar{
  width: 3px;
  height: 3px;
}
/* 滚动条两端的按钮② */
::-webkit-scrollbar-button{
  display: none;
}
/* 外层轨道③ */
::-webkit-scrollbar-track{
  display: none;
}
/*内层滚动槽④*/
::-webkit-scrollbar-track-piece{

}
/* 滚动的滑块⑤*/
::-webkit-scrollbar-thumb{
  background-color: #2779B7;
}
::-webkit-scrollbar-thumb:hover{
  border-radius: 50%;
}
/*边角⑥*/
::-webkit-scrollbar-corner{

}
/*下角拖动块的样式⑦*/
::-webkit-resizer{

}




.BackTop{
  position: fixed;
  bottom: 60px;
  right: 40px;
  width: 40px;
  height: 40px;
  background-color: #43B269;
  opacity: 0.8;
  background-size: 50% 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./style/image/BackTOP.svg");
  cursor: pointer;
  z-index: 999;
}

/*底部定位补位*/
.PositionBottom{
  width: 100%;
  height: 240px;
}




/*网站整体风格公共样式*/
.MainTopBox{
  width: 100%;
  height: 106px;
}
.MainConTitle{
  float: left;
  width: auto;
  height: 48px;
  margin: 30px 10px 0 20px;
}
.MainConTitZH{
  width: 100%;
  height: 24px;
  line-height: 24px;
  font-size: 22px;
  text-align: center;
  font-family:MicrosoftYaHeiBold,sans-serif;
  letter-spacing: 0.15em;
  color: #FFFFFF;
  margin-bottom: 8px;
}
.MainConTitEN{
  width: 100%;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  font-family:MicrosoftYaHeiBold,sans-serif;
  letter-spacing: 0.15em;
  color: #FFFFFF;
}
.MainConTitBorder{
  float: left;
  width:3px;
  height:30px;
  background:#1780EC;
  margin: 38px 10px 38px 0;
}
.MainActiveListTitle{
  float: left;
  height: 22px;
  line-height: 22px;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  font-family:MicrosoftYaHeiBold,sans-serif;
  letter-spacing: 0.15em;
  color: #FFFFFF;
  margin: 42px 0;
}
.ContentMainBox{
  width: 100%;
  height: auto;
  min-height: 460px;
  background-color: rgba(4,136,202,0.35);
}
.MainLeftNavBox{
  float: left;
  width: 196px;
  height: auto;
  min-height: 460px;
}
.MainLeftNavListBox{
  display: block;
  width: 100%;
  height: auto;
  margin-top: 60px;
}
.MainLeftNavList{
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  text-align: center;
  font-size:20px;
  font-family:MicrosoftYaHeiBold,sans-serif;
  letter-spacing: 0.15em;
  color: #FFFFFF;
  cursor: pointer;
  margin-bottom: 22px;
}
.MainLeftNavList:hover{
  font-family:MicrosoftYaHei,sans-serif;
  font-weight: bold;
  color: #030303;
  background:linear-gradient(-68deg,rgba(255,255,255,1) 0%,rgba(255,255,255,0.1) 100%);
}
.MainLeftNavActiveList{
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  text-align: center;
  font-size:20px;
  font-family:MicrosoftYaHei,sans-serif;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #030303;
  background:linear-gradient(-68deg,rgba(255,255,255,1) 0%,rgba(255,255,255,0.1) 100%);
  margin-bottom: 22px;
  cursor: pointer;
}

.MainThisNavBox{
  float: left;
  width: 1004px;
  height: auto;
  min-height: 460px;
  background-color: #FFFFFF;
}




.DetailsBackBox{
  float: right;
  width: auto;
  height: 26px;
  cursor: pointer;
}
.DetailsBackIco{
  float: left;
  width: 26px;
  height: 26px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./style/image/DetailsBack.png");
}
.DetailsBackWord{
  float: left;
  width: auto;
  height: 26px;
  line-height: 25px;
  text-align: center;
  text-decoration: underline;
  font-size: 20px;
  font-family:MicrosoftYaHei,sans-serif;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #9B9B9B;
  cursor: pointer;
  margin: 0 12px 0 6px;
}





/*网站Flex布局样式*/
.DisplayFlex{
    display:-webkit-flex;
    display:flex;
    flex-wrap: wrap;
    justify-content:center;
    align-content:center;
    align-items:center;
}

/*人工审核请等待页面样式*/
.ManualReviewBox{
  width: 100%;
  height: auto;
  margin: 80px auto;
}
.ManualReviewIco{
  width:120px;
  height:116px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("./style/image/ManualReviewIco.png");
}
.ManualReviewWordBox{
  width: 150px;
  height: 64px;
  margin: 52px auto;
}
.ManualReviewWord{
  width: 150px;
  height: 32px;
  line-height: 32px;
  font-size:16px;
  font-family: MicrosoftYaHeiBold,sans-serif;
  font-weight:bold;
  letter-spacing: 0.1em;
  color: #575757;
}
.ManualReviewStep{
  width: 480px;
  height: 3px;
  margin: 0 auto;
  border: 1px solid #535353;
}
.ManualReviewStepHave{
  float: left;
  width: 192px;
  height: 3px;
  background-color: #22AC38;
}
.ManualReviewBlackWord{
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 21px;
  font-family:MicrosoftYaHei,sans-serif;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #868686;
  cursor: pointer;
  text-decoration: underline;
}




/*表格详情点击*/
.ClickDetails{
  color: #6DC1FF;
  text-decoration: underline;
  cursor: pointer;
}
.ClickList{
  cursor: pointer;
}
.ClickList:hover span{
  color: #6DC1FF;
  text-decoration: underline;
}





/*超出省略号*/
.Ellipsis{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}








/*信息大列表，点击新页面（赛事回顾，新闻公告，下载中心）*/
.InfoListPositionTitBox{
  width: 100%;
  height: 40px;
}
.InfoListPositionTit{
  float: right;
  width: auto;
  height: 20px;
  margin: 20px 30px 0 0;
}
.InfoListPosition{
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  font-family: PingFangRegular,sans-serif;
  font-weight: bold;
  color: #222222;
  letter-spacing: 0.15em;
  cursor: pointer;
}
.InfoListPosition span:hover{
  color: #FB621E;
}

.InfoListBorder{
  width: 958px;
  height: 2px;
  margin: 10px auto;
  background-color: #B5B5B5;
}
.InfoListBox{
  width: 880px;
  height: 600px;
  margin: 0 auto;
}
.InfoList{
  width: 100%;
  height: 32px;
  margin: 0 0 19px 0;
  cursor: pointer;
  line-height: 32px;
  font-size: 14px;
  letter-spacing: 0.09em;
  color:#222222;
  font-family: SimSun,sans-serif;
}
.InfoList:hover{
  color: #FB621E;
}
.InfoListKey{
  float: left;
  width: 60px;
  text-align: center;
  font-weight: bold;
}
.InfoListTitle{
  float: left;
  width: 480px;
  text-align: left;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  word-break:break-all;
  font-weight: bold;
}
.InfoListCenter{
  float: left;
  width: 200px;
  text-align: center;
  font-weight: bold;
}
.InfoListTime{
  float: left;
  width: 140px;
  text-align: right;
  font-weight: bold;
}



/*隐藏滚动条*/
.scrollBarFox{
  scrollbar-width: none;
}

/*超出滚动条*/
.scrollBar{
  overflow-y:auto;
}
.scrollBar::-webkit-scrollbar{
  width: 2px;
  height: 0;
}
.scrollBar::-webkit-scrollbar-track-piece{
  width: 2px;
  background-color: #FFFFFF;
}
.scrollBar::-webkit-scrollbar-thumb{
  background-color: #0488CA;
}
.scrollBar::-webkit-scrollbar-thumb:hover{
  border-radius: 0;
}


/*超出滚动条*/
.scrollBarTwo{
  overflow-y:auto;
}
.scrollBarTwo::-webkit-scrollbar{
  width: 2px;
  height: 0;
}
.scrollBarTwo::-webkit-scrollbar-track-piece{
  width: 2px;
  background-color: #FFFFFF;
}
.scrollBarTwo::-webkit-scrollbar-thumb{
  background-color: #FFFFFF;
}
.scrollBarTwo::-webkit-scrollbar-thumb:hover{
  border-radius: 0;
}






.NotYet{
  width: 100%;
  height: 746px;
  margin: 0 auto;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url("http://oss.asfcyy.com/richTextImage/f465bcb8bc9911ec9dc800163e3521a2.png");
}











/*关于新闻一类页面的A4图片样式*/

.media-wrap,
.image-wrap{
  max-width: 1000px;
  margin:  20px auto;
}
.media-wrap img,
.image-wrap img{
  width: 100%;
  max-width: 1000px;
  height: auto;
}
.media-wrap video,
.image-wrap video{
  width: 100%;
  max-width: 1000px;
  height: auto;
}

/*迁移进会员中心返回会员中心*/
.BackMemberBtn{
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: auto;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangRegular,sans-serif;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #FFFFFF;
  cursor: pointer;
}




.Maintain{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("http://oss.asfcyy.com/richTextImage/fbdf000c7f5011ec9dc700163e3521a2.png");
  z-index: 666666;
}
.OpenWebBox{
  position: fixed;
  top: 53%;
  left: 39.3%;
  width: 2%;
  height: 3%;
  z-index: 999999;
}


.css-icon {
  display: inline-block;
  height: 1em; width: 1em;
  font-size: 20px;
  box-sizing: border-box;
  text-indent: -9999px;
  vertical-align: middle;
  position: relative;
}
.css-icon::before,
.css-icon::after {
  content: '';
  box-sizing: inherit;
  position: absolute;
  left: 50%; top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
