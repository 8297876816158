.clearfix:after{
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
body,ul{
    margin:0;
    padding:0;
}
div{
    outline:none;
}
ul,li{
    display:block;
    list-style: none;
}
.AskOutBox{
    width: 1200px;
    height: auto;
    margin: 0 auto;
}
.AskTitle{
    width: 100%;
    height: 180px;
    line-height: 180px;
    font-size: 42px;
    font-weight: 400;
    text-align: center;
    color: #000000;
}
.ReportBoxOK{
    position: relative;
    width: 600px;
    height: 406px;
    margin: 0 auto;
    background-image: url("http://oss.asfcyy.com/richTextImage/11e124b4c77011eb9dc700163e3521a2.png");
    background-size: 600px 406px;
    background-repeat: no-repeat;
    box-shadow: 0 0 6px 1px rgba(6,0,1,0.5);
}
.ReportBoxERR{
    position: relative;
    width: 600px;
    height: 406px;
    margin: 0 auto;
    background-image: url("http://oss.asfcyy.com/richTextImage/2657ba765c5d11eb9dc700163e3521a2.png");
    background-size: 600px 406px;
    background-repeat: no-repeat;
    box-shadow: 0 0 6px 1px rgba(6,0,1,0.5);
}
.ReportUserName{
    position: absolute;
    top: 170px;
    left: 160px;
    width:auto;
    height:42px;
    line-height: 40px;
    font-size: 40px;
    font-weight: 500;
    color: #3F3F3F;
}
.ReportUserGrade{
    position: absolute;
    bottom: 54px;
    left: 160px;
    width:auto;
    height:52px;
    line-height: 52px;
    font-size: 60px;
    font-weight: 500;
    color: #3F3F3F;
}
.ReportBoxBtn{
    display: block;
    width: 167px;
    height: 52px;
    margin: 40px auto;
    border: 0;
    outline: none;
    background-color: #6EC4FF;
    border-radius: 6px;
    line-height: 50px;
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
    cursor: pointer;
}