.clearfix:after{
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}




.MsgInfoContent{
    width: 1000px;
    height: auto;
    min-height: 32px;
    margin: 0 auto;
    padding-bottom: 20px;
    line-height: 32px;
    text-align: center;
    font-size: 16px;
    font-family: SimSun,sans-serif;
    font-weight: bold;
}
.InfoListDel{
    float: left;
    width: auto;
    height: 32px;
    margin: 0 0 19px 0;
    cursor: pointer;
    line-height: 32px;
    font-size: 14px;
    letter-spacing: 0.09em;
    color:#222222;
    font-family: SimSun,sans-serif;
    font-weight: bold;
}