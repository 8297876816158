.clearfix:after{
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.Header{
    width: 100%;
    height: 84px;
    background-color: #0068B7;
}
.HeaderBox{
    position: relative;
    width: 1200px;
    height: 84px;
    margin: 0 auto;
}
/*LOGO*/
.HeaderLogo{
    float: left;
    /*width: 96px;*/
    width: 64.14px;
    height: 70px;
    margin: 7px 0 7px 20px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /*background-image: url("./handfimage/LOGO.png");*/
    background-image: url("http://oss.asfcyy.com/richTextImage/e5a648aab25d11ec9dc700163e3521a2.png");
}
/*商城图标*/
.HeaderIcoShop{
    float: right;
    width: 24px;
    height: 26px;
    margin: 45px 10px 13px 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    background-image: url("./handfimage/shopIco.png");
}
/*消息图标*/
.HeaderIcoMessage,
.HeaderIcoMessageHove{
    float: right;
    width: 24px;
    height: 26px;
    margin: 45px 26px 13px 16px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}
.HeaderIcoMessage{
    background-image: url("./handfimage/HeaderIcoMessage.png");
}
.HeaderIcoMessageHove{
    background-image: url("./handfimage/HeaderIcoMessageHove.png");
}
/*导航菜单*/
.NavBoxes{
    float: left;
    width: 960px;
    height: 84px;
}
.NavBox{
    width: 936px;
    height: 40px;
    margin: 0 auto;
}
.NavBox{
    margin-top: 44px;
}
.NavList{
    position: relative;
    float: left;
    width: 97px;
    height: 40px;
    margin: 0 10px;
    cursor: pointer;
}
.NavListSpan{
    width: 97px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    font-family: MicrosoftYaHeiBold,sans-serif;
    color: #FFFFFF;
    transition: all 0.4s linear 0s;
    cursor: pointer;
}
.NavListBorderBottom{
    width: 0;
    height: 4px;
    border-radius: 2px;
    background-color: #FB611D;
    transition: all 0.2s linear 0.1s;
}
.NavList:hover .NavListSpan{
    font-size: 18px;
}
.NavList:hover .NavListBorderBottom{
    width: 97px;
}
.SonNavBox{
    position: absolute;
    top: 40px;
    left: 0;
    width: 97px;
    max-height:0;
    background-color: #0068B7;
    transition: all 0.4s linear;
    overflow: hidden;
    z-index: 99;
}
.SonNavList{
    width: 97px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    font-family: MicrosoftYaHeiBold,sans-serif;
    color: #FFFFFF;
    transition: all 0.4s linear;
}
.NavList:hover .SonNavBox{
    max-height:200px;
}
.SonNavList:hover{
    font-size: 16px;
}
/*登录后的姓名显示*/
.MemberUserBox{
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: 28px;
}
.MemberUserName,
.SignOutBtn{
    float: left;
    width: auto;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 14px;
    font-family: MicrosoftYaHei,sans-serif;
    color: #FFFFFF;

}
.SignOutBtn{
    margin: 0 0 0 6px;
    cursor: pointer;
}
.SignOutBtn:hover span.SignOut{
    transition: all 0.2s linear;
    color: #FB611D;
}



/*底部Footer部分*/
.Footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 240px;
    background-color: #0068B7;
}
.WeBoxesBg{
    width: 100%;
    height: 240px;
    background-color: #0068B7;
}
.WeBoxes{
    width: 1200px;
    height: 240px;
    margin: 0 auto;
}
.FooterLogoBox{
    float: left;
    width: 134px;
    height: 220px;
    margin: 20px 20px 0 20px;
}
.FooterLogo{
    width: 134px;
    height: 97px;
    margin-bottom: 20px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url("./handfimage/LOGO.png");

}
.FooterAboutBox{
    width: 134px;
    height: 92px;
}
.FooterAboutList{
    float: left;
    width: 67px;
    height: 24px;
    margin: 0 0 10px 0 ;
    line-height: 24px;
    text-align: center;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    color: #E1E1E1;
    cursor: pointer;
    transition: all 0.2s linear;
}
.FooterAboutList:hover{
    color: #FB611D;
}
.FooterCon{
    float: left;
    width: 642px;
    height: 200px;
    margin: 40px 10px 0 10px;
}
.FooterConBox{
    float: left;
    width: 154px;
    margin: 0 30px;
}
.FooterConBoxTit{
    width: 100%;
    height: 24px;
    margin: 0 0 20px 0;
    line-height: 24px;
    text-align: center;
    font-size: 18px;
    font-family: MicrosoftYaHei,sans-serif;
    color: #E1E1E1;
}
.FooterConBoxList{
    width: 100%;
    height: 24px;
    margin: 0 0 4px 0 ;
    line-height: 24px;
    text-align: center;
    font-size: 16px;
    font-family: SimSun,sans-serif;
    color: #E1E1E1;
    cursor: pointer;
    transition: all 0.2s linear;
}
.FooterConBoxList:hover{
    color: #FB611D;
}
.FooterTwoCode{
    float: right;
    width: 330px;
    height: 200px;
    margin: 20px 10px;
}
.Wx{
    float: right;
    width: 150px;
    height: 200px;
    margin: 0 7px;
}
.WxTitWord{
    width: 150px;
    height: 24px;
    margin: 0 0 20px 0;
    line-height: 24px;
    text-align: left;
    font-size: 18px;
    font-family: MicrosoftYaHei,sans-serif;
    color: #E1E1E1;
}
.WxShowCode{
    width: 150px;
    height: 150px;
}
.WxShowCode img{
    display: block;
    width: 100%;
    height: 100%;
}
/*友情连接*/
.FriendBoxes{
    width: 1200px;
    height: auto;
    margin: 0 auto;
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
}
/*备注公告*/
.FooterCopyright{
    width: 1200px;
    height: 108px;
    margin: 0 auto;
}
.Copyright{
    display: table;
    width: auto;
    min-width: 200px;
    height: 36px;
    margin: 2px auto;
}
.CopyrightCon{
    float: left;
    width: auto;
    height: 36px;
    margin: 0 16px;
    line-height: 36px;
    text-align: justify;
    font-size: 14px;
    font-family: AdobeHeitiStd,sans-serif;
    color: #FFFFFF;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.CopyrightCon span {
    float: left;
}
.CopyrightCon img{
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    margin: 6px 4px 0 0;
}
img.AsfcLogo{
    float: left;
    width: auto;
    height: 36px;
    margin: 0 10px 0 0;
}



/*登录或注册页面的Header*/
.AuthHeader{
    width: 100%;
    height: 162px;
    background-color: #0068B7;
}
.AuthHeaderBox{
    width: 1200px;
    height: 162px;
    margin: 0 auto;
}
.AuthHeaderLogo{
    float: left;
    /*width: 195px;*/
    width: 130.13px;
    height: 142px;
    margin: 10px 0 10px 20px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /*background-image: url("./handfimage/LOGO.png");*/
    background-image: url("http://oss.asfcyy.com/richTextImage/e5a648aab25d11ec9dc700163e3521a2.png");
}
.AHLogoBorderRight{
    float: left;
    width: 2px;
    height: 132px;
    margin: 15px 46px;
    background-color: #FFFFFF;
}
.AuthHeaderTitle{
    float: left;
    width: auto;
    height: 32px;
    margin: 82px 0 48px 0;
    line-height: 32px;
    text-align: center;
    font-size:28px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    color:#FFFFFF;
    letter-spacing: 0.2em;
}
.BackHome{
    float: right;
    width: auto;
    height: 20px;
    margin: 130px 20px 12px 20px;
    line-height: 20px;
    text-align: center;
    font-size:18px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    color:#FFFFFF;
    letter-spacing: 0.2em;
    cursor: pointer;
    transition: all 0.2s linear;
}
.BackLogin{
    float: right;
    width: auto;
    height: 20px;
    margin: 131px 48px 11px 20px;
    line-height: 20px;
    text-align: center;
    font-size:18px;
    font-family:SimSun,sans-serif;
    color:#FFFFFF;
    letter-spacing: 0.02em;
    cursor: pointer;
    transition: all 0.2s linear;
}
.BackHome:hover,
.BackLogin:hover{
    color:#FB611D;
}







/*2022.06.10*/
.NewFooter{
    width: 100%;
    height: 240px;
    background-color: #0068B7;
}
.FooterBtnBox{
    position: absolute;
    right: 10px;
    top: 0;
    width: 76px;
    height: 36px;
    border-radius: 76px 76px 0 0;
    border-bottom: 1px solid #fff;
    z-index: 9999999999999999999999999999;
    background-color: #0068B7;
    cursor: pointer;
}
.FooterBtnBoxN{
    position: absolute;
    right: 1px;
    top: -62px;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    z-index: 9999999999999999999999999999;
    background-color: #0068B7;
    cursor: pointer;
}
.FooterBtnBoxN1{
    position: absolute;
    right: 2px;
    top: -44px;
    width: 76px;
    height: 40px;
    border-radius: 50%;
    z-index: 9999999999999999999999999999;
    /*background-color: #0068B7;*/
    background-size: auto 100%;
    background-repeat: no-repeat;
    /*background-image: url("./handfimage/LOGO.png");*/
    background-image: url("http://oss.asfcyy.com/richTextImage/6cf60882260111ee9dc800163e3521a2.png");
    cursor: pointer;
}



.FooterBtnBorLeft,
.FooterBtnBorLeftN,
.FooterBtnBorRightN,
.FooterBtnBorRight{
    position: absolute;
    width: 30px;
    height: 4px;
    border-radius: 2px;
    background-color: #fff;
}
.FooterBtnBorLeft{
    top: 18px;
    left: 14.5px;
    transform: rotate(50deg);
}
.FooterBtnBorRight{
    top: 18px;
    left: 31.5px;
    transform: rotate(130deg);
}
/*.FooterBtnBox:hover .FooterBtnBorLeft{*/
/*    transform: rotate(130deg);*/
/*}*/
/*.FooterBtnBox:hover .FooterBtnBorRight{*/
/*    transform: rotate(50deg);*/
/*}*/


.FooterBtnBorLeftN{
    top: 26px;
    left: 7px;
    transform: rotate(130deg);
}
.FooterBtnBorRightN{
    top: 26px;
    left: 24px;
    transform: rotate(50deg);
}

/*.FooterBtnBoxN:hover .FooterBtnBorRightN{*/
/*    transform: rotate(130deg);*/
/*}*/
/*.FooterBtnBoxN:hover .FooterBtnBorLeftN{*/
/*    transform: rotate(50deg);*/
/*}*/


