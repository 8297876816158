.AvSchoolListBox{
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: left;
    text-indent: 2em;
    font-size: 18px;
    font-family: SimSun,sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #030303;
    cursor: pointer;
    margin: 0 0 10px 0;
}
.AvSchoolListBox:hover{
    color: #6DC1FF;
    text-decoration: underline;
}
.AvSchoolMoreBox{
    width: 100%;
    height: 30px;
}
.AvSchoolMore{
    position: relative;
    float: right;
    width: 100px;
    height: 20px;
    margin: 6px 60px 0 0;
    cursor: pointer;
}
.AvSchoolMore span{
    display: block;
    line-height: 20px;
    text-align: left;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.12em;
    color: #48aed9;
}
.AvSchoolStyleBox{
    position: relative;
    width: 850px;
    height: 786px;
    margin: 0 auto;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("http://oss.asfcyy.com/richTextImage/8b1800f875ba11eb9dc700163e3521a2.jpg");
}
.AvSchoolStyleUpBox{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 850px;
    height: 786px;
}
.AvSchoolStyle{
    position: relative;
    float: left;
    height: 242px;
    border-bottom: 20px solid #FFFFFF;
    cursor: pointer;
    background-color: rgba(255,255,255,0.7);
}
.AvSchoolStyle:nth-child(1){
    width: 420px;
    border-right: 10px solid #FFFFFF;
}
.AvSchoolStyle:nth-child(2){
    width: 420px;
}
.AvSchoolStyle:nth-child(3){
    width: 360px;
    border-right: 10px solid #FFFFFF;
}
.AvSchoolStyle:nth-child(4){
    width: 480px;
}
.AvSchoolStyle:nth-child(5){
    width: 480px;
    border-right: 10px solid #FFFFFF;
}
.AvSchoolStyle:nth-child(6){
    width: 360px;
}
.AvSchoolStyle:hover{
    background-color: rgba(255,255,255,0);
}
.AvSchoolNameBox{
    position: relative;
    margin: 80px auto;
    width: auto;
    min-width: 10px;
    height: auto;
    min-height: 40px;
    max-height: 200px;
    line-height: 40px;
    text-align: center;
    font-size: 24px;
    font-family:FZRUIZHJW,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #000000;
    background-color: rgba(83,83,83,0);

}
.AvSchoolStyle:nth-child(1) .AvSchoolNameBox{
    max-width: 320px;
}
.AvSchoolStyle:nth-child(2) .AvSchoolNameBox{
    max-width: 320px;
}
.AvSchoolStyle:nth-child(3) .AvSchoolNameBox{
    max-width: 260px;
}
.AvSchoolStyle:nth-child(4) .AvSchoolNameBox{
    max-width: 380px;
}
.AvSchoolStyle:nth-child(5) .AvSchoolNameBox{
    max-width: 380px;
}
.AvSchoolStyle:nth-child(6) .AvSchoolNameBox{
    max-width: 260px;
}
.AvSchoolStyle:hover .AvSchoolNameBox{
    background-color: rgba(83,83,83,0.89);
    color: #ffffff;
    font-size: 24px;
}
.AvSchoolBorderLeft{
    display: none;
    position: absolute;
    top:-10px;
    left: -10px;
    width: 20px;
    height: calc(100% + 16px);
    border-top: 2px solid #535353;
    border-left: 2px solid #535353;
    border-bottom: 2px solid #535353;
}
.AvSchoolBorderRight{
    display: none;
    position: absolute;
    top:-10px;
    right: -10px;
    width: 20px;
    height: calc(100% + 16px);
    border-top: 2px solid #535353;
    border-right: 2px solid #535353;
    border-bottom: 2px solid #535353;
}
.AvSchoolStyle:hover .AvSchoolBorderLeft{
   display: block;
}
.AvSchoolStyle:hover .AvSchoolBorderRight{
   display: block;
}










