/*DatePicker时间选择器个性样式*/
.ReactDatePicker{
    position: relative;
    width: 260px;
    height: 34px;
}
.ReactDatePicker .react-date-picker{
    position: absolute;
    top: 0;
    left: 0;
    width: 260px;
    height: 34px;
    box-sizing:unset;
}
.ReactDatePicker .react-date-picker__wrapper{
    display: block;
    width: 260px;
    height: 34px;
    border: 2px solid #6DC1FF;
}
.ReactDatePicker .react-date-picker__inputGroup{
    float: left;
    width: 226px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    text-align: center;
}
.ReactDatePicker .react-date-picker__inputGroup input{
    display: none;
}
.ReactDatePicker .react-date-picker__inputGroup span{
    display: none;
}
.ReactDatePicker .react-date-picker__clear-button,
.ReactDatePicker .react-date-picker__button{
    border: 0;
    cursor: pointer;
    background-color: #FFFFFF;
}
.ReactDatePicker .react-date-picker__clear-button{
    float: right;
    border: 0;
    cursor: pointer;
    padding: 6px 6px 0 0;
}
.ReactDatePicker .react-date-picker__clear-button:focus{
    border: 0;
    outline: none;
}
.ReactDatePicker .react-date-picker__calendar{
    z-index: 99999;
    background-color: #ffffff;
}
.ReactDatePicker .react-date-picker__calendar-button{
    position: absolute;
    left: 2px;
    top: 2px;
    width: 224px;
    height: 30px;
    padding: 0;
    cursor: text;
    z-index: 9999;
    opacity: 0;
}
.ReactDatePicker .react-date-picker__calendar-button:focus{
    border: 0;
    outline: none;
}
.ReactDatePicker .react-date-picker__calendar-button__icon{
    display: none;
}

.ReactDatePicker .react-date-picker__calendar--open{
    width: 260px;
    height: auto;
    top: calc(100% - 2px) !important;
}
.ReactDatePicker .react-calendar{
    border: 2px solid #6DC1FF;
}
.ReactDatePicker .react-calendar span{
    font-size: 12px;
}
.ReactDatePicker .react-calendar__navigation{
    margin: 0;
}
.ReactDatePicker .react-calendar__month-view__weekdays{
    height: 36px;
    margin: 10px auto;
}
.ReactDatePicker .react-calendar__month-view__weekdays__weekday{
    height: 36px;
    line-height: 36px;
    font-size: 10px;
    padding: 0 0.5em;
}
.ReactDatePicker abbr{
    text-decoration: none;
}
.ReactDatePicker .react-calendar__navigation button:enabled:hover{
    background-color: #6DC1FF;
}
.ReactDatePicker .react-calendar__navigation button:enabled:focus{
    background-color: #FFFFFF;
}
.ReactDatePicker .react-calendar__tile{
    font-size: 11px;
}
.ReactDatePicker .react-calendar__tile--now{
    background-color: #6DC1FF;
}
.ReactDatePicker .react-calendar__tile:enabled:hover,
.ReactDatePicker .react-calendar__tile:enabled:focus{
    background-color: #6DC1FF;
}
.ReactDatePicker .TimeShow{
    position: absolute;
    left: 2px;
    top: 2px;
    width: 224px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    z-index: 9998;
    font-size: 14px;
    letter-spacing: 0.15em;
}