.loadEffect{
    width: 100px;
    height: 100px;
    position: relative;
    margin: 180px auto;
}
.loadEffect span{
    display: inline-block;
    width: 30px;
    height: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #303030;
    position: absolute;
    animation: loadEffect 0.8s ease-out -1s infinite;
}
.loadEffect span:nth-child(1){
    left: 0;
    top: 50%;
    margin-top:-5px;
    animation-delay:0.1s;
}
.loadEffect span:nth-child(2){
    left: 10px;
    top: 20px;
    transform: rotate(45deg);
    animation-delay:0.2s;
}
.loadEffect span:nth-child(3){
    left: 50%;
    top: 10px;
    margin-left: -15px;
    transform: rotate(90deg);
    animation-delay:0.3s;
}
.loadEffect span:nth-child(4){
    top: 20px;
    right:10px;
    transform: rotate(135deg);
    animation-delay:0.4s;
}
.loadEffect span:nth-child(5){
    right: 0;
    top: 50%;
    margin-top:-5px;
    transform: rotate(180deg);
    animation-delay:0.5s;
}
.loadEffect span:nth-child(6){
    right: 10px;
    bottom:20px;
    transform: rotate(225deg);
    animation-delay:0.6s;
}
.loadEffect span:nth-child(7){
    bottom: 10px;
    left: 50%;
    margin-left: -15px;
    transform: rotate(270deg);
    animation-delay:0.7s;
}
.loadEffect span:nth-child(8){
    bottom: 20px;
    left: 10px;
    transform: rotate(315deg);
    animation-delay:0.8s;
}
@keyframes loadEffect{
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0.2;
    }
}