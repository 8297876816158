.clearfix:after{
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.Home{
    width: 100%;
    height: auto;
}
.SloGonBgImage{
    position: relative;
    width: 100%;
    height: 42.1875vw;
    min-height: 506px;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("http://oss.asfcyy.com/richTextImage/12d84a8e53ec11eb9dc700163e3521a2.png");
    /*background-image: url("http://oss.asfcyy.com/richTextImage/2134d05a1c7811ee9dc800163e3521a2.jpg");*/
}
.SloGonBgImage img{
    display: block;
    width: 100%;
    height: 100%;
}

/*Swiper的Css*/
#banner{
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    margin: auto;
    width: 1200px;
    height: 266px;
    z-index: 89;
}
.swiper-container{
    position: static !important;
}
.swiper-slide{
    height: 266px !important;
    cursor: pointer;
}
.swiper-slide-prev-gray{
    position: absolute;
    top: 0;
    left: 0;
    width: 366px;
    height: 266px;
    z-index: 90;
    background-color: rgba(85,85,85,0.6);
}
.swiper-slide-next-gray{
    position: absolute;
    top: 0;
    right: 0;
    width: 362px;
    height: 266px;
    z-index: 90;
    background-color: rgba(85,85,85,0.6);
}
.swiper-slide-prev{
}
.swiper-slide-next{
}
.swiper-slide-active{
    position: relative;
    width: 472px !important;
    box-shadow:0 3px 9px 1px #040000;
}
.swiper-slide-active .BannerImage{
    display: block;
    width: 472px;
    height: 266px;
}
.swiper-slide-prev .BannerImage{
    display: block;
    float: right;
    width: 472px;
    height: 266px;
}
.swiper-slide-next .BannerImage{
    display: block;
    float: left;
    width: 472px;
    height: 266px;
}
.swiper-slide-prev .swiper-slide-active-title,
.swiper-slide-next .swiper-slide-active-title{
    display: none;
}
.swiper-slide-active .swiper-slide-active-title{
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 212px;
    background:linear-gradient(0deg,rgba(253,251,251,0.6) 0%,rgba(235,237,238,0.6) 100%);
    padding: 38px 18px 16px 24px;
}
.swiper-slide-active .swiper-slide-active-title .small-title{
    width: 100px;
    height: 22px;
    line-height: 21px;
    font-size:18px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    text-align: left;
    color: #555555;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.swiper-slide-active .swiper-slide-active-title .small-word{
    width: 100px;
    height: 144px;
    margin: 24px 0 0 0;
    line-height: 16px;
    font-size:13px;
    font-family:SimSun,sans-serif;
    font-weight: bold;
    text-align: left;
    color: #333333;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9;
    overflow: hidden;
    word-break:break-all;
}
.swiper-slide-active .swiper-slide-active-title .small-time{
    width: 100px;
    height: 16px;
    line-height: 16px;
    font-size:12px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    text-align: left;
    color: #999999;
}
.swiper-pagination-position{
    position: absolute;
    width: 100%;
    height: 12px;
    top: -28px;
    left: 0;
    z-index: 89;
}
.swiper-pagination{
    position: static !important;
    display: table !important;
    width: auto !important;
    height: 12px !important;
    margin: 0 auto !important;
    transform: none;
}
.swiper-pagination-bullet{
    display: block;
    float: left;
    width:11px;
    height:11px;
    margin: 0 4px;
    background-color: #FFFFFF;
    border-radius:50%;
    opacity: 1;
    transition: all 0.2s linear;
}
.swiper-news-bullet-active{
    width:42px;
    height:11px;
    background-color: #FFFFFF;
    border-radius:6px;
    opacity: 1;
    transition: all 0.2s linear;
}

/*主页的SLOGON*/
.SloGonBox{
    position: absolute;
    top: 2.0833333vw;
    left: 2.0833333%;
    right: 0;
    width:698px;
    height:374px;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./homeimage/SloGonTwo.png");
}

/*主页的搜索框*/
.HomeSearchBox{
    position: absolute;
    top: 440px;
    left: 0;
    right: 0;
    margin: auto;
    width:536px;
    height:32px;
    border:2px solid #FFFFFF;
}
.HomeSearch{
    display: block;
    float: left;
    width:444px;
    height:32px;
    border: 0;
    outline: none;
    padding: 0 0 0 12px;
    text-align: left;
    line-height: 32px;
    font-size: 16px;
    font-weight: bold;
    font-family: SimSun,sans-serif;
    color: #FFFFFF;
    background-color: rgba(255,255,255,0);
}
input.HomeSearch::-webkit-input-placeholder { /* WebKit browsers */
    color: #FFFFFF;
}
input.HomeSearch:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #FFFFFF;
}
input.HomeSearch::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #FFFFFF;
}
input.HomeSearch:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #FFFFFF;
}
.HomeSearchBtn{
    display: block;
    float: right;
    width: 80px;
    height: 32px;
    border: 0;
    outline: none;
    line-height: 28px;
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    font-family: MicrosoftYaHei,sans-serif;
    color: #FB611D;
    background-color: #FFFFFF;
    cursor: pointer;
}


/*主页的信息标题盒子*/
.HomeTitlesBox{
    position: absolute;
    width: 728px;
    height: auto;
}
.HomeTitleInfoBox{
    width: 728px;
    height: auto;
    min-height: 238px;
}
.HomeTitleInfo{
    width: 660px;
    height: 22px;
    margin: 0 auto;
    line-height: 22px;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    font-weight:bold;
    color:#555555;
    cursor: pointer;
    transition: all 0.2s linear;
}
.HomeTitleInfo:hover{
    color: #FB611D;
}
.HomeTitleInfo span{
    display: block;
    float: left;
    text-align: center;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    word-break:break-all
}
span.HomeTitleCon{
    width: 350px;
}
span.HomeTitleEllipsis{
    width: 200px;
}
span.HomeTitleTime{
    float: right;
    width: 110px;
}
.GameMargin,
.NewMargin{
    margin-bottom: 32px;
}
.TestMargin{
    margin-bottom: 26px;
}
.HomeTitlesBtn{
    display: block;
    float: right;
    width: 100px;
    height: 40px;
    margin: 0 44px 0 0;
    border: 0;
    outline: none;
    line-height: 36px;
    text-align: center;
    font-size: 19px;
    font-weight: bold;
    font-family: MicrosoftYaHei,sans-serif;
    color: #FFFFFF;
    background-color: #195786;
    cursor: pointer;
}

/*主页的赛事活动*/
.HomeGameBg{
    position: relative;
    width: 100%;
    height: 658px;
    background-color: #FFFFFF;
}
.HomeGameTitImg{
    position: absolute;
    bottom: 2px;
    left: 5px;
    width: 273px;
    height: 55px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./homeimage/HomeEvents.png");
}
.HomeGameConBox{
    position: relative;
    width: 960px;
    height: 658px;
    margin: 0 auto;
}
.HomeGameLogo{
    position: absolute;
    top: 188px;
    left: 0;
    width: 178px;
    height: 168px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./homeimage/HomeGameLogo.png");
}
.HomeGamePosition{
    bottom: 106px;
    right: 0;
}

/*主页的新闻公告*/
.HomeNewBg{
    position: relative;
    width: 100%;
    height: 752px;
    background-color: #E3E3E3;
}
.HomeNewTitImg{
    position: absolute;
    bottom: 2px;
    right: 5px;
    width: 667px;
    height: 53px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./homeimage/HomeNews.png");
}
.HomeNewConBox{
    position: relative;
    width: 1180px;
    height: 694px;
    margin: 0 auto;
}
.HomeNewLogo{
    position: absolute;
    top: 40px;
    left: 10px;
    width: 168px;
    height: 168px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./homeimage/HomeNewsLogo.png");
}
.HomeNewDecorateImgLeft{
    position: absolute;
    top:109px;
    left: 256px;
    width: 551px;
    height: 414px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size:cover;
    background-image: url("http://oss.asfcyy.com/richTextImage/3142030253ec11eb9dc700163e3521a2.jpg");
}
.HomeNewDecorateImgRight{
    position: absolute;
    top:109px;
    right: 0;
    width: 306px;
    height: 414px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size:cover;
    background-image: url("http://oss.asfcyy.com/richTextImage/46b4193e620911eb9dc700163e3521a2.png");
    /*background-image: url("http://oss.asfcyy.com/richTextImage/3a4de81253ec11eb9dc700163e3521a2.jpg");*/
}
.HomeNewPosition{
    bottom: 4px;
    left: 0;
    padding: 32px 0 22px 0;
    background:rgba(255,255,255,1);
    box-shadow:0 10px 28px 1px rgba(4,0,0,0.21);
}

/*主页的等级测试*/
.HomeTestBg{
    position: relative;
    width: 100%;
    height: 852px;
    background-color: #FFFFFF;
}
.HomeTestTitImg{
    position: absolute;
    bottom: 2px;
    left: 5px;
    width: 722px;
    height: 55px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./homeimage/HomeDwon.png");
}
.HomeTestConBox{
    position: relative;
    width: 1180px;
    height: 790px;
    margin: 0 auto;
}
.HomeTestLogo{
    position: absolute;
    top: 131px;
    right: 0;
    width: 168px;
    height: 168px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./homeimage/HomeDwonLogo.png");
}
.HomeTestDecorateImg{
    position: absolute;
    top:195px;
    left: 0;
    width: 566px;
    height: 422px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size:cover;
    background-image: url("http://oss.asfcyy.com/richTextImage/7225af42620911eb9dc700163e3521a2.png");
    /*background-image: url("http://oss.asfcyy.com/richTextImage/41a17e3a53ec11eb9dc700163e3521a2.jpg");*/
}
.HomeTestPosition{
    bottom: 174px;
    right: 0;
}




/*22024.4.10一个********的需求，*******，******/
.HomeDSB{
    width: 100%;
    height: 120px;
    line-height: 200px;
    text-align: center;
    font-size: 31px;
    font-weight: bold;
    font-family: MicrosoftYaHei,sans-serif;
    color: #000;
    background-color: #fff;
    letter-spacing: 0.19em;
}





















