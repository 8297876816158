.clearfix:after{
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.GameJumpsBg{
    height: auto;
    padding: 0.6rem 1.2rem;
}
.GameJumpsCon{
    width: 5.1rem;
    height: auto;
    line-height: 0.6rem;
    font-size: 0.36rem;
    text-align: center;
    font-weight: 500;
    color: #ff4d4f;
    margin: 0 auto;
    padding: 1.2rem 0 0 0;
    word-break: break-word;
}