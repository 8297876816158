.CertificateBox{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    width: auto;
    height: 98vh;
    min-height: 400px;
    margin: auto;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.NewAppWorkersInfo{
    display: table;
    width: auto;
    min-width: 366px;
    height: 20px;
    margin: 20px auto;
    line-height: 20px;
    text-align: center;
    font-size:14px;
    font-family: MicrosoftYaHei,sans-serif;
    font-weight:bold;
    letter-spacing: 0.15em;
    color: #030303;
}
.AboutMyselfInfoInfo{
    display: table;
    width: 100%;
    height: 40px;
    margin: 40px auto;
    line-height: 40px;
    text-align: left;
    font-size:30px;
    font-family: MicrosoftYaHei,sans-serif;
    font-weight:bold;
    letter-spacing: 0.15em;
    color: #030303;
}



/*服务协议*/
.AgreementBox{
    width: 1200px;
    height: auto;
    min-height: 774px;
    margin: 0 auto;
    background-color: #FFFFFF;
    padding-bottom: 40px;
}
.AgreementTitle{
    width: 1000px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    color: #030303;
    font-size: 24px;
    letter-spacing: 0.2em;
    padding: 28px 0;
    margin: 0 auto;
}
.AgreementTit{
    width: 1000px;
    height: auto;
    min-height: 32px;
    line-height: 32px;
    text-align: left;
    font-family: SimSun,sans-serif;
    font-weight: bold;
    color: #030303;
    font-size: 18px;
    letter-spacing: 0.15em;
    margin: 0 auto;
}
.AgreementCon{
    width: 1000px;
    height: auto;
    min-height: 26px;
    line-height: 26px;
    text-align: justify;
    text-indent: 2em;
    font-family: SimSun,sans-serif;
    font-weight: bold;
    color: #030303;
    font-size: 16px;
    letter-spacing: 0.12em;
    margin: 4px auto;
}



/*友情链接*/
.FriendLinkBox{
    width: 1200px;
    height: auto;
    min-height: 700px;
    margin: 0 auto;
}
.FriendLinkLeftBox{
    float: left;
    width: 360px;
    height: auto;
    min-height: 700px;
    margin: 25px 0;
}
.FriendLinkAboutBox{
    width: 340px;
    padding: 10px;
    background: #FFFFFF;
}
.FriendLinkAboutBox p{
    line-height: 24px;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    font-weight: bold;
    margin: 12px 0;
    color: #030303;
    letter-spacing: 0.06em;
}
.FriendLinkMyImageBox{
    width: 360px;
    margin: 16px 0;
}
.FriendLinkMyImage{
    float: left;
    width: 160px;
    height: 80px;
    margin: 0 10px;
}
.FriendLinkMyImage img{
    display: block;
    width: auto;
    height: 100%;
    margin: 0 auto;
    cursor: pointer;
}
.FriendLinkRightBox{
    float: left;
    width: 810px;
    height: auto;
    min-height: 700px;
    margin: 25px 0 25px 30px;
}
.FriendLinkRightBox ul{
    display: block;
    width: 810px;
    height: auto;
    min-height: 700px;
}
.FriendLinkRightBox li{
    display: block;
    float: left;
    width: 180px;
    height: 90px;
    margin: 10px;
    border: 1px solid #EEEEEE;
    background-color: #FFFFFF;
    cursor: pointer;
}
.FriendLinkRightBox li:hover .FriendLinkImageBox{
    transform: scale(1.2);
}
.FriendLinkImageBox{
    width: 172px;
    height: 58px;
    padding: 4px;
    overflow: hidden;
    transition: all 0.2s linear 0.1s;
}
.FriendLinkImageBox img{
    display: block;
    width: auto;
    height: 100%;
    max-height: 58px;
    margin: 0 auto;
}
.FriendLinkName{
    width: 172px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    font-family: MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    color: #000000;
    padding: 0 4px;
}
.MineSloGon{
    width: 1000px;
    height: 200px;
    margin: 0 auto;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./shpwimage/SloGon.png");
}
.MineWordBox{
    width: auto;
    height: auto;
    min-height: 200px;
    margin: 0 auto;
}
.MineWordTitle{
    width: 100%;
    height: auto;
    min-height: 26px;
    line-height: 26px;
    text-align: left;
    text-indent: 2em;
    font-size: 16px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: bold;
    color: #030303;
    margin: 1px 0;
}
.MineWord{
    width: 100%;
    height: auto;
    min-height: 26px;
    line-height: 26px;
    text-align: left;
    text-indent: 2em;
    font-size: 15px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: 500;
    color: #030303;
    margin: 6px 0;
}

























