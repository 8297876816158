.InquirePackageBox{
    position: relative;
    width: 1004px;
    height: auto;
    min-height: 746px;
}
.DetailsPackageBox{
    width: 100%;
    height: auto;
    min-height: 746px;
    background-color: #FFFFFF;
}



.InquireInputBox{
    float: right;
    width: 530px;
    height: 36px;
    margin: 36px 10px 0 0;
}
.InquireInput{
    display: block;
    float: left;
    width:424px;
    height:32px;
    border: 2px solid #FFFFFF;
    outline: none;
    padding: 0 0 0 12px;
    text-align: left;
    line-height: 32px;
    font-size: 16px;
    font-family: SimSun,sans-serif;
    color: #FFFFFF;
    background-color: rgba(255,255,255,0);
}
input.InquireInput::-webkit-input-placeholder { /* WebKit browsers */
    color: #FFFFFF;
}
input.InquireInput:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #FFFFFF;
}
input.InquireInput::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #FFFFFF;
}
input.InquireInput:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #FFFFFF;
}
.InquireInputBtn{
    display: block;
    float: right;
    width: 90px;
    height: 36px;
    border: 0;
    outline: none;
    line-height: 28px;
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    font-family: MicrosoftYaHei,sans-serif;
    color: #FB611D;
    background-color: #FFFFFF;
    cursor: pointer;
}
.InquirePackageHeaderBox{
    width: 970px;
    height: 68px;
    margin: 0 auto;
    border-bottom: 2px solid #E5E5E5;
}
.InquirePackageHeaderList{
    float: left;
    line-height: 26px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.15em;
    color: #868686;
    margin: 36px 0 0 0;
}
.InquireHeaderSelect{
    position: relative;
    width:118px;
    height:26px;
    background-color: #6EC2FF;
    border-radius:5px;
    margin: 0 auto;
    cursor: pointer;
}
.InquireSelectWord{
    float: left;
    width: 80px;
    margin-left: 12px;
    overflow: hidden;
    line-height: 26px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.15em;
    color: #FFFFFF;
}
.EquipmentDownNavBox{
    position: absolute;
    top: 30px;
    left: 60px;
    width: 120px;
    height: auto;
    max-height: 180px;
    background-color: #FFFFFF;
    box-shadow:0 2px 8px 1px rgba(0, 0, 0, 0.47);
    overflow-y: auto;
}
.EquipmentDownNav{
    width: 116px;
    height: 24px;
    margin: 2.2px auto;
    line-height: 24px;
    text-align: left;
    text-indent: 0.5em;
    font-size: 12px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.04em;
    color: #000000;
    background-color: #FFFFFF;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.EquipmentDownNav:hover{
    background-color: #8DCEFD;
}
.InquirePackageContentBox{
    width: 974px;
    height: auto;
    max-height: 676px;
    margin: 0 auto;
    overflow-y: auto;
    scrollbar-width:2px;
}
.InquirePackageContentListBox{
    width: 100%;
    height: 40px;
    margin: 20px 0;
}
.InquirePackageContentList{
    float: left;
    line-height: 40px;
    text-align: center;
    font-size: 13px;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #000000;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.InquirePaConLiWord{
    display: table;
    margin: 0 auto;
    width: auto;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #030303;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.InquireListHover:hover .InquirePaConLiWord{
    color: #1B7CFF;
    text-decoration: underline;
}
.InquireListHover:hover .ClickDetails{
    color: #1B7CFF;
    text-decoration: underline;
}
.InAddressThreeBox{
    float: left;
    width: 314px;
    height: 26px;
    margin-left: 8px;
}




































.DetailsPackageTop{
    width: 100%;
    height: 26px;
    padding: 20px 0;
}
.DetailsMainBox{
    width: 100%;
    height: auto;
}
.TaAndScDetailsMainBox{
    width: 1140px;
    height: auto;
    border-top: 2px solid #E5E5E5;
    margin: 0 auto;
}
.EquipmentDetailsLeft{
    float: left;
    width: 346px;
    height: auto;
    margin: 0 15px 0 15px;
    padding-top: 26px;
}
.EquipmentPhotoBox{
    width: 346px;
    height: 346px;
    margin-bottom: 54px;
}
.EquipmentPhotoBox img{
    width: 100%;
    height: 100%;
}
.BuyEquipmentBtn{
    display: block;
    width: 189px;
    height: 49px;
    line-height: 49px;
    text-align: center;
    font-size: 18px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    border: 0;
    margin: 0 auto;
    outline: none;
    color: #FFFFFF;
    background-color: #FF6A03;
    cursor: pointer;
}
.EquipmentDetailsRight{
    float: left;
    width: 800px;
    height: auto;
}
.EquipmentDetailsTopTit{
    width: 100%;
    height: 18px;
    overflow: hidden;
}
.DetailsTopTitWord{
    float: left;
    width: auto;
    height: 18px;
    line-height: 18px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #BEBEBE;
    margin: 0 10px 0 0;
}
.DetailsTopTitBorder{
    overflow: hidden;
    height: 2px;
    margin: 8px 0;
    background-color: #E5E5E5;
}
.EquipmentInfo{
    width: 100%;
    height: auto;
    margin: 20px 0;
}
.EquipmentInfoLeftBox{
    float: left;
    width: 464px;
    height: auto;
    margin-right: 16px;
}
.EquipmentInfoRightBox{
    float: left;
    width: 320px;
    height: auto;
}
.EquipmentInfoListBox{
    width: 100%;
    height: auto;
    min-height: 20px;
    margin: 32px 0;
}
.EquipmentInfoTit{
    float: left;
    width: 124px;
    height: auto;
    min-height: 20px;
    line-height: 20px;
    text-align: right;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #868686;
    margin-right: 20px;
}
.EquipmentInfoCon{
    float: left;
    height: auto;
    min-height: 20px;
    line-height: 20px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #000000;
}
.EquipmentInfoConLeft{
    width: 320px;
}
.EquipmentInfoConRight{
    width: 176px;
}
.EquipmentInfoCenterBox{
    float: left;
    width: 266px;
    height: auto;
}
a.EquipmentWebLink{
    color: #00B7EE;
    cursor: pointer;
    text-decoration: underline;
}
.OtherBuyBtnBox{
    width: 740px;
    height: 58px;
    margin: 26px auto;
}
.OtherBuyEquipmentBtn{
    display: block;
    float: left;
    width: 214px;
    height: 58px;
    line-height: 58px;
    text-align: center;
    font-size: 18px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    border: 0;
    margin: 0 16px 0 0;
    outline: none;
    color: #FFFFFF;
    background-color: #FF6A03;
    cursor: pointer;
}
.TaAndScDetailsLeftBox{
    float: left;
    width: 584px;
    height: auto;
}
.TaAndScDetailsCfBox{
    width: 548px;
    height: 370px;
    margin: 44px 0 0 36px;
}
.TaAndScDetailsCfBox img{
    width: 100%;
    height: 100%;
}
.TaAndScDetailsRightBox{
    float: left;
    width: 556px;
    height: auto;
    margin-top: 28px;
}
.TaAndScInfoListBox{
    width: 100%;
    height: auto;
    min-height: 20px;
    margin: 20px 0;
}
.TaAndScInfoTit{
    float: left;
    width: 180px;
    height: auto;
    min-height: 20px;
    line-height: 20px;
    text-align: right;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #868686;
    margin-right: 24px;
}
.TaAndScInfoCon{
    float: left;
    width: 340px;
    height: auto;
    min-height: 20px;
    line-height: 20px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #000000;
}
.TeacherDetailsLeft{
    float: left;
    width: 360px;
    height: auto;
}
.TeacherPhotoBox{
    width: 252px;
    height: 318px;
    margin: 0 auto;
}
.TeacherPhotoBox img{
    width: 100%;
    height: 100%;
}
.TeacherDetailsRight{
    float: left;
    width: 840px;
    height: auto;
}
.TeacherDetailsTopTit{
    width: 100%;
    height: 18px;
    overflow: hidden;
}
.PublicInfoBox{
    width: 100%;
    height: auto;
    margin: 2px 0;
}
.PublicInfoLeft{
    float: left;
    width: 480px;
    height: auto;
}
.PublicInfoList{
    width: 100%;
    height: auto;
    min-height: 22px;
    margin: 30px 0;
}
.TeacherInfoTit{
    float: left;
    width: 140px;
    height: auto;
    min-height: 22px;
    line-height: 22px;
    text-align: right;
    font-size: 18px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #868686;
    margin-right: 20px;
}
.TeacherInfoCon{
    float: left;
    width: 320px;
    height: auto;
    min-height: 22px;
    line-height: 22px;
    text-align: left;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #000000;
}
.TeacherCardListBox{
    float: left;
    width: 134px;
    height: auto;
    margin: 18px 10px;
    cursor: pointer;
}
.TeacherCardBox{
    width: 134px;
    height: 174px;
    margin-bottom: 10px;
}
.TeacherCardBox img{
    width: 100%;
    height: 100%;
}
.TeacherLevelWord{
    width: 112px;
    height: auto;
    min-height: 18px;
    margin: 0 auto;
    line-height: 18px;
    text-align: center;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #000000;
}
.ClickDetails:hover{
    color: #1B7CFF;
    text-decoration: underline;
}
.TeacherWorkingList{
    width: 100%;
    height: auto;
    min-height: 20px;
    margin: 20px auto;
    line-height: 20px;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #000000;
}
.TeacherWorkingTime{
    float: left;
    width: 248px;
    text-align: right;
    margin-right: 32px;
}
.TeacherWorkingTrain{
    float: left;
    width: auto;
    text-align: left;
}

.EquipmentIntroduction{
    width: 100%;
    height: auto;
    min-height: 20px;
    line-height: 32px;
    text-align: justify;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    text-indent: 2em;
    color: #000000;
}

















.InquirePackageContentBox::-webkit-scrollbar,
.EquipmentDownNavBox::-webkit-scrollbar{
    width: 6px;
    height: 0;
}
.InquirePackageContentBox::-webkit-scrollbar-track-piece,
.EquipmentDownNavBox::-webkit-scrollbar-track-piece{
    width: 2px;
    background-color: #FFFFFF;
}
.InquirePackageContentBox::-webkit-scrollbar-thumb,
.EquipmentDownNavBox::-webkit-scrollbar-thumb{
    background-color: #0488CA;
}
.InquirePackageContentBox::-webkit-scrollbar-thumb:hover,
.EquipmentDownNavBox::-webkit-scrollbar-thumb:hover{
    border-radius: 0;
}




/*全部数据按钮部分*/
.InquireAllDataBtn{
    position: absolute;
    top: 8px;
    right: 10px;
    width: auto;
    height: 24px;
    cursor: pointer;
    background-color: #1780ec;
    border-radius: 10px;
    padding-right: 4px;
}
.InquireAllDataIco{
    float: left;
    margin-top: 2px;
    color: #FFF;
    margin-left: 4px;
    margin-right: 4px;
}
.InquireAllDataIco {
    display: inline-block;
    height: 1em; width: 1em;
    font-size: 20px;
    box-sizing: border-box;
    text-indent: -9999px;
    vertical-align: middle;
    position: relative;
}
.InquireAllDataIco::before,
.InquireAllDataIco::after {
    content: '';
    box-sizing: inherit;
    position: absolute;
    left: 50%; top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.InquireAllDataIco::before {
    width: .9em; height: .9em;
    border: 2px solid;
    border-right-color: transparent;
    border-radius: 50%;
}
.InquireAllDataIco::after {
    border: .2em solid;
    border-left-color: transparent;
    border-top-color: transparent;
    left: 75%; top: 20%;
}
.InquireAllDataWord{
    float: left;
    width: auto;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 16px;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #fff;
}





