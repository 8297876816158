.MemberTopInfo {
  position: relative;
  width: 100%;
  height: 52px;
  background-color: #ffffff;
}
.MemberTopInfoWord {
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  color: #000000;
  letter-spacing: 0.15em;
}
.NowUserName {
  position: absolute;
  top: 0;
  left: 20px;
  width: auto;
  max-width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.MemberTopTitle {
  float: left;
  width: 100%;
}
.MemberTopRight {
  position: absolute;
  top: 0;
  right: 20px;
  width: auto;
  height: 52px;
  line-height: 52px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
}
.DropOut {
  float: right;
  width: auto;
  text-decoration: underline;
  cursor: pointer;
  color: #3e3e3e;
}
.ChangePassword {
  float: left;
  width: auto;
  margin-right: 42px;
  text-decoration: underline;
  cursor: pointer;
  color: #3e3e3e;
}

.MemberMainBox {
  height: auto;
  background-color: #0488ca;
}
.MemberMainBoxLeft {
  float: left;
  width: 194px;
  height: auto;
}
.MemberMainBoxRight {
  float: right;
  width: 1006px;
  height: auto;
  background-color: #ffffff;
}
.MemberMainBoxTop {
  width: 100%;
  height: 42px;
  background-color: #0488ca;
  line-height: 42px;
  text-align: left;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ffffff;
}
.MemberUList {
  display: block;
  width: 100%;
  height: auto;
}
.MemberList {
  display: block;
  width: 184px;
  height: 56px;
  margin-bottom: 12px;
  line-height: 56px;
  text-align: center;
  font-size: 20px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ffffff;
  border-left: 10px solid #0488ca;
  cursor: pointer;
}
.MemberList:hover {
  border-left: 10px solid #eb6100;
  background-color: #ffffff;
  color: #000000;
}

.MemberPackageBox {
  width: 100%;
  height: auto;
}

/*会员中心个人基本信息部分*/
.InfoPublic {
  position: relative;
  width: 100%;
  height: 84px;
}
.InfoPublicBox {
  float: right;
  margin: 0 10px 0 0;
  width: auto;
  height: 84px;
  line-height: 84px;
  text-align: center;
  font-size: 20px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
}
.InfoPublicIco {
  float: right;
  width: 54px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #eb6100;
  margin: 31px 0 0 10px;
}
.MoveIco {
  float: left;
  width: 32px;
  height: 24px;
  margin: -2px 0 0 -2px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.47);
  border-radius: 10px;
  cursor: pointer;
}
.UserPhotoBox {
  position: relative;
  float: left;
  width: 200px;
  height: 272px;
  margin: 0 0 0 146px;
  border: 1px solid #000000;
}
.UserPhotoBox img {
  width: 100%;
  height: 100%;
}
.UserPhotoBoxMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 202px;
  height: 274px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  line-height: 270px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ffffff;
  z-index: -1;
}
.UserPhotoBoxMasks {
  position: absolute;
  top: 0;
  left: 0;
  width: 202px;
  height: 274px;
  cursor: pointer;
  line-height: 270px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #3e3e3e;
}
.MemberUploadBtn {
  position: absolute;
  bottom: -26px;
  left: 194px;
  display: block;
  width: 120px;
  height: 30px;
  line-height: 28px;
  font-size: 16px;
  text-align: center;
  font-family: MicrosoftYaHei, sans-serif;
  letter-spacing: 0.15em;
  color: #ffffff;
  background-color: #6ec2ff;
  border: 0;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}
.UserPhotoBox:hover .UserPhotoBoxMask {
  z-index: 10;
}
.UserBaseInfo {
  float: left;
  width: 600px;
  height: auto;
  min-height: 274px;
  margin-left: 34px;
}
.UserBaseInfoTitle {
  display: block;
  float: left;
  width: 174px;
  height: auto;
}
.UserBaseInfoContent {
  display: block;
  float: left;
  width: auto;
  height: auto;
}
.UserBaseInfoTitleList {
  display: block;
  width: 174px;
  height: 30px;
  margin-bottom: 20px;
  line-height: 30px;
  text-align: left;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #868686;
}
.UserBaseInfoContentList {
  position: relative;
  display: block;
  width: 426px;
  height: auto;
  min-height: 30px;
  margin-bottom: 20px;
  line-height: 30px;
  text-align: left;
  font-size: 18px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
}
span.Comment {
  font-family: PingFangRegular, sans-serif;
  font-weight: bolder;
  font-size: 12px;
  color: #eb6100;
}
.PeBaseInfoSolid {
  width: 812px;
  height: 1px;
  margin: 0 auto;
  background-color: #dcdcdc;
}
.OtherPeBaseInfoBox {
  float: left;
  width: auto;
  height: auto;
  margin-left: 182px;
}
.UpDataBtn {
  position: absolute;
  top: 0;
  right: -60px;
  width: auto;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  text-decoration: underline;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #1b7cff;
  cursor: pointer;
}

/*会员中心学员、教员、辅导员信息部分*/
.MemberTopNavBox {
  position: relative;
  width: 986px;
  height: 60px;
  padding: 26px 0 0 20px;
}
.MemberPosition {
  position: absolute;
  right: 7px;
  top: 26px;
}
.MemberTopNav {
  float: left;
  width: 170px;
  height: 60px;
  border: 1px solid #ffffff;
  background-color: #e5e5e5;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
  cursor: pointer;
}
.PeStudentInfoBox {
  width: 978px;
  height: auto;
  margin: 1px 0 0 20px;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  padding-top: 4px;
}
.PeStudentInfoBox .TableHeader {
  width: 918px;
  height: 60px;
  margin: 0 auto;
  border-bottom: 2px solid #eeeeee;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #868686;
}
.PeStudentInfoBox .TableHeaderInfo {
  float: left;
  line-height: 20px;
  margin: 26px 0 0 0;
}
.PeStudentInfoBox .TableContentBox {
  width: 918px;
  height: auto;
  margin: 0 auto;
  overflow-y: auto;
}
.PeStudentInfoBox .TableContent {
  width: 918px;
  height: auto;
  min-height: 32px;
  margin: 1px 0;
}
.PeStudentInfoBox .TableContentInfo {
  float: left;
  line-height: 20px;
  margin: 6px 0;
  text-align: center;
  font-size: 12px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PeStudentInfoBox .TableContentInfoWord {
  display: table;
  margin: 0 auto;
  cursor: pointer;
}
.PeStudentInfoBox .ViewIco {
  float: right;
  width: 17px;
  height: 16px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./memberimage/PeStudentView.png');
  margin: 2px 0 0 6px;
  cursor: pointer;
}
.PeStudentInfoBox .DetailsViews {
  display: table;
  margin: 0 auto;
  text-decoration: underline;
  color: #1b7cff;
  cursor: pointer;
}

/*会员中心训练数据部分*/
.PeDataBoxes {
  width: 980px;
  padding-top: 18px;
  margin: 0 auto;
}
.PeDataTopBox {
  width: 100%;
  height: 39px;
  border-bottom: 1px solid #000000;
  background-color: #e5e5e5;
}
.PeDataTopBox .TableHeader {
  width: 980px;
  height: 39px;
}
.PeDataTopBox .TableHeaderInfo {
  position: relative;
  float: left;
  height: 39px;
  line-height: 38px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.35em;
  color: #000000;
}
.PeDataTopBox .TableHeaderInfoWord {
  display: inline-block;
  width: auto;
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PeDataTopBox .SubjectDownIco {
  position: relative;
  float: left;
  width: 14px;
  height: 10px;
  margin: 15px 10px 0 0;
}
.PeDataTopBox .SubjectDownTitle {
  float: left;
  width: auto;
  max-width: 296px;
  cursor: pointer;
}
.PeDataTopBox .SubjectDownNavBox {
  position: absolute;
  top: 40px;
  left: 16px;
  width: 280px;
  height: 158px;
  background-color: #ffffff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}
.PeDataTopBox .SubjectDownNav {
  width: 276px;
  height: 25px;
  margin: 6px 0 0 4px;
  line-height: 24px;
  text-align: left;
  font-size: 14px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.05em;
  color: #575757;
  background-color: #ffffff;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PeDataTopBox .SubjectDownNav:hover {
  background-color: #8dcefd;
}
.PeDataConBoxes {
  width: 100%;
}
.PeDataConBox {
  width: 100%;
  margin: 0 0 24px 0;
}
.PeDataConBox .TableContent {
  width: 100%;
  height: 16px;
  margin: 13px 0;
}
.PeDataConBox .TableContentInfo {
  float: left;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 13px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PeDataConBox .DetailsViews {
  display: table;
  margin: 0 auto;
  text-decoration: underline;
  color: #1b7cff;
  cursor: pointer;
}

/*会员中心飞行团队部分*/
.PeTeamInfoBox {
  width: 978px;
  height: auto;
  min-height: 400px;
  margin: 1px 0 0 20px;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
}
.NoTeamPrompt {
  width: auto;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #868686;
  padding-top: 148px;
}
.NoTeamComment {
  width: auto;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ff6363;
  margin-top: 10px;
  margin-bottom: 54px;
}
.NoTeamButton {
  display: table;
  width: 169px;
  height: 52px;
  line-height: 50px;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ffffff;
  background-color: #6ec2ff;
  border: 0;
  outline: none;
  margin: 36px auto;
  border-radius: 10px;
  cursor: pointer;
}
.DropOutTeamBtn {
  position: absolute;
  top: 42px;
  right: 38px;
  display: table;
  width: 108px;
  height: 41px;
  line-height: 40px;
  font-size: 14px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ffffff;
  background-color: #6ec2ff;
  border: 0;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}
.YesTeamTop {
  width: 880px;
  height: 290px;
  margin: 0 auto;
  padding-top: 58px;
}
.YesTeamLogoBox {
  position: relative;
  float: left;
  width: 322px;
  height: 290px;
}
img.YesTeamLogo {
  width: 100%;
  height: 100%;
}
.YesTeamLogoMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 322px;
  height: 290px;
  line-height: 290px;
  font-size: 16px;
  text-align: center;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -2;
}
.YesTeamLogoBox:hover .YesTeamLogoMask {
  z-index: 2;
  cursor: pointer;
}
.YesTeamInfo {
  float: left;
  width: 448px;
  height: 168px;
  margin: 61px 0 0 110px;
}
.YesTeamInfoList {
  height: 24px;
  margin: 30px 0;
  line-height: 24px;
}
.YesTeamInfoTit {
  float: left;
  width: 126px;
  font-size: 16px;
  text-align: left;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #868686;
}
.YesTeamInfoCon {
  float: left;
  width: 322px;
  font-size: 20px;
  text-align: left;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.YesTeamPeople {
  width: 100%;
  height: auto;
  min-height: 260px;
}
.YesTeamPeopleTitle {
  width: auto;
  height: 20px;
  margin: 0 0 32px 50px;
  line-height: 20px;
  font-size: 16px;
  text-align: left;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #868686;
}
.YesTeamPeopleConBox {
  width: 876px;
  height: auto;
  min-height: 168px;
  margin: 0 auto;
}
.YesTeamPeoList {
  float: left;
  width: 292px;
  height: 18px;
  margin: 10px 0;
  line-height: 18px;
  font-size: 16px;
  text-align: left;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.02em;
  color: #000000;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.YesTeamPeoList:hover {
  text-decoration: underline;
  color: #4f6cff;
}
.YesTeamPeopleComment {
  width: auto;
  height: 20px;
  margin: 20px 36px 40px 0;
  line-height: 18px;
  font-size: 16px;
  text-align: right;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #747474;
}

/*会员中心飞行团队的组件部分*/
.TeamFlagUploadBtn {
}
.DropOutTeamJoinBtn {
  position: absolute;
  top: 15px;
  right: 25px;
  display: table;
  width: 108px;
  height: 41px;
  line-height: 40px;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ffffff;
  background-color: #6ec2ff;
  border: 0;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}
.PeTeamModule {
  width: 900px;
  height: 592px;
  margin: 0 auto;
  padding-top: 80px;
}
.TeamIdInputBoxes {
  width: 100%;
  height: 42px;
  margin-bottom: 28px;
}
.TeamIdInputTit {
  float: left;
  width: auto;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #787878;
  margin-right: 18px;
}
.TeamIdInputBox {
  float: left;
  width: 398px;
  height: 40px;
  border: 1px solid #1c7dc4;
  border-radius: 5px;
}
.TeamIdInput {
  display: block;
  float: left;
  width: 290px;
  height: 38px;
  padding-left: 10px;
  border: 0;
  border-radius: 5px;
  outline: none;
  text-align: left;
  line-height: 38px;
  font-size: 16px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
}
.TeamIdInputBtn {
  float: right;
  display: block;
  width: 94px;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
  background-color: #6ec2ff;
  border: 0;
  outline: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  margin: 1px;
}
.TeamIdInputNote {
  float: left;
  width: auto;
  height: 40px;
  line-height: 40px;
  text-align: left;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ff3b2f;
  margin-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.TeamPleaseSendBtn {
  display: block;
  width: auto;
  min-width: 140px;
  height: 30px;
  line-height: 28px;
  font-size: 16px;
  text-align: center;
  font-family: MicrosoftYaHei, sans-serif;
  letter-spacing: 0.15em;
  color: #ffffff;
  background-color: #6ec2ff;
  border: 0;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 6px;
}
.TeamListHeaderBox {
  width: 898px;
  height: 32px;
  border: 1px solid #999999;
  background-color: #cccccc;
}
.TeamHeaderList {
  position: relative;
  float: left;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  text-align: center;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
}
.TeamHeaderCheckbox {
  width: 20px;
  height: 20px;
  margin: 6px auto;
  background-color: #ffffff;
  cursor: pointer;
}
.TeamHeaderCheckboxActive {
  float: left;
  width: 10px;
  height: 10px;
  margin: 5px 0 0 5px;
  background-color: #03000a;
}
.TeamListConBox {
  width: 100%;
  height: 452px;
  overflow-y: auto;
}
.TeamListCon {
  width: 100%;
  height: 30px;
  margin: 10px 0;
}
.TeamConList {
  float: left;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: 500;
  letter-spacing: 0.15em;
  color: #000000;
}
.TeamConList .DetailsViews {
  text-decoration: underline;
  color: #1b7cff;
  cursor: pointer;
}
.TeamSendIco {
  width: 30px;
  height: 30px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
  cursor: pointer;
}
.CheckboxIco {
  width: 18px;
  height: 16px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
  cursor: pointer;
}
.PeTeamCreateInfoBox {
  width: 100%;
  height: 180px;
}
.TeamCreateStep {
  position: relative;
  width: 117px;
  height: 34px;
  cursor: default;
}
.TeamCreateStepWord {
  width: 100px;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  text-align: center;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: 500;
  letter-spacing: 0.15em;
  color: #ffffff;
  background-color: #0068b7;
}
.PeTeamCreateInfoConBox {
  width: 100%;
  height: 44px;
  margin: 10px 0;
}
.PeTeamCreateInfoConTit {
  float: left;
  width: 115px;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  text-align: right;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: 500;
  letter-spacing: 0.15em;
  color: #787878;
  margin-right: 18px;
}
.TeamNameInput {
  display: block;
  float: left;
  width: 388px;
  height: 42px;
  padding-left: 10px;
  border: 1px solid #1c7dc4;
  border-radius: 5px;
  outline: none;
  text-align: left;
  line-height: 42px;
  font-size: 16px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
  margin-right: 16px;
}
.PeTeamCreateNote {
  float: left;
  width: auto;
  height: 44px;
  line-height: 44px;
  text-align: left;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ff3b2f;
}
.TeamNameNoteIco {
  float: left;
  width: 32px;
  height: 32px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./memberimage/TeamNameNoteIco.png');
  margin: 6px auto;
}
.TeamCreateLogoBox {
  position: relative;
  float: left;
  width: auto;
  min-width: 90px;
  height: 30px;
  margin: 7px 0;
}
.TeamCreateLogo {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  font-family: MicrosoftYaHei, sans-serif;
  letter-spacing: 0.15em;
  color: #ffffff;
  background-color: #6ec2ff;
  border-radius: 5px;
  cursor: pointer;
}
.TeamCreateLogoBox .FileNameBox {
  position: absolute;
  top: 0;
  left: 100px;
  width: 180px;
}
.TeamCreateLogoFile {
  float: left;
  width: auto;
  max-width: 400px;
  height: 44px;
  line-height: 44px;
  text-align: left;
  font-size: 14px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  text-decoration: underline;
  color: #0488ca;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PeTeamManageInfoConTit {
  float: left;
  width: auto;
  min-width: 100px;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  text-align: left;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #868686;
  margin-right: 16px;
}
.PeTeamManageInfoCon {
  float: left;
  width: auto;
  min-width: 200px;
  height: 44px;
  line-height: 44px;
  font-size: 20px;
  text-align: left;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PeTeamManageInfoCon .Btn {
  float: left;
  width: auto;
  font-size: 18px;
  text-align: left;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  text-decoration: underline;
  cursor: pointer;
  color: #1b7cff;
  margin-left: 40px;
}
.PeTeamManageInfoConWod {
  float: left;
}
.TwoWordBtn {
  float: left;
  display: block;
  width: 108px;
  height: 41px;
  line-height: 40px;
  font-size: 14px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ffffff;
  background-color: #6ec2ff;
  border: 0;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}
.GoOnSolid {
  float: right;
  width: 800px;
  height: 2px;
  background-color: #999999;
  margin: 9px 0;
}
.PeJoinTameTitBtnBox {
  width: 100%;
  height: 36px;
}
.PeTeamManagePeInfo {
  float: right;
  width: auto;
  min-width: 68px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  margin-left: 36px;
}
.AdminOr {
  width: 98px;
  height: 24px;
  border: 1px solid #6ec2ff;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 3px auto;
  line-height: 24px;
  font-size: 14px;
  font-family: MicrosoftYaHeid, sans-serif;
  letter-spacing: 0.15em;
  color: #969696;
  cursor: pointer;
}
.AdminOr:hover {
  background-color: #6ec2ff;
  color: #ffffff;
}
.TableHeaderInfoListBox {
  display: inline-block;
  width: auto;
  height: 32px;
  cursor: pointer;
}
.TableHeaderDownIco {
  position: relative;
  float: left;
  width: 15px;
  height: 32px;
  margin-right: 8px;
}
.TableHeaderDownTitle {
  float: left;
  width: auto;
}
.TableHeaderDownNavBox {
  position: absolute;
  top: 32px;
  left: 40px;
  width: 114px;
  height: auto;
  background-color: #ffffff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.TableHeaderDownNav {
  width: 110px;
  height: 24px;
  margin: 2px 0 6px 2px;
  line-height: 24px;
  text-align: left;
  text-indent: 0.2em;
  font-size: 14px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.1em;
  color: #575757;
  background-color: #ffffff;
  cursor: pointer;
}
.TableHeaderDownNav:hover {
  background-color: #8dcefd;
}

/*机构的会员中心部分*/
.GrBaseInfoTitle {
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: left;
  text-indent: 2em;
  font-size: 18px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
  margin: 0 0 24px 0;
  padding-top: 24px;
}
.GrBaseInfoListBox {
  width: 940px;
  height: auto;
  min-height: 24px;
  margin: 32px auto;
}
.GrBaseInfoListTit {
  float: left;
  width: 228px;
  height: 24px;
  line-height: 24px;
  text-align: right;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #868686;
}
.GrBaseInfoListCon {
  float: left;
  width: 480px;
  height: auto;
  min-height: 20px;
  line-height: 20px;
  text-align: left;
  font-size: 18px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
  margin-left: 45px;
}
.GrBaseInfoListConWord {
  float: left;
  width: 430px;
  line-height: 24px;
}
.GrBaseInfoListUpDataBtn {
  float: right;
  width: auto;
  height: 20px;
  line-height: 20px;
  text-align: left;
  text-decoration: underline;
  font-size: 18px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #1b7cff;
  cursor: pointer;
}
.SeparateBorder {
  width: 940px;
  height: 1px;
  margin: 0 auto;
  background-color: #dcdcdc;
}
.GrTrainTeacherConBox {
  width: 910px;
  height: 670px;
  margin: 0 auto;
}
.GrTrainAdDownNavBox {
  height: auto;
  max-height: 120px;
  overflow-y: auto;
}
.GrTrainAdDownNav {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.RemoveThisTeacher {
  width: 20px;
  height: 23px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./memberimage/RemoveThis.png');
  margin: 0 auto;
  cursor: pointer;
}
.GrAvSchoolBox {
  width: 900px;
  height: auto;
  max-height: 672px;
  margin: 0 auto;
  padding-top: 20px;
}
.GrAvSchoolListBox {
  width: 100%;
  height: 20px;
  margin: 36px 0;
}
.GrAvSchoolListTit {
  float: left;
  width: 180px;
  height: 20px;
  line-height: 20px;
  text-align: right;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #868686;
}
.GrAvSchoolListCon {
  float: left;
  width: 680px;
  height: 20px;
  line-height: 20px;
  text-align: left;
  font-size: 18px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
  margin-left: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.GrAvSchoolListConBtn {
  float: left;
  width: 700px;
  height: 20px;
  line-height: 20px;
  text-align: left;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #1b7cff;
  margin-left: 40px;
  cursor: pointer;
  text-decoration: underline;
}
.TableContentInfoBranch {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.GrGameHeaderBox {
  width: 900px;
  height: 50px;
  margin: 0 auto;
  border-bottom: 2px solid #eeeeee;
}
.GrGameHeaderInfo {
  float: left;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #868686;
  margin-top: 24px;
}
.GrGameContentBox {
  width: 100%;
  height: 706px;
  overflow-y: auto;
}
.GrGameContentList {
  width: 900px;
  height: 18px;
  margin: 20px auto;
}
.GrGameContentInfo {
  float: left;
  height: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 14px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #010101;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.GrDeviceConListBox {
  width: 100%;
  height: 520px;
}
.GrDeviceConList {
  width: 918px;
  height: 110px;
  margin: 10px auto;
}
.GrDeviceConInfo {
  float: left;
}
.GrDeviceLogoBox {
  position: relative;
  width: 110px;
  height: 110px;
  margin: 0 auto;
}
img.GrDeviceLogo {
  display: block;
  width: 100%;
  height: 100%;
}
.GrDeviceLogoHoverBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 110px;
  height: 110px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: -1;
}
.GrDeviceLogoBox:hover .GrDeviceLogoHoverBox {
  z-index: 6;
}
.BrandName {
  width: 90px;
  height: 50px;
  padding: 10px;
  line-height: 18px;
  text-align: left;
  font-size: 14px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ffffff;
  word-break: break-all;
}
.GrDeviceReview {
  width: 90px;
  height: 20px;
  padding: 0 10px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  text-decoration: underline;
  color: #ffffff;
}
.GrDeviceConInfoWord {
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #010101;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MemberOverflow {
  overflow-y: auto;
}

.TeamNewsDownNavBox {
  position: absolute;
  top: 38px;
  left: 56px;
  width: 100px;
  height: auto;
  min-height: 60px;
  background-color: #ffffff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}
.TeamNewsDownNav {
  width: 92px;
  height: 25px;
  margin: 6px 0 0 4px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.05em;
  color: #575757;
  background-color: #ffffff;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.TeamNewsDownNav:hover {
  background-color: #8dcefd;
}
.TeamNewsBtn {
  display: block;
  width: auto;
  min-width: 60px;
  height: 20px;
  line-height: 19px;
  font-size: 12px;
  text-align: center;
  font-family: MicrosoftYaHei, sans-serif;
  letter-spacing: 0.25em;
  border: 0;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px;
  color: #ffffff;
}
.MemberOverflow::-webkit-scrollbar,
.TableContentBox::-webkit-scrollbar,
.TeamListConBox::-webkit-scrollbar,
.GrTrainAdDownNav::-webkit-scrollbar,
.GrGameContentBox::-webkit-scrollbar,
.VipIndexMain::-webkit-scrollbar,
.PeDataTopBox .SubjectDownNavBox::-webkit-scrollbar {
  width: 3px;
  height: 0;
}
.MemberOverflow::-webkit-scrollbar-track-piece,
.TableContentBox::-webkit-scrollbar-track-piece,
.TeamListConBox::-webkit-scrollbar-track-piece,
.GrTrainAdDownNav::-webkit-scrollbar-track-piece,
.GrGameContentBox::-webkit-scrollbar-track-piece,
.VipIndexMain::-webkit-scrollbar-track-piece,
.PeDataTopBox .SubjectDownNavBox::-webkit-scrollbar-track-piece {
  width: 2px;
  background-color: #ffffff;
}
.MemberOverflow::-webkit-scrollbar-thumb,
.TableContentBox::-webkit-scrollbar-thumb,
.TeamListConBox::-webkit-scrollbar-thumb,
.GrTrainAdDownNav::-webkit-scrollbar-thumb,
.GrGameContentBox::-webkit-scrollbar-thumb,
.VipIndexMain::-webkit-scrollbar-thumb,
.PeDataTopBox .SubjectDownNavBox::-webkit-scrollbar-thumb {
  background-color: #0488ca;
}
.MemberOverflow::-webkit-scrollbar-thumb:hover,
.TableContentBox::-webkit-scrollbar-thumb:hover,
.TeamListConBox::-webkit-scrollbar-thumb:hover,
.GrTrainAdDownNav::-webkit-scrollbar-thumb:hover,
.GrGameContentBox::-webkit-scrollbar-thumb:hover,
.VipIndexMain::-webkit-scrollbar-thumb:hover,
.PeDataTopBox .SubjectDownNavBox::-webkit-scrollbar-thumb:hover {
  border-radius: 0;
}

/*后部教练员挂靠*/
.ActiveTrainWord {
  float: left;
  width: 354px;
  height: 42px;
  line-height: 42px;
  text-align: left;
  font-size: 14px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  font-weight: 500;
  text-indent: 1em;
  letter-spacing: 0.04em;
  color: #9b9b9b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ActiveTrainBtn {
  position: relative;
  float: right;
  width: 42px;
  height: 38px;
  background-color: #6dc1ff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  margin: 1px;
}
.ActiveTrainMenuListBox {
  position: absolute;
  left: 2px;
  top: 42px;
  width: 350px;
  height: auto;
  max-height: 186px;
  background-color: #ffffff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
  z-index: 99;
  overflow-y: auto;
}
.ActiveTrainMenuList {
  width: 346px;
  height: 24px;
  margin: 2px auto;
  line-height: 24px;
  text-align: left;
  font-size: 12px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  font-weight: 500;
  text-indent: 1em;
  letter-spacing: 0.04em;
  color: #3e3e3e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ActiveTrainMenuList:hover {
  background-color: #6dc1ff;
}

.TrainActiveBox {
  position: absolute;
  top: 32px;
  right: 0;
  width: 300px;
  max-height: 120px;
  height: auto;
  background-color: #ffffff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}
.TrainPageShowWord {
  width: 640px;
  height: auto;
  margin: 0 auto;
  line-height: 24px;
  text-align: left;
  font-size: 18px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  font-weight: 500;
  text-indent: 1em;
  letter-spacing: 0.04em;
  color: #bb0000;
  padding-top: 240px;
}
.PeDataDetailsBox {
  width: 480px;
  height: 600px;
}

.OldRealNameBox {
  width: 1200px;
  height: 800px;
  margin: 0 auto;
  background-color: #ffffff;
}
.OldRealNameTitle {
  width: 100%;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 32px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  font-weight: 500;
  letter-spacing: 0.2em;
  color: #000000;
  padding-top: 100px;
  margin-bottom: 20px;
}
.OldRealNameTit {
  width: 100%;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  font-weight: 500;
  letter-spacing: 0.24em;
  color: #868686;
  margin-bottom: 60px;
}
.OutLogin {
  width: auto;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin: 0 auto;
}

/*后加机构推荐辅导员*/
.CounselorTitle {
  width: 960px;
  height: 68px;
  margin: 0 auto;
  border-bottom: 2px solid #e5e5e5;
}
.CounselorListBtn {
  float: right;
  width: auto;
  min-width: 100px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.2em;
  color: #ffffff;
  background-color: #6ec2ff;
  border-radius: 10px;
  cursor: pointer;
  padding: 0 16px;
  margin: 14px 10px 0 0;
}

/*会员福利*/
.MemberPeBenefitsBox {
  float: left;
  width: 400px;
  height: 240px;
  margin: 20px 50px;
  border: 1px solid #666666;
  cursor: pointer;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.PromoCodeBg {
  background-image: url('./memberimage/PromoCode.jpg');
}
.TrickBg {
  background-image: url('./memberimage/Trick.png');
}

.TrickTitle {
  width: 100%;
  height: 32px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #f5495d;
  line-height: 32px;
  font-size: 24px;
}
.TrickRemarks {
  width: 100%;
  height: 32px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.15em;
  color: #030303;
  line-height: 32px;
  font-size: 12px;
  margin: 16px 0 0 0;
}
.TrickInfoBox {
  width: 100%;
  height: 32px;
  margin: 40px 0;
}
.TrickInfoTit {
  float: left;
  width: 420px;
  height: 32px;
  text-align: right;
  font-weight: 500;
  letter-spacing: 0.15em;
  color: #030303;
  line-height: 32px;
  font-size: 14px;
}
.TrickInfoWord {
  float: left;
  width: 420px;
  height: 32px;
  border: 1px solid #f5495d;
}
.TrickInfoBtn {
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #ffffff;
  font-size: 18px;
  margin: 100px auto;
  background-color: #f5495d;
  cursor: pointer;
}
.TrickInfoInput {
  display: block;
  width: 408px;
  height: 30px;
  line-height: 30px;
  text-align: left;
  font-size: 16px;
  padding-left: 10px;
  border: 0;
  outline: none;
}
.PromoCodeBtnBox {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 360px;
}
.PromoCodeDown {
  position: absolute;
  top: -48px;
  right: -54px;
  width: 118px;
  height: 26px;
  cursor: pointer;
}
.PromoCodeBox {
  width: 100%;
  height: auto;
  min-height: 32px;
}
.PromoCodeTit {
  float: left;
  width: 144px;
  height: auto;
  min-height: 32px;
  line-height: 32px;
  text-align: right;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #030303;
  font-size: 16px;
}
.PromoCodeConBox {
  float: left;
  width: 700px;
  height: auto;
  min-height: 32px;
}
.PromoCodeConList {
  float: left;
  width: 350px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
  color: #f5495d;
  font-size: 18px;
}
.PromoCodeStepTitle {
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-family: MicrosoftYaHeiBold, sans-serif;
  font-weight: 500;
  color: #030303;
  font-size: 24px;
  letter-spacing: 0.2em;
}
.PromoCodeStepTit {
  width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 32px;
  text-align: left;
  font-family: SimSun, sans-serif;
  font-weight: bold;
  color: #030303;
  font-size: 18px;
  letter-spacing: 0.15em;
}
.PromoCodeStepCon {
  width: 100%;
  height: auto;
  min-height: 26px;
  line-height: 26px;
  text-align: justify;
  text-indent: 2em;
  font-family: SimSun, sans-serif;
  font-weight: bold;
  color: #030303;
  font-size: 16px;
  letter-spacing: 0.12em;
  margin: 4px 0;
}
.PromoCodeStepCon a {
  color: #0000ee;
}
.PromoCodeStepCon a:visited {
  color: #0000ee;
}
.PromoLeftBox {
  float: left;
  width: 400px;
  height: auto;
  min-height: 400px;
}
.PromoRightBox {
  float: right;
  width: 486px;
  height: auto;
  min-height: 400px;
}
.MoneyPhoto {
  width: 316px;
  height: 316px;
  border: 1px solid #3f3f3f;
  margin: 20px auto;
}
.AltWord {
  width: 300px;
  height: auto;
  line-height: 22px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #ff4d4f;
  margin: 0 auto;
}
.iframeVelcro {
  width: 316px;
  height: 316px;
  border: 0;
  outline: none;
}
.MoneyWord {
  display: table;
  width: auto;
  height: auto;
  line-height: 32px;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  color: #999999;
  margin: 20px auto;
}
.AddressShow {
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  color: #3f3f3f;
}

/*数据分析*/
.TrainDataMain {
  width: 896px;
  height: auto;
  min-height: 320px;
  margin: 0 auto;
}
.keyNumLogo {
  width: 160px;
  height: 116px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('http://oss.asfcyy.com/richTextImage/b4ca9eee54a211eb9dc700163e3521a2.png');
  margin: 0 auto;
}
.keyNumTitle {
  width: auto;
  min-width: 100px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-family: PingFangBold, sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #ff0000;
  letter-spacing: 0.1em;
  margin: 40px 0 20px 0;
}
.InputKeyNumBox {
  width: 482px;
  height: 34px;
  margin: 0 auto;
}
.InputKeyNum {
  display: block;
  float: left;
  width: 350px;
  height: 30px;
  border: 2px solid #e5e5e5;
  border-right: 0;
  outline: none;
  padding: 0 0 0 10px;
}
.InputKeyNumBtn {
  float: left;
  width: 132px;
  height: 34px;
  cursor: pointer;
  background-color: #37a6e9;
  line-height: 34px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.12em;
}

/*云程数据*/
.YCClick {
  color: #6dc1ff;
  text-decoration: underline;
  cursor: pointer;
}
.YunChengSelectBox {
  width: 960px;
  height: 34px;
  margin: 20px auto;
}
.YunChengTimeBox {
  float: left;
  width: 260px;
  height: 34px;
  margin: 0 10px;
}
.YunChengItemBox {
  position: relative;
  float: left;
  width: 256px;
  height: 30px;
  margin: 0 10px;
  border: 2px solid #6dc1ff;
}
.YCItemName {
  float: left;
  width: 226px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  text-indent: 0.2em;
  letter-spacing: 0.01em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.YCItemIco {
  float: right;
  width: 30px;
  height: 30px;
}
.YCItemIco .icon-arrow-down {
  width: 30px;
  height: 30px;
  font-size: 20px;
  box-sizing: border-box;
  text-indent: -9999px;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}
.YCItemIco .icon-arrow-down::before,
.YCItemIco .icon-arrow-down::after {
  content: '';
  box-sizing: inherit;
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.YCItemIco .icon-arrow-down::before {
  width: 0.65em;
  height: 0.65em;
  border-style: solid;
  border-width: 2px 0 0 2px;
  -ms-transform: translate(-50%, -75%) rotate(225deg);
  transform: translate(-50%, -75%) rotate(225deg);
}
.YCItemNavBox {
  position: absolute;
  top: 30px;
  left: -2px;
  width: 256px;
  height: 391px;
  border: 2px solid #6dc1ff;
  z-index: 9999999;
  background-color: #ffffff;
}
.YCItemNav {
  width: 256px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: left;
  text-indent: 0.2em;
  letter-spacing: 0.01em;
  cursor: pointer;
  margin: 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.YCItemNav:hover {
  background-color: #6dc1ff;
}
.YCButton {
  float: left;
  width: 100px;
  height: 34px;
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangBold, sans-serif;
  text-align: center;
  letter-spacing: 0.4em;
  margin: 0 10px;
  cursor: pointer;
  color: #ffffff;
  background-color: #3e9ed3;
}
.EChartsBox {
  width: auto;
  min-width: 800px;
  height: auto;
  min-height: 160px;
  margin: 0 auto;
}
#LineEChartsMain,
#LineEChartsOne,
#LineEChartsTwo,
#LineEChartsThree {
  width: 800px;
  height: 400px;
  margin: 10px auto;
}
#PieEChartsMain {
  width: 800px;
  height: 400px;
  margin: 40px auto;
}

/*云程数据2期2021.04.21*/
.AppSearchBox {
  float: left;
  width: 600px;
  height: 30px;
  margin: 3px 0 0 10px;
}
.AppSearchInputBox {
  float: left;
  width: 336px;
  height: 26px;
  border: 2px solid #d2d2d2;
}
.AppSearchBtn {
  float: left;
  width: 96px;
  height: 30px;
  background-color: #0488ca;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: 500;
  letter-spacing: 0.2em;
  color: #ffffff;
}
.SearchInput {
  display: block;
  width: 326px;
  height: 24px;
  line-height: 24px;
  text-align: left;
  font-size: 14px;
  outline: none;
  border: 0;
  padding-left: 10px;
}
.AddStuBtn {
  float: right;
  width: 96px;
  height: 30px;
  margin: 4px 10px 0 0;
  background-color: #eb6100;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: 500;
  letter-spacing: 0.2em;
  color: #ffffff;
}
.YCAddStuInfoList {
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: left;
  text-indent: 4em;
  font-size: 16px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #030303;
}
.YCAddStuInfoList img {
  display: flex;
  width: 140px;
  height: 200px;
  margin: 0 60px;
}
.YCAddStuInfoList span {
  display: block;
  float: left;
}
.StuOfSchoolListBox {
  position: absolute;
  top: 30px;
  left: 16px;
  width: 260px;
  height: auto;
  min-height: 30px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.47);
  overflow-y: auto;
}
.StuOfSchoolList {
  width: 256px;
  height: 24px;
  margin: 2px auto;
  line-height: 24px;
  text-align: left;
  text-indent: 0.2em;
  font-size: 13px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.04em;
  color: #000000;
  background-color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.StuOfSchoolList:hover {
  background-color: #8dcefd;
}

.YCOperatingBtn {
  float: right;
  width: auto;
  min-width: 60px;
  height: 30px;
  background-color: #009944;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  text-indent: 0.2em;
  font-size: 16px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: 500;
  letter-spacing: 0.2em;
  color: #ffffff;
  padding: 0 12px;
}

/*云程数据2021.04.26*/
.OtherEChartsBtn {
  float: right;
  width: auto;
  min-width: 100px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 18px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  letter-spacing: 0.16em;
  color: #6dc1ff;
  cursor: pointer;
  margin: 0 20px;
}
.OtherEChartsBtn:hover {
  color: #009944;
}

.FiveEChartsMain {
  width: 800px;
  height: 400px;
  margin: 40px auto;
}
.YcClickList {
  cursor: pointer;
  background-color: #fff;
}

.YcCodeShowBox {
  width: 980px;
  height: 600px;
  margin: 20px auto;
  border: 2px solid #6dc1ff;
}
.YcCodeShowList {
  float: left;
  width: 320px;
  height: 24px;
  line-height: 24px;
  margin: 4px 0;
  text-align: left;
  text-indent: 1em;
  font-size: 14px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  letter-spacing: 0.16em;
  color: #ff0000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*运程数据2021.09.17*/
.MainListBox {
  width: 800px;
  height: 26px;
}
.MainListBox:hover {
  background-color: #ddd;
}
.MainList {
  float: left;
  height: 26px;
  line-height: 26px;
  text-align: center;
  font-size: 14px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #090909;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.MainContentBox {
  width: 100%;
}
.SLH {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*添加信息弹窗*/
.AddItemBox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 1200px;
  height: 600px;
  margin: auto;
  background-color: #ffffff;
}
.AddItemBigTitle {
  position: relative;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: left;
  text-indent: 1em;
  font-size: 18px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.1em;
  color: #ffffff;
  background-color: #1c7dc4;
}
.AddItemBackIco {
  position: absolute;
  top: 2px;
  right: 2px;
  display: inline-block;
  height: 1em;
  width: 1em;
  font-size: 20px;
  box-sizing: border-box;
  text-indent: -9999px;
  vertical-align: middle;
  cursor: pointer;
}
.AddItemBackIco::before {
  width: 90%;
  border-top: 2px solid;
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  content: '';
  box-sizing: inherit;
  position: absolute;
  left: 50%;
  top: 50%;
}
.AddItemBackIco::after {
  height: 90%;
  border-left: 2px solid;
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  content: '';
  box-sizing: inherit;
  position: absolute;
  left: 50%;
  top: 50%;
}
.AddItemTitle {
  position: relative;
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.4em;
  color: #000000;
}
.AddItemSaveBtn {
  position: absolute;
  bottom: 10px;
  right: 30px;
  width: 157px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  color: #ffffff;
  background-color: #1c7dc4;
  cursor: pointer;
}

.SLHBackIco {
  display: inline-block;
  height: 1em;
  width: 1em;
  font-size: 24px;
  box-sizing: border-box;
  text-indent: -9999px;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}
.SLHBackIco::before,
.SLHBackIco::after {
  content: '';
  box-sizing: inherit;
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.SLHBackIco::before {
  width: 90%;
  border-top: 2px solid;
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.SLHBackIco::after {
  height: 90%;
  border-left: 2px solid;
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.DiplomaName {
  width: 400px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: #333333;
  margin: 5px 0;
}

.ZXXIco {
  display: inline-block;
  height: 1em;
  width: 1em;
  font-size: 20px;
  box-sizing: border-box;
  text-indent: -9999px;
  vertical-align: middle;
  position: relative;
}
.ZXXIco::before,
.ZXXIco::after {
  content: '';
  box-sizing: inherit;
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.DownloadIco {
  position: absolute;
  top: 0;
  right: 0;
  margin: 2px 4px;
  border: solid transparent;
  border-width: 0 1px;
  box-shadow: inset 0 -2px;
  cursor: pointer;
  color: #00c921;
}
.DownloadIco::before {
  width: 0.5em;
  height: 0.5em;
  border-style: solid;
  border-width: 2px 0 0 2px;
  -ms-transform: translate(-50%, -0.3em) rotate(-135deg);
  transform: translate(-50%, -0.3em) rotate(-135deg);
}
.DownloadIco::after {
  height: 0.6em;
  border-left: 2px solid;
  top: 40%;
}

/*2022.09.26*/
.AddItemMainListBox {
  width: 1000px;
  height: 28px;
  margin: 0 auto;
  background-color: #f1f4fa;
}
.AddItemMainList {
  float: left;
  height: 28px;
  line-height: 28px;
  text-align: center;
  font-size: 14px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #797979;
}
.AddItemMainBox {
  width: 1000px;
  height: 482px;
  margin: 0 auto;
}
.ActivityAskSelect {
  float: left;
  margin-right: 80px;
}
.ActivityAskIco {
  float: left;
  width: 28px;
  height: 28px;
  border: 2px solid #6dc1ff;
  border-radius: 50%;
  cursor: pointer;
}
.askSelectHave {
  width: 20px;
  height: 20px;
  background-color: #6dc1ff;
  border-radius: 50%;
  margin: 4px;
}
.ActivityContentWord {
  float: left;
  width: auto;
  height: 32px;
  line-height: 32px;
  text-align: left;
  text-indent: 1em;
  font-size: 24px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #333333;
}
.AddInfoMainCon {
  float: left;
  width: 400px;
  height: 26px;
  border: 1px solid #1c7dc4;
  margin: 2px 0;
}
.AddInfoMainInput {
  display: block;
  border: 0;
  outline: none;
  width: 396px;
  height: 24px;
  line-height: 24px;
  text-align: left;
  text-indent: 0.4em;
  font-size: 12px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #000;
}

/*2023.04.28 个人云程部分*/
.css-icon {
  display: inline-block;
  height: 1em;
  width: 1em;
  font-size: 20px;
  box-sizing: border-box;
  text-indent: -9999px;
  vertical-align: middle;
  position: relative;
}
.css-icon::before,
.css-icon::after {
  content: '';
  box-sizing: inherit;
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.icon-check::before {
  border-style: solid;
  border-width: 0 0 2px 2px;
  height: 0.5em;
  width: 0.9em;
  -ms-transform: translate(-50%, -0.375em) rotate(-45deg);
  transform: translate(-50%, -0.375em) rotate(-45deg);
}
.iconCheck {
  border-radius: 5px;
  background-color: #0488ca;
  color: #fff;
  margin: 10px 0;
  cursor: pointer;
}
.iconCheckBox {
  width: 16.8px;
  height: 16.8px;
  border: 2px solid #8a8a8a;
  border-radius: 5px;
  margin: 10px 0;
  cursor: pointer;
}
.PeYcVipTitle {
  width: 840px;
  height: auto;
  min-height: 40px;
  margin: 40px auto;
  line-height: 40px;
  text-align: left;
  font-size: 28px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #000;
}
.PeYcVipPayTip {
  float: left;
  width: 700px;
  height: auto;
  min-height: 32px;
  color: #9a9a9a;
  margin-left: 36px;
  font-size: 15px;
  line-height: 32px;
}
.PeYcVipBtn {
  width: 110px;
  height: 40px;
  background-color: #0488ca;
  border-radius: 14px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.8em;
  text-indent: 0.8em;
  cursor: pointer;
}

.PeYcVipBtn2 {
  float: left;
  width: 140px;
  height: 40px;
  background-color: #0488ca;
  border-radius: 2px;
  line-height: 40px;
  text-align: left;
  font-size: 18px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.1em;
  text-indent: 0.8em;
  cursor: pointer;
  margin: 0 30px 0 0;
}
.PeYcVipBtnIco1 {
  width: 30px;
  height: 30px;
  margin: 5px;
  float: right;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('http://oss.asfcyy.com/richTextImage/c6557894ef7911ee9dc800163e3521a2.png');
}
.PeYcVipBtnIco2 {
  width: 30px;
  height: 30px;
  float: right;
  margin: 5px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('http://oss.asfcyy.com/richTextImage/d63ec56cef7911ee9dc800163e3521a2.png');
}

.PeYcVipList {
  width: 840px;
  height: auto;
  min-height: 32px;
  margin: 20px auto;
  line-height: 32px;
  text-align: left;
  font-size: 17px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #7a7a7a;
}

/*2023.08.30 个人云程单位账号*/
.GrServiceListBox {
  width: 840px;
  border-radius: 6px;
  border: 1px solid #999999;
  margin: 40px auto;
  padding: 40px;
}
.GrServiceList {
  float: left;
  width: 185px;
  height: 120px;
  background-color: #cecdcd;
  border-radius: 8px;
  margin: 60px 40px;
  cursor: pointer;
  border-left: 15px solid #bbbbbb;
  line-height: 120px;
  text-align: center;
  font-size: 28px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #fff;
}
.GrServiceList:hover {
  background-color: #16a1b9;
  border-color: #10879b;
}
.GrServiceTop {
  float: left;
  width: 100px;
  height: 28px;
  margin: 2px 0 0 40px;
  border: 2px solid #ccc;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
  line-height: 28px;
  text-align: center;
  font-size: 15px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #1c1c1c;
}
.GrServiceTop:hover {
  background-color: #0488ca;
  border-color: #0488ca;
  color: #fff;
}
.GrServicePerBox {
  width: 960px;
  border-radius: 6px;
  border: 1px solid #999999;
  margin: 20px auto;
}
.GrServicePerTit {
  width: 100%;
  height: 36px;
  margin: 10px 0;
}
.GrServicePerTop {
  float: right;
  width: 100px;
  height: 28px;
  margin: 2px 20px 0 0;
  border: 2px solid #ccc;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
  line-height: 28px;
  text-align: center;
  font-size: 15px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #1c1c1c;
}
.GrServicePerTop:hover {
  background-color: #0488ca;
  border-color: #0488ca;
  color: #fff;
}
.GrServicePerNumBox {
  float: left;
  width: auto;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 18px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #1c1c1c;
  margin: 0 0 0 20px;
}
.GrServicePerMainBox {
  width: 920px;
  border-radius: 6px;
  border: 1px solid #d2d2d2;
  margin: 0 auto;
}
.ServiceMainTitleBox {
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #d2d2d2;
  background-color: #fafafa;
  border-radius: 6px 6px 0 0;
}
.ServiceMainTitleList {
  float: left;
  height: 28px;
  line-height: 28px;
  text-align: left;
  font-size: 17px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  text-indent: 0.4em;
  color: #030303;
  border-right: 1px solid #d2d2d2;
  margin: 10px 0;
}
.ServicePerMainList {
  width: 100%;
  height: 30px;
}
.ServicePerMainList:hover {
  background-color: #fafafa;
}
.ServicePerLister {
  float: left;
  height: 30px;
  line-height: 30px;
  text-align: left;
  font-size: 14px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  text-indent: 0.3em;
  color: #060606;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ServicePerBorder {
  width: 100%;
  height: 1px;
  background-color: #cccccc;
}
.GrServicePerSalesTit {
  width: 100%;
  height: 28px;
  line-height: 28px;
  text-align: left;
  font-size: 19px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #020202;
  margin: 16px 0;
  text-indent: 1em;
}
.GrServicePerSalesBox {
  float: left;
  width: 48%;
  height: auto;
  margin: 0 1%;
}
.GrServicePerSalesMain {
  width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 32px;
  margin: 60px 0;
}
.GrServicePerSalesMainWord {
  float: left;
  width: 100px;
  height: 32px;
  line-height: 32px;
  text-align: right;
  font-size: 15px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #020202;
}
.GrServicePerSalesMainInput {
  position: relative;
  float: left;
  width: 340px;
  height: 28px;
  border: 2px solid #ccc;
  border-radius: 4px;
}
.ClearInput {
  float: right;
  width: 16px;
  height: 16px;
  margin: 6px;
  border-radius: 50%;
  background-color: #989898;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
}

.icon-close1::before {
  width: 60%;
  border-top: 2px solid;
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.icon-close1::after {
  height: 60%;
  border-left: 2px solid;
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.GrServiceDownBtn {
  float: right;
  width: 28px;
  height: 28px;
  color: #989898;
}

.icon-arrow-down1::before {
  height: 0.65em;
  width: 0.65em;
  border-style: solid;
  border-width: 2px 0 0 2px;
  -ms-transform: translate(-50%, -75%) rotate(225deg);
  transform: translate(-50%, -75%) rotate(225deg);
}
.GrServiceDownNav {
  float: left;
  width: 300px;
  height: 28px;
  line-height: 28px;
  text-align: left;
  font-size: 15px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #020202;
  text-indent: 0.4em;
}
.GrServiceDownSelectBox {
  position: absolute;
  top: 30px;
  left: 0;
  width: 338px;
  height: auto;
  max-height: 120px;
  border: 1px solid #989898;
  z-index: 9;
  background-color: #fff;
}
.GrServiceDownSelectList {
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-indent: 0.4em;
  text-align: left;
  font-size: 14px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #020202;
  cursor: pointer;
  margin: 4px 0;
}
.GrServiceDownSelectList:hover {
  background-color: #989898;
}

.OrderCodeSearchBox {
  width: 940px;
  height: auto;
  min-height: 42px;
  margin-left: 20px;
}
.OrderCodeSearchTit {
  float: left;
  width: 180px;
  height: 42px;
  line-height: 42px;
  text-align: left;
  font-size: 15px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #000;
  cursor: pointer;
}
.OrderCodeSearchBtn {
  float: left;
  width: 72px;
  height: 32px;
  margin: 5px 0 0 16px;
  border-radius: 6px;
  background-color: #0488ca;
  cursor: pointer;
  line-height: 32px;
  text-align: center;
  font-size: 15px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #fff;
}
.OrderCodeInfoBox {
  float: left;
  width: 220px;
  height: 22px;
  line-height: 22px;
  text-align: left;
  font-size: 13px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #000;
  margin: 5px 10px 10px 0;
}
.OrderCodeInfoTit {
  float: left;
  width: 70px;
}
.OrderCodeInfo {
  float: left;
  width: calc(100% - 70px);
  color: #e9783d;
}

/*2023.11.6*/
.RadarItemNav {
  width: 100%;
  height: 100%;
  background-color: #15619a;
}
.RadarItemNavList {
  width: 244px;
  height: 43px;
  border-right: 6px solid #15619a;
  border-bottom: 1px solid #fff;
  line-height: 43px;
  text-align: left;
  font-size: 13px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  text-indent: 0.6em;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.RadarItemNavList:hover {
  background-color: #fdad4d;
  border-right-color: #60c7ef;
  color: #333;
  font-size: 15px;
}
.RadarStart {
  float: right;
  width: 740px;
  height: 100%;
}

#RadarMain {
  width: 800px;
  margin: 10px auto;
}
#RadarMainFive {
  width: 800px;
  margin: 10px auto;
}

.ttsCodeTitBox {
  width: 800px;
  height: 36px;
  padding: 10px 0 0 0;
  margin: 0 0 10px 0;
  border-top: 1px solid #999;
}
.ttsCodeTitBtnBox {
  float: left;
  width: 250px;
  height: 36px;
}
.ttsCodeTitWord {
  float: left;
  width: 300px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 20px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #2f4554;
}
.ttsCodeTitBtn {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 15px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #fff;
  background-color: #0488ca;
  border-radius: 5px;
  margin: 3px 5px 0 0;
  cursor: pointer;
}
.RadarBorder {
  width: 100%;
  height: 1px;
  background-color: #999;
}

/*2024.03.29*/
.SwitchBtn {
  float: left;
  width: auto;
  height: 26px;
  line-height: 30px;
  text-align: left;
  font-size: 15px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  text-indent: 2em;
  text-decoration: underline;
  color: #0488ca;
  cursor: pointer;
}
.trapezoid {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: -14px;
  width: 13px;
  height: 50px;
  background-color: #15619a;
  transform: perspective(0.1em) rotateY(30deg);
  cursor: pointer;
  z-index: 195;
}
.RadarItemNavBox {
  position: absolute;
  width: 250px;
  margin: 0 0 0 10px;
}

.RadarMainTipsBox {
  float: right;
  width: 450px;
  height: 400px;
}
.TipsMainBox {
  width: 100%;
  height: 315px;
  overflow: hidden;
}
.TipsMainMore span {
  display: block;
  line-height: 40px;
  text-align: left;
  font-size: 17px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  letter-spacing: 0.12em;
  color: #fb611d;
}
.TipsBoxTit {
  width: 100%;
  height: 42px;
  line-height: 42px;
  text-align: left;
  font-size: 17px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #222;
}
.TipsBoxCon {
  width: 96%;
  height: auto;
  line-height: 26px;
  text-align: left;
  font-size: 15px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #0488ca;
  text-indent: 2em;
}
.FiveLineMainBox {
  width: 100%;
  height: auto;
  min-height: 380px;
}
.FiveLineTipsBox {
  float: right;
  width: 290px;
  height: auto;
}
.PrizeTitle {
  font-size: 32px;
  margin-top: 10px;
  font-weight: 600;
}
.PrizeMessage {
  margin-top: 30px;
  padding: 30px;
  font-size: 20px;
}
.PrizeItem {
  display: flex;
  align-items: flex-end;
}
.PrizeSubmitArea {
  margin-top: 88px;
  margin-left: 268px;
  display: flex;
  align-items: flex-end;
}
.PrizeButton {
  width: 150px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  border-radius: 5px;
  background-color: #009944;
  cursor: pointer;
}
.PrizeTips {
  font-size: 14px;
  color: #bb0000;
  margin-left: 20px;
}
.PrizeTableInfoBox {
  width: calc(100% - 10px);
  height: auto;
  border: 1px solid #000;
  margin-top: 5px;
  font-weight: normal;
}
.PrizeTableHeader {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  border-bottom: 1px solid #000;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #868686;
}
.PrizeTableHeaderInfo {
  text-align: center;
  line-height: 20px;
  margin: 26px 0 0 0;
}
.PrizeTableContentBox {
  width: 100%;
  height: auto;
  margin-top: 15px;
}
.PrizeTableContent {
  display: flex;
  justify-content: space-between;
}
.PrizeTableContentInfo {
  color: #000;
  font-size: 18px;
  text-align: center;
  height: 30px;
}
/*2024.04.07*/
.YCMakeInvoiceBox {
  width: 90%;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 15px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #0488ca;
  margin: 10px auto;
  text-decoration: underline;
  cursor: pointer;
}
.ManualSuccessIco {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('../../style/image/SuccessIco.png');
}
.TipPopUpContent {
  width: 92%;
  height: 560px;
  line-height: 26px;
  font-size: 15px;
  text-align: justify;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  color: #000;
  letter-spacing: 0.06em;
  text-indent: 2em;
  margin: 5px auto;
}
.TipsMainMore {
  position: relative;
  float: right;
  width: 103px;
  height: 40px;
  cursor: pointer;
}
