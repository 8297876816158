/*关于微信二维码付款类型*/
/*初教六 7*/
/*VIP 8*/
/*VIP物品 9*/
.PayActiveMainBoxes{
    width: 970px;
    height: auto;
    margin: 0 auto;
}
.PayActiveMainBoxesTit{
    position: relative;
    width: auto;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #242424;
}
.PayActiveBorderBottom{
    border-bottom: 5px solid #E5E5E5;
}
.PayActiveBackBox{
    position: absolute;
    top: 24px;
    right: 0;
}
.PayActiveLittleTitle{
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #5F5F5F;
}
.PayActiveButton{
    position: relative;
    display: block;
    width: 163px;
    height: 48px;
    line-height: 46px;
    font-size: 18px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.15em;
    border: 0;
    outline: none;
    cursor: pointer;
    margin: 25px auto;
}
.PayArrowIco{
    position: absolute;
    top: 19px;
    right: 22px;
    width: 10px;
    height: 12px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../style/image/PayArrow.png");
}
.PayBoxes{
    position: relative;
    width: 100%;
    height: auto;
}
.PayTitleBox{
    width: 100%;
    height: auto;
}
.PayCodeBox{
    float: left;
    width: 192px;
    height: 192px;
    border: 12px solid;
    margin: 20px;
    overflow: hidden;
}
.PayCodePhoto{
    display: block;
    width: 100%;
    height: 100%;
}
.PayCodeTipsWord{
    float: left;
    width: auto;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.15em;
    color: #3E3E3E;
    margin-top: 128px;
}
.PayMoneyWord{
    float: right;
    width: auto;
    height: 22px;
    line-height: 22px;
    font-size: 20px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.15em;
    color: #212121;
    margin-top: 124px;
}
.PayMoney￥Word{
    float: right;
    width: auto;
    height: 36px;
    line-height: 36px;
    font-size: 34px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.15em;
    color: #EB3D00;
    margin: 100px 20px 0 20px;
}
.PayInfoTitBox{
    width: 100%;
    height: 32px;
}
.PayInfoTit{
    float: left;
    width: 142px;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.15em;
    color: #212121;
    background-color: #E5E5E5;
}
.PayInfoBox{
    width: 968px;
    height: 106px;
    border: 1px solid #DCDCDC;
}
.PayInfoHeaderBox{
    width: 968px;
    height: 40px;
    border-bottom: 1px solid #DCDCDC;
}
.PayInfoHeaderList{
    float: left;
    line-height: 40px;
    font-size: 16px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.15em;
    color: #939393;
}
.PayInfoMainBox{
    width: 968px;
    height: 65px;
}
.PayInfoMainList{
    float: left;
    line-height: 65px;
    font-size: 16px;
    font-family:SimSun,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.15em;
    color: #282828;
}
.InvoiceInfoTit{
    float: left;
    width: auto;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.15em;
    color: #212121;
}
.InvoiceInfoCheckbox{
    float: left;
    width: 18px;
    height: 16px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 8px 10px 0 0;
}
.InvoiceInfoListTit{
    float: left;
    width: auto;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.15em;
    color: #3E3E3E;
}
.InvoiceInfoListIco{
    float: left;
    width: 14px;
    height: 14px;
    border: 2px solid #00B7EF;
    border-radius: 50%;
    margin: 7px 6px 0 0;
    cursor: pointer;
}
.InvoiceInput{
    float: left;
    display: block;
    width: 240px;
    height: 28px;
    border: 1px solid #DCDCDC;
    line-height: 28px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-indent: 1em;
    letter-spacing: 0.15em;
    color: #3E3E3E;
    outline: none;
    margin-left: 20px;
}
.InvoiceInputYes{
    float: left;
    width: 240px;
    height: 28px;
    line-height: 28px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-indent: 1em;
    letter-spacing: 0.15em;
    color: #3E3E3E;
    margin-left: 20px;
}
.InvoiceSaveBtn{
    display: block;
    width:122px;
    height:35px;
    line-height: 33px;
    font-size: 18px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.15em;
    border: 0;
    outline: none;
    cursor: pointer;
    margin: 20px auto;
    color: #FFFFFF;
    background-color: #6EC2FF;
    border-radius:5px;
}

.ReSuccessBox{
    width: 330px;
    height: 54px;
    margin: 0 auto;
}
.ReSuccessIco{
    float: left;
    width:54px;
    height:54px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../style/image/SuccessIco.png");
}
.ReSuccessWord{
    float: right;
    width:auto;
    height:54px;
    line-height:54px;
    font-size:24px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight:bold;
    letter-spacing: 0.15em;
    color:#888888;
}

.PaySuccessBtn{

}
.PayTypeBackBtn{
    position: absolute;
    top: -41px;
    right: 0;
    width: 118px;
    height: 26px;
    cursor: pointer;
}
.AlipayLogoBox{
    width: 216px;
    height: 216px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../style/image/alipay.jpg");
    border-radius: 48px;
    cursor: pointer;
}




/*后加对公转账部分样式*/
.PayYYInfoBox{
    float: left;
    width: 580px;
    height: auto;
    min-height: 200px;
}
.PayYYListBox{
    width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 32px;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0.15em;
    color: #3E3E3E;
    margin: 9px 0;
}
.PayYYListTit{
    float: left;
    width: 120px;
    margin: 0 10px 0 80px;
}
.PayYYListCon{
    font-size: 15px;
}
.SavePayTransferBtn{
    position: absolute;
    right: 20px;
    bottom: 16px;
    display: block;
    width:122px;
    height:35px;
    line-height: 33px;
    font-size: 18px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.15em;
    border: 0;
    outline: none;
    cursor: pointer;
    color: #FFFFFF;
    background-color: #6EC2FF;
    border-radius:5px;
}










