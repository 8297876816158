.GameMainBox{
    width: 100%;
    height: auto;
    min-height: 460px;
}

.GameSignUpIcoBoxes{
    width: 820px;
    height: auto;
    margin: 0 auto;
}
.GameSignUpIcoBox{
    float: left;
    width: 408px;
    height: 340px;
}
.GameSignUpIco{
    width: 200px;
    height: 224px;
    margin: 24px auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}
.GameSignUpIcoBox:nth-of-type(4n+1) .GameSignUpIco{
    background-image: url("./gameimage/gameIcoOne.png");
}
.GameSignUpIcoBox:nth-of-type(4n+2) .GameSignUpIco{
    background-image: url("./gameimage/gameIcoTwo.png");
}
.GameSignUpIcoBox:nth-of-type(4n+3) .GameSignUpIco{
    background-image: url("./gameimage/gameIcoThree.png");
}
.GameSignUpIcoBox:nth-of-type(4n+4) .GameSignUpIco{
    background-image: url("./gameimage/gameIcoFour.png");
}
.GameSignUpIco:hover{
    width: 230px;
    height: 260px;
    margin: 6px auto;
}
.GameSignUpIcoBox:nth-of-type(4n+1) .GameSignUpIco:hover{
    background-image: url("./gameimage/GameOne.png");
}
.GameSignUpIcoBox:nth-of-type(4n+2) .GameSignUpIco:hover{
    background-image: url("./gameimage/GameTwo.png");
}
.GameSignUpIcoBox:nth-of-type(4n+3) .GameSignUpIco:hover{
    background-image: url("./gameimage/GameThree.png");
}
.GameSignUpIcoBox:nth-of-type(4n+4) .GameSignUpIco:hover{
    background-image: url("./gameimage/GameFour.png");
}
.GameSignUpIcoTitle{
    width: 320px;
    height: 64px;
    margin: 0 auto;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #333333;
    overflow: hidden;
}





.GameInfoTitleBox{
    width: 100%;
    height: 28px;
    border-bottom: 1px solid #AAAAAA;
}
.GameInfoTitle{
    float: left;
    width: auto;
    height: 28px;
    line-height: 28px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #030303;
    text-indent: 1em;
}
.GameInfoTitle span:hover{
    color: #FB621E;
    cursor: pointer;
}







.GPClickBox{
    width: 800px;
    height: auto;
    min-height: 240px;
    margin:36px auto;
}
.GPClickUlBox{
    display: block;
    float: left;
    width: 360px;
    height: auto;
    min-height: 240px;
}
.GPClickLiBox{
    width: 100%;
    height: auto;
    min-height: 30px;
    margin: 0 0 48px 0;
}
.GPClickInfoTit{
    float: left;
    width: auto;
    height: 30px;
    line-height: 28px;
    text-align: left;
    font-size: 18px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.09em;
    color: #3F3F3F;
}
.GPClickInfoConShowSave{
    float: right;
    width: 252px;
    height: auto;
    min-height: 26px;
    line-height: 26px;
    text-align: left;
    font-size: 18px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.09em;
    color: #3F3F3F;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.GPClickInfoCon{
    position: relative;
    float: right;
    width: 252px;
    height: auto;
    min-height: 26px;
}
.GPDownBtn{
    float: right;
    width: 60px;
    height: 30px;
    background-color: #6EC2FF;
    cursor: pointer;
}
.GPSelectShow{
    float: right;
    width: 180px;
    height: 26px;
    border: 2px solid #E5E5E5;
    border-right: 0;
    line-height: 26px;
    font-size: 14px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.09em;
    text-align: left;
    color: #3F3F3F;
    padding: 0 0 0 10px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.GPSelect{
    position: absolute;
    top: 28px;
    left: 0;
    width: 192px;
    height: auto;
    z-index: 998;
    background-color: #E5E5E5;
}
.GPOption{
    width: 180px;
    height: 26px;
    line-height: 24px;
    font-size: 14px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.09em;
    text-align: left;
    color: #3F3F3F;
    padding: 0 0 0 10px;
    cursor: pointer;
}
.GPProjectAdd{
    position: relative;
    float: right;
    width: 252px;
    height: 30px;
}
.GPProjectAddBtn{
    position: relative;
    float: right;
    width: 80px;
    height: 30px;
    background-color: #6EC2FF;
    cursor: pointer;
}
.GPProjectAddBtn span{
    position: absolute;
    top: 0;
    left: 13px;
    width: auto;
    height: 30px;
    line-height: 28px;
    font-size: 14px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.09em;
    text-align: center;
    color: #FFFFFF;
}
.GPProjectSelect{
    position: absolute;
    top: 28px;
    left: 0;
    width: 252px;
    height: auto;
    z-index: 998;
    background-color: #E5E5E5;
    box-shadow: 0 5px 6px 0 rgba(6,0,1,0.23);
}
.GPProjectOption{
    width: 242px;
    height: 26px;
    line-height: 24px;
    font-size: 13px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.09em;
    text-align: right;
    color: #3F3F3F;
    padding: 0 10px 0 0;
    cursor: pointer;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.GPOption:hover,
.GPProjectOption:hover{
    background-color: #8DCEFD;
    box-shadow: 0 5px 6px 0 rgba(6,0,1,0.23);
}
.GPProjectSelectShow{
    float: right;
    width: 248px;
    height: 26px;
    border: 2px solid #E5E5E5;
    line-height: 26px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #3F3F3F;
    margin-bottom: 2px;
}
.GPProjectSelectWord{
    float: left;
    width: 190px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.06em;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    padding-left: 10px;
}
.GPProjectSelectDelete{
    float: right;
    width: 12px;
    height: 12px;
    background-image: url("../../style/image/cross.png");
    background-size: 12px 12px;
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 7px 10px;
}
.GPManyNum{
    float: right;
    width: 16px;
    text-align: center;
}
.GPListTitle{
    width: 800px;
    height: 30px;
    margin: 0 auto;
    line-height: 28px;
    text-align: left;
    font-size: 18px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.09em;
    color: #3F3F3F;
}
.GPUploadFileBox{
    width: 584px;
    height: auto;
    min-height: 30px;
    margin: 0 auto;
}
.GPUploadFileList{
    width: 584px;
    height: 26px;
    margin: 0 0 14px 0;
}
.GPUploadFileBtn{
    position: relative;
    float: left;
    width: 133px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-size: 14px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.09em;
    color: #696969;
    cursor: pointer;
    background-color: #DCDCDC;
}
.GPUploadFileName{
    float: left;
    width: 400px;
    height: 26px;
    line-height: 26px;
    text-align: left;
    font-size: 14px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.09em;
    color: #3F3F3F;
    margin: 0 0 0 13px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.GPUploadFileIco{
    float: right;
    width: 18px;
    height: 18px;
    background-image: url("../../style/image/Checkmark.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 4px 10px;
}
.GamePersonalBtn{
    width: 167px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    font-size: 20px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.25em;
    color: #FFFFFF;
    margin: 0 auto;
    background: #6EC2FF;
    border-radius: 10px;
    cursor: pointer;
}
.GamePersonalBtnTip{
    width: 100%;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.25em;
    color: #FF0000;
    margin: 16px 0 0 0;
    padding-bottom: 36px;
}
.GamePersonalInfoBtn{
    width: 98px;
    height: 31px;
    line-height: 31px;
    text-align: center;
    font-size: 12px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.25em;
    color: #FFFFFF;
    margin: 0 auto;
    background: #6EC2FF;
    border-radius: 10px;
    cursor: pointer;
}
















.GGWindowBox{
    width: 974px;
    height: 26px;
    margin: 10px auto;
}
.GGWindowList{
    position: relative;
    float: left;
    width: 175px;
    height: 24px;
    line-height: 24px;
    text-align: left;
    text-indent: 0.4em;
    font-size: 14px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.05em;
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #0068B7;
    cursor: pointer;
    margin: 0 2px 0 0;
}
.GGWindowList:hover{
    color: #FFFFFF;
    background-color: #0068B7;
}
.GGWindowDelIco{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 14px;
    height: 14px;
}
.IcoPublic::before {
    width: 90%;
    border-top: 2px solid;
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
    content: '';
    box-sizing: inherit;
    position: absolute;
    left: 50%; top: 50%;
}
.IcoPublic::after {
    height: 90%;
    border-left: 2px solid;
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
    content: '';
    box-sizing: inherit;
    position: absolute;
    left: 50%; top: 50%;
}
.IcoPublic {
    height: 1em; width: 1em;
    box-sizing: border-box;
    text-indent: -9999px;
    vertical-align: middle;
    position: relative;
    color: #ff0000;
}
.GameGroupTitle{
    width: 974px;
    height: 20px;
    line-height: 20px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.05em;
    color: #464646;
    margin: 10px auto;
}


/*添加人员*/
.GGAddPeopleBox{
    width: 974px;
    height: auto;
    min-height: 360px;
    border: 1px solid #AAAAAA;
    margin: 0 auto;
}
.GGAPSearchBox{
    width: 100%;
    height: 26px;
    margin: 17px 0;
}
.GGAPSearchTit{
    float: left;
    width: auto;
    height: 26px;
    line-height: 26px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.05em;
    color: #3E3E3E;
    margin: 0 16px;
}
.GGAPSearchMainBox{
    float: left;
    width: auto;
    height: 26px;
}
.GGAPSearchInput{
    display: block;
    float: left;
    width: 285px;
    height: 22px;
    line-height: 22px;
    text-align: left;
    font-size: 12px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: 500;
    text-indent: 1em;
    letter-spacing: 0.15em;
    color: #6C6C6C;
    border: 1px solid #A0A0A0;
    outline: none;
}
.GGAPSearchBtn{
    float: left;
    width:80px;
    height:26px;
    line-height: 26px;
    text-align: center;
    font-size: 14px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.2em;
    color: #FFFFFF;
    background-color: #0068B7;
    cursor: pointer;
}
.GGAPSearchDescription{
    float: left;
    width: auto;
    height: 26px;
    line-height: 26px;
    text-align: left;
    font-size: 14px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.03em;
    color: #FF0000;
    margin: 0 0 0 16px;
}
.GGAPListTitleBox{
    width: 964px;
    height: 28px;
    background-color: #DCDCDC;
    margin: 4px auto;
}
.GGAPListTitleName{
    float: left;
    width: 240px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 14px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.05em;
    color: #000000;
}
.GGAPListMainBox{
    width: 964px;
    height: 266px;
    margin: 0 auto;
    overflow-y: auto;
}
.GGAPListMainList{
    width: 960px;
    height: 28px;
    margin: 9px auto;
}
.GGAPListMainListContent{
    float: left;
    width: 238px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.05em;
    color: #464646;
}
.GGAPListMainBtn{
    width:80px;
    height:26px;
    line-height: 26px;
    text-align: center;
    font-size: 14px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.2em;
    color: #FFFFFF;
    background-color: #0068B7;
    cursor: pointer;
    margin: 1px auto;
}
.GGAPBorder{
    width: 964px;
    height: 1px;
    background-color: #AAAAAA;
    margin: 0 auto;
}

/*添加人员弹窗*/
.GGAddPopUpBgBox{
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    margin: auto;
    width: 936px;
    height: auto;
    min-height: 840px;
    background-color: #FFFFFF;
    z-index: 999999;
}
.GGAddPopBorder{
    width: 762px;
    height: auto;
    min-height: 648px;
    border: 1px solid #3F3F3F;
    margin: 0 auto;
}
.GGAddPopTitleBox{
    width: 750px;
    height: 56px;
    margin: 0 auto;
    border: 1px solid #3F3F3F;
    line-height: 56px;
    text-align: center;
    font-size: 20px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.2em;
    color: #3F3F3F;
}
.GGAddPopMainBox{
    width: 750px;
    height: auto;
    min-height: 574px;
    margin: 0 auto;
    border: 1px solid #3F3F3F;
}
.GGAPInfoBox{
    float: left;
    width: 308px;
    height: auto;
    min-height: 574px;
    margin: 0 0 0 20px;
}
.GGAPInfoPhoto{
    width: 308px;
    height: 410px;
    margin: 20px 0;
}
.GGAPInfoPhoto img{
    display: block;
    width: 100%;
    height: 100%;
}
.GGAPInfoListBox{
    width: 308px;
    height: 28px;
    margin: 16px 0;
}
.GGAPInfoListTit{
    float: left;
    width: 80px;
    height: 28px;
    line-height: 28px;
    text-align: right;
    font-size: 18px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.2em;
    color: #3F3F3F;
}
.GGAPInfoListCon{
    float: right;
    width: 220px;
    height: 28px;
    line-height: 28px;
    text-align: left;
    font-size: 18px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.2em;
    color: #3F3F3F;
}
.GGAPSelectBox{
    float: left;
    width: 374px;
    height: auto;
    min-height: 554px;
    margin: 0 0 20px 20px;
}
.GGAddPopUpBtnBox{
    width: 248px;
    height: 44px;
    border: 2px solid #6EC2FF;
    border-radius: 10px;
    margin: 36px auto;
    cursor: pointer;
}
.GGAddPopUpBtnOK{
    float: left;
    width: 124px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 20px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.25em;
    color: #000000;
    background-color: #6EC2FF;
}
.GGAddPopUpBtnERR{
    float: right;
    width: 124px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 20px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.25em;
    color: #000000;
}

/*分配科目*/
.GGItemPerBox{
    width: 958px;
    height: 262px;
    border: 1px solid #1B1B1B;
    margin: 7px auto;
}
.GGItemPerTitle{
    width: 100%;
    height: 26px;
    line-height: 26px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-indent: 1em;
    letter-spacing: 0.25em;
    color: #464646;
    background-color: #DCDCDC;
}
.GGItemPerConBox{
    width: 958px;
    height: 236px;
    overflow-y: auto;
}
.GGItemPerConList{
    position: relative;
    float: left;
    width: 436px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-size: 16px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    color: #464646;
    margin: 10px 20px;
}
.GGItemPerName{
    float: left;
    width: 160px;
}
.GGItemPerNum{
    float: left;
    width: 72px;
}
.GGItemPerItemNum{
    float: left;
    width: 64px;
}
.GGItemPerItemBtn{
    position: relative;
    float: right;
    width: 98px;
    height: 20px;
    line-height: 20px;
    text-align: left;
    text-indent: 1.6em;
    font-size: 12px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    letter-spacing: 0.06em;
    color: #FFFFFF;
    margin: 3px 20px 3px 0;
    background-color: #0068B7;
    cursor: pointer;
}
.GGItemArrBox{
    width: 960px;
    height: auto;
    min-height: 250px;
    margin: 0 auto;
}
.GGItemSubjectBox{
    float: left;
    width: 292px;
    height: 208px;
    border: 1px solid #DCDCDC;
    margin: 7px 12px;
}
.GGItemSubTitle{
    width: 100%;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-size: 12px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    color: #464646;
    background-color: #6EC2FF;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.GGItemSubMainBox{
    width: 292px;
    height: 182px;
    overflow-y: auto;
}
.GGItemSubListBox{
    width: 288px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    color: #464646;
    margin: 10px 0;
}

/*报名信息汇总*/
.GGSInfoTopBox{
    width: 950px;
    height: 94px;
    margin: 8px auto;
}
.GGSInfoLeaderBox{
    float: left;
    width: 465px;
    height: 92px;
    border: 1px solid #DCDCDC;
}
.GGSInfoCoachBox{
    float: right;
    width: 465px;
    height: 92px;
    border: 1px solid #DCDCDC;
}
.GGSInfoTitBox{
    width: 100%;
    height: 26px;
    line-height: 26px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-indent: 1em;
    letter-spacing: 0.1em;
    color: #464646;
    background-color: #DCDCDC;
}
.GGSInfoTitBtn{
    float: right;
    width: 89px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    text-indent: 0.6em;
    font-size: 12px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: 500;
    letter-spacing: 0.25em;
    color: #FFFFFF;
    background-color: #707070;
    margin: 3px;
    cursor: pointer;
}
.GGSInfoTopConBox{
    width: 100%;
    height: 66px;
    overflow-y: auto;
}
.GGSInfoTopNameList{
    float: left;
    width: 144px;
    height: 20px;
    margin: 6px 5px;
}
.GGSInfoListCheckbox{
    float: left;
    width: 14px;
    height: 14px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 3px;
    cursor: pointer;
}
.GGSInfoListTopName{
    float: left;
    width: 124px;
    height: 20px;
    line-height: 20px;
    text-align: left;
    text-indent: 0.4em;
    font-size: 16px;
    font-family:SimHei,sans-serif;
    font-weight: 400;
    text-decoration: underline;
    color: #00CFAF;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.GGSInfoStuBox{
    width: 948px;
    height: 388px;
    margin: 8px auto;
    border: 1px solid #DCDCDC;
}
.GGSInfoStuConTit{
    width: 948px;
    height: 32px;
}
.GGSInfoStuConBorder{
    width: 912px;
    height: 1px;
    background-color: #A0A0A0;
    margin: 0 auto;
}
.GGSInfoStuContent{
    width: 948px;
    height: 329px;
    overflow-y: auto;
}
.GGSInfoStuTitList{
    float: left;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.2em;
    color: #5E5E5E;
}
.GGSInfoStuContentList{
    width: 944px;
    height: 22px;
    margin: 10px 0;
}
.GGSInfoStuConList{
    float: left;
    height: 22px;
    line-height: 22px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.2em;
    color: #5E5E5E;
}
.GGSInfoStuListCheckbox{
    float: right;
    width: 14px;
    height: 14px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 4px;
    cursor: pointer;
}
.GGSInfoStuUpBtn{
    font-weight: 500;
    text-decoration: underline;
    color: #00CFAF;
    cursor: pointer;
}
.GameGroupUpdateBtn{
    position: absolute;
    top: 61px;
    left: 176px;
    width: 118px;
    height: 43px;
    line-height: 43px;
    text-align: center;
    font-size: 18px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    letter-spacing: 0.25em;
    color: #FFFFFF;
    margin: 0 auto;
    background: #6EC2FF;
    border-radius: 5px;
    cursor: pointer;
}
.GameGroupUpdateDes{
    position: absolute;
    top: 70px;
    left: 320px;
    width: 600px;
    height: 40px;
    line-height: 20px;
    text-align: left;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #818181;
}



























.GameYearBox{
    width: 960px;
    height: 182px;
    margin: 20px auto;
    background-color: #FFFFFF;
    box-shadow: 1px 6px 10px 1px rgba(0, 0, 0, 0.18);
}
.GameYearNumBox{
    position: relative;
    float: left;
    width: 250px;
    height: 182px;
}
.GameYearNum{
    position: absolute;
    top: 22px;
    left: 60px;
    width: auto;
    line-height: 90px;
    text-align: center;
    font-size: 86px;
    font-weight: bold;
    font-family: DFPSongW9,sans-serif;
    color: #666666;
    letter-spacing: 0.02em;
}
.GameYearWord{
    position: absolute;
    bottom: 30px;
    right: 20px;
    width: auto;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold;
    font-family: DFPSongW9,sans-serif;
    color: #666666;
    letter-spacing: 0.02em;
}
.GameYearContentBox{
    float: right;
    width: 710px;
    height: 182px;
}
.GameYearContentTit{
    width: 640px;
    height: 22px;
    line-height: 22px;
    margin: 16px 0;
    text-align: left;
    font-size: 16px;
    font-family: MicrosoftYaHeiBold,sans-serif;
    color: #222222;
    letter-spacing: 0.05em;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    word-break: break-word;
}
.GameYearContent{
    width: 644px;
    height: 54px;
    line-height: 18px;
    text-align: justify;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    font-weight: bold;
    color: #666666;
    letter-spacing: 0.12em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: normal !important;
}
.GameYearMore{
    width: 644px;
    height: 18px;
    margin-top: 24px;
    border-left: 4px solid #FB621E;
}
.GameYearMore span{
    display: block;
    float: right;
    line-height: 18px;
    text-align: center;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    font-weight: bold;
    color: #666666;
    letter-spacing: 0.12em;
    cursor: pointer;
}
.GameYearMore span:hover{
    color: #FB621E;
}


.GYInfoTitle{
    width: 800px;
    height: auto;
    min-height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 22px;
    font-family: SimSun,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    margin: 0 auto;
    padding: 40px 0 0 0;
    color: #030303;
}
.GYInfoTimeTitle{
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 16px;
    font-family: SimSun,sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    margin: 20px 0 40px 0;
    color: #B03A5B;
}
.GYInfoContent{
    width: 960px;
    height: auto;
    min-height: 32px;
    margin: 0 auto;
    padding-bottom: 80px;
}











.NotGameTip{
    margin: 80px auto;
}
.NotGameTipTopImage{
    position: relative;
    width: 401px;
    height: 186px;;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../round/roundimage/TypeFail.png");
}
.NotGameTipConBox{
    position: relative;
    width: 397.6px;
    height: 214px;
    background-color: #FFFFFF;
    border-radius: 0 0 10px 10px;
}









/*网赛排行榜*/
.NetGameListTitleBox{
    width: 1000px;
    height: 36px;
    margin: 0 auto;
    padding-top: 10px;
}
.GameWordPublic{
    text-align:left;
    font-size: 13px;
    font-family: PingFangRegular,sans-serif;
    font-weight: bold;
    letter-spacing: 0.01em;
    color: #030303;
    text-indent: 0.1em;
}
.NetGameListBox{
    width: 960px;
    height: 880px;
    margin: 10px auto;
    border: 2px solid #030303;
}
.NetGameTitleBox{
    width: 100%;
    height: 32px;
    line-height: 32px;
    border-bottom: 1px solid #030303;
}
.NetGameTitleList{
    height: 32px;
    float: left;
    text-align: center;
    text-indent: unset;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: normal !important;
}
.NetGameListMainBox{
    width: 100%;
    height: auto;
}




.perBoundCoachBox{
    float: right;
    width: 374px;
    height: 240px;
}
.perBoundCoachWord{
    float: left;
    width: 374px;
    height: 36px;
    line-height: 36px;
    text-align:left;
    font-size: 13px;
    font-family: PingFangRegular,sans-serif;
    font-weight: bold;
    letter-spacing: 0.01em;
    color: #ff0000;
    text-indent: 0.1em;
}



.TopOperateBtn{
    float: right;
    width: auto;
    height: 28px;
    margin: 16px 0;
    color: #fff;
    background-color: #1C7DC4;
    cursor: pointer;
}
.TopOperateBtnWord{
    float: left;
    width: auto;
    padding: 0 10px 0 0;
    height: 28px;
    line-height: 28px;
    text-align: left;
    text-indent: 0.6em;
    font-size: 14px;
    font-family: PingFangRegular,sans-serif;
    font-weight: bold;
    letter-spacing: 0.2em;
    color: #fff;
}
.ZXXIco {
    display: inline-block;
    height: 1em; width: 1em;
    font-size: 20px;
    box-sizing: border-box;
    text-indent: -9999px;
    vertical-align: middle;
    position: relative;
}
.ZXXIco::before,
.ZXXIco::after {
    content: '';
    box-sizing: inherit;
    position: absolute;
    left: 50%; top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-80%, -50%);
}
.brushIco {
    float: left;
    margin: 4px;
}
.brushIco::before {
    width: .9em; height: .9em;
    border: 2px solid;
    border-right-color: transparent;
    border-radius: 50%;
}
.sanjiao{
    position: absolute;
    width: 0;
    height: 0;
    border: .22em solid;
    border-left-color: transparent;
    border-top-color: transparent;
    left: 50%; top: 0;
}

.PerRemarkWord{
    width: 100%;
    height: auto;
    min-height: 30px;
    line-height: 30px;
    text-align:left;
    font-size: 14px;
    font-family: PingFangRegular,sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #ff0000;
}




/*2022.06.09*/
.GroupTitle{
    width: 100%;
    height: 24px;
    line-height: 24px;
    text-align: left;
    font-size: 16px;
    font-family: PingFangRegular,sans-serif;
    font-weight: bold;
    letter-spacing: 0.06em;
    color: #020202;
    margin: 10px 0;
}
.BackIcoBox{
    position: relative;
    float: left;
    width: 20px;
    height: 20px;
    margin: 4px 0 0 4px;
}
.BackIcoBorder{
    position: absolute;
    left: 0;
    width: 18px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffffff;
}


.PopUpTipsWord1{
    width: 94%;
    height: 32px;
    line-height: 32px;
    text-align: left;
    text-indent: 2em;
    font-size:16px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    color:#111111;
    margin: 2px auto;
}
.PopUpBtn{
    width: 120px;
    height: 40px;
    margin: 40px auto;
    border-radius: 6px;
    background-color: #1C7DC4;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    font-size:18px;
    font-family:PingFangBold,sans-serif;
    font-weight: bold;
    letter-spacing: 0.2em;
    text-indent: 0.2em;
    color:#ffffff;
}


.ZXIco {
    display: inline-block;
    height: 1em; width: 1em;
    font-size: 20px;
    box-sizing: border-box;
    text-indent: -9999px;
    vertical-align: middle;
    position: relative;
}
.ZXIco::before,
.ZXIco::after {
    content: '';
    box-sizing: inherit;
    position: absolute;
    left: 50%; top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.downIco {
    float: left;
    margin: 4px;
    border: solid transparent;
    border-width: 0 1px;
    box-shadow: inset 0 -2px;
}
.downIco::before {
    width: .5em; height: .5em;
    border-style: solid;
    border-width: 2px 0 0 2px;
    -ms-transform: translate(-50%, -.3em) rotate(-135deg);
    transform: translate(-50%, -.3em) rotate(-135deg);
}
.downIco::after {
    height: .6em;
    border-left: 2px solid;
    top: 40%;
}

