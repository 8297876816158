.clearfix:after{
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
body,ul{
    margin:0;
    padding:0;
}
div{
    outline:none;
}
ul,li{
    display:block;
    list-style: none;
}
.AskOutBox{
    width: 1200px;
    height: auto;
    margin: 0 auto;
}
.AskTitle{
    width: 100%;
    height: 180px;
    line-height: 180px;
    font-size: 42px;
    font-weight: 400;
    text-align: center;
    color: #000000;
}
.AskExplain{
    position: relative;
    width: 100%;
    height: 226px;
    background-image: url("./Images/考试说明@2x.png");
    background-size: 100% 226px;
    background-repeat: no-repeat;
}
.AskNumBer{
    position: absolute;
    top: 78px;
    left: 208px;
    width: auto;
    height: 32px;
    line-height: 30px;
    font-size: 20px;
    font-weight: 500;
    color: #000000;
}
.BorderDashed{
    width: 880px;
    height: 1px;
    margin: 60px auto;
    border-bottom: 2px dashed #DBDBDB;
}
.Asked{
    width: 1080px;
    height: auto;
    margin: 20px auto;
}
.AskContent{
    width: 1080px;
    height: auto;
    margin: 40px auto;
}
.SubjectTitle{
    position: relative;
    width: 100%;
    height: auto;
    min-height: 32px;
}
.BracketsLeft,
.BracketsRight{
    float: left;
    width: auto;
    height: 32px;
    line-height: 18px;
    font-weight: 500;
    font-size: 16px;
    margin: 0 18px;
}
.SubjectCon{
    float: left;
    width: 990px;
    height: auto;
    min-height: 32px;
    line-height: 22px;
    font-weight: bold;
    font-family: SimSun,sans-serif;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0.1em;
}
.AnswerBox{
    display: block;
    width: 960px;
    height: auto;
    margin: 20px auto;
}
.AnswerList{
    float: left;
    width: 300px;
    height: auto;
    min-height: 32px;
    margin: 0 20px 0 0;
}
.AnswerListIco{
    float: left;
    width: 20px;
    height: 20px;
    margin: -2px 6px 0 0;
    background-image: url("./Images/椭圆 1@2x.png");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    cursor: pointer;
}
.AnswerListIcoHave{
    float: left;
    width: 20px;
    height: 20px;
    margin: -2px 6px 0 0;
    background-image: url("./Images/椭圆 1 拷贝@2x.png");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    cursor: pointer;
}
.AnswerListCon{
    display: block;
    float: left;
    width: 26px;
    line-height: 18px;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}
.AnswerListWord{
    float: left;
    width: 240px;
    line-height: 18px;
    font-weight: 400;
    font-size: 16px;
    text-align: justify;
    color: #000000;
}
.SubjectChoice{
    position: absolute;
    top: -10px;
    left: 10px;
    width:42px;
    height:42px;
    line-height: 40px;
    font-size:40px;
    font-weight:500;
    color: #198E3B;
}
.SubmitBtn{
    width: 167px;
    height: 52px;
    margin: 0 auto;
    background-image: url("./Images/提交按钮@2x.png");
    background-size: 167px 52px;
    background-repeat: no-repeat;
    cursor: pointer;
}
.Occlude{
    position: absolute;
    top: 80px;
    left: 376px;
    width: 100px;
    height: 32px;
    z-index: 1000;
    background-color: #fff;
}
.AskImageBox{
    width: 960px;
    height: 220px;
    margin: 10px auto;
}
.AskImage{
    float: left;
    display: block;
    width: auto;
    height: 220px;
    margin: 0 20px 0 0;
}