/*2022.11.30 21:47*/
/*北京市昌平 联通*/
/*阿耨Fred*/
.BaseVipBox{
    position: absolute;
    left: 80px;
    top: 0;
    width: auto;
    min-width: 250px;
    height: 84px;
}
.BaseVipIco{
    float: left;
    width: 42px;
    height: 46px;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin: 18px 0 20px 0;
    cursor: pointer;
}
.VipIcoNo{
    background-image: url('http://oss.asfcyy.com/richTextImage/9fcaf36c70c611ed9dc800163e3521a2.png');
}
.VipIcoYes{
    /*background-image: url('http://oss.asfcyy.com/richTextImage/0c16d29870c711ed9dc800163e3521a2.png');*/
    background-image: url('http://oss.asfcyy.com/richTextImage/660d514470e211ed9dc800163e3521a2.png');
}
.VipIcoWr{
    background-image: url('http://oss.asfcyy.com/richTextImage/90e5aea270ce11ed9dc800163e3521a2.png');
}
.BaseVipWord{
    float: left;
    width: auto;
    height: 46px;
    line-height: 46px;
    text-align: left;
    font-size: 17px;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #7E7E7E;
    margin: 20px 0 0 20px;
    cursor: pointer;
}
.NoVipBox{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 680px;
    height: 436px;
    background: #ffffff;
    box-shadow: -2.37px 2.37px 4.75px 0 rgba(0,0,0,0.16);
}
.NoVipTitBox{
    width: 100%;
    height: 84px;
    background-color: #0488CA;
}
.NoVipPeImg{
    float: left;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #fff;
    margin: 7px 1px 0 24px;
    overflow: hidden;
}
.NoVipPeImg img{
    display: block;
    width: 100%;
    height: 120%;
    margin: 0 auto;
}
.NoVipPeInfoBOx{
    float: left;
    width: 200px;
    height: 64px;
    line-height: 32px;
    text-align: left;
    font-size: 17px;
    font-family:PingFangRegular,sans-serif;
    font-weight: 500;
    color: #fff;
    margin: 10px 0 0 30px;
}
.NoVipMain{
    width: 92%;
    height: 240px;
    margin: 30px auto;
}
.NoVipPayBox{
    float: left;
    width: 170px;
    height: 238px;
    background: #ffffff;
    border: 1px solid rgba(112,112,112,0.44);
    border-radius: 16px;
    box-shadow: 2.37px 2.37px 4.75px 0 rgba(0,0,0,0.16);
}
.NoVipPayTit{
    width: 100%;
    height: 64px;
    border-bottom: 1.58px dashed #0488ca;
    line-height: 58px;
    text-align: center;
    font-size: 26px;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #000;
}
.NoVipPayBg{
    width: 162px;
    height: 143px;
    background-color: #0488ca;
    border-radius: 21px;
    margin: 14px auto;
    padding-top: 7px;
}
.NoVipPayBg2{
    width: 146px;
    height: 132px;
    border: 1px dashed #ffffff;
    border-radius: 21px;
    margin: auto;
}
.NoVipPay{
    width: 100%;
    height: 72px;
    line-height: 84px;
    text-align: center;
    font-size: 40px;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #fff;
}
.NoVipCodeBox{
    float: right;
    width: 424px;
    height: 238px;
    background: #ffffff;
    border: 1px solid rgba(112,112,112,0.39);
    box-shadow: 0 2.37px 4.75px 0 rgba(0,0,0,0.16);
}
.NoVipCodeTitBox{
    width: 100%;
    height: 42px;
}
.SMZF{
    float: left;
    width: auto;
    height: 32px;
    margin: 10px 0 0 16px;
    background-color: #0488ca;
    border-radius: 12px;
    line-height: 32px;
    text-align: center;
    font-size: 18px;
    font-family:PingFangRegular,sans-serif;
    font-weight: 500;
    color: #fff;
    padding: 0 20px ;
}
.XZF{
    float: right;
    width: auto;
    height: 42px;
    line-height: 52px;
    text-align: center;
    font-size: 16px;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #000;
    margin: 0 20px 0 0;
}
.NoVipPayCode{
    width: 140px;
    height: 140px;
    background-color: #ffffff;
    border: 1px solid #707070;
    margin: 10px auto;
}
.WeChatPayIco{
    float: left;
    width: 31px;
    height: 28px;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('http://oss.asfcyy.com/richTextImage/c55a384470d611ed9dc800163e3521a2.png');
}
.WeChatPayWord{
    float: left;
    width: auto;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 16px;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #000;
    margin-left: 10px;
}
.NoVipTip{
    width: 96%;
    height: 32px;
    background-color: #FFD6D6;
    margin: 20px auto;
    line-height: 32px;
    text-align: left;
    font-size: 15px;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #000;
    text-indent: 2em;
}
.VipMainTitBox{
    width: 100%;
    height: 48px;
}
.XFWord{
    float: right;
    width: auto;
    height: 48px;
    line-height: 48px;
    text-align: left;
    font-size: 28px;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    letter-spacing: 0.2em;
    color: #CEBC17;
    text-decoration: underline;
    margin-right: 20px;
    cursor: pointer;
}
.XFIco{
    float: right;
    width: 40px;
    height: 40px;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('http://oss.asfcyy.com/richTextImage/660d514470e211ed9dc800163e3521a2.png');
    margin-right: 10px;
    margin-top: 4px;
    cursor: pointer;
}
.VipIndexMain{
    overflow-y: auto;
}
.VipIndexList{
    width: 96%;
    height: auto;
    min-height: 300px;
    margin: 10px auto;
}
.VipIndexListTit{
    width: 100%;
    height: 37px;
    border-bottom: 1px solid #707070;
    line-height: 37px;
    text-align: center;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
}
.VipIndexListTitName{
    float: left;
    width: auto;
    font-size: 22px;
    color: #000000;
    text-indent: 0.5em;
}
.VipIndexListTitMore{
    float: right;
    width: auto;
    line-height: 37px;
    font-size: 16px;
    color: #0488CA;
    margin-right: 20px;
    text-decoration: underline;
    cursor: pointer;
}

.VipIndexListTit2{
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
}
.VipIndexListTitNum{
    float: right;
    width: auto;
    font-size: 16px;
    color: #0488CA;
    margin-right: 30px;
    cursor: pointer;
    text-decoration: underline;
}
.VipMainConBox{
    width: 100%;
    height: 240px;
    margin: 10px auto;
}
.VipMainConList{
    float: left;
    width: 284px;
    height: 236px;
    background: #ffffff;
    border: 1px solid rgba(0,0,0,0.27);
    border-radius: 10px;
    box-shadow: 0 2.37px 4.75px 0 rgba(0,0,0,0.16);
    margin: 0 16px;
    cursor: pointer;
}
.VipMainConImg{
    width: 100%;
    height: 166px;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}
.VipNameInfoBox{
    width: 100%;
    height: 68px;
}
.VipNameInfo{
    width: 92%;
    height: 40px;
    line-height: 20px;
    font-size: 16px;
    text-align: left;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #000000;
    letter-spacing: 0.1em;
    margin: 0 auto;
}
.VipIcoInfo{
    width: 86%;
    margin: 0 auto;
}
.VipIcoInfoWord{
    float: right;
    width: auto;
    height: 20px;
    line-height: 20px;
    font-size: 18px;
    text-align: left;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #CEBC17;
    letter-spacing: 0.1em;
}
.VipBackWord{
    float: left;
    width: auto;
    height: 48px;
    line-height: 48px;
    font-size: 24px;
    text-align: center;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #0488CA;
    letter-spacing: 0.1em;
    cursor: pointer;
}
.VipContentImg{
    width: 780px;
    height: 240px;
    margin: 0 auto;
    overflow: hidden;
}
.VipContentTitle{
    width: 100%;
    height: 32px;
    line-height: 32px;
    font-size: 28px;
    text-align: center;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #000000;
    letter-spacing: 0.1em;
    margin: 20px auto;
}
.VipConBigTitleBox{
    width: 100%;
    height: 40px;
    margin: 10px auto;
}
.VipConBigTitleBorder{
    float: right;
    width: 800px;
    height: 2px;
    background-color: #0488CA;
    margin: 19px 0 0 0;
}
.VipConBigTitleBg{
    float: right;
    width: 150px;
    height: 40px;
    background-color: #0488ca;
    border-radius: 20px;
    line-height: 40px;
    font-size: 18px;
    text-align: center;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.1em;
}
.VipContent{
    width: 70%;
    height: auto;
    min-height: 30px;
    line-height: 30px;
    font-size: 17px;
    text-align: justify;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #000;
    letter-spacing: 0.06em;
    text-indent: 2em;
    margin: 10px auto;
}
.VipContentBtn{
    float: right;
    width: 254px;
    height: 62px;
    background-color: #0488ca;
    border-radius: 32px;
    line-height: 62px;
    font-size: 26px;
    text-align: center;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.1em;
    cursor: pointer;
}
.PageTypeTitleWord{
    float: left;
    width: auto;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    text-align: center;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.1em;
    text-indent: 1em;
}
.PageTypeContent{
    width: 610px;
    height: 230px;
    background-color: #ffffff;
    border: 1px solid rgba(112,112,112,0.44);
    border-radius: 16px;
    box-shadow: 2.37px 2.37px 4.75px 0 rgba(0,0,0,0.16);
    margin: 10px auto;
}
.PageTypeContentList{
    width: 92%;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    text-align: left;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #000;
    letter-spacing: 0.1em;
    margin: 20px auto;
}
.PageTypeContentListTit{
    float: left;
    width: 100px;
    height: 32px;
}
.PageTypeContentListInput{
    float: left;
    width: 240px;
    height: 32px;
    background-color: #f5f5f5;
    border-radius: 10px;
}
.PageTypeContentListInput input{
    display: block;
    width: 236px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    text-align: left;
    font-family:PingFangRegular,sans-serif;
    font-weight: 500;
    color: #000;
    letter-spacing: 0.1em;
    border: 0;
    outline: none;
    text-indent: 1em;
    background-color: unset;
}
.PageTypeContentListInput textarea{
    display: block;
    line-height: 30px;
    font-size: 16px;
    text-align: left;
    font-family:PingFangRegular,sans-serif;
    font-weight: 500;
    color: #000;
    letter-spacing: 0.1em;
    border: 0;
    outline: none;
    text-indent: 1em;
    background-color: unset;
}
.PageTypeContentBtn{
    width: 360px;
    height: 52px;
    background-color: #0488ca;
    border-radius: 23px;
    margin: 20px auto;
    line-height: 52px;
    font-size: 20px;
    text-align: center;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.1em;
    cursor: pointer;
}
.PageTypeContentTit{
    width: 92%;
    height: 40px;
    line-height: 40px;
    font-size: 28px;
    text-align: center;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #000;
    letter-spacing: 0.1em;
    margin: 10px auto;
}
.PageTypeContentCon{
    width: 92%;
    height: 80px;
    line-height: 40px;
    font-size: 18px;
    text-align: justify;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #000;
    letter-spacing: 0.1em;
    margin: 10px auto;
    text-indent: 2em;
}
.PageTypeContentOkBtn{
    float: left;
    width: 130px;
    height: 40px;
    background-color: #0488ca;
    border-radius: 22px;
    line-height: 40px;
    font-size: 17px;
    text-align: center;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.1em;
    margin: 20px 30px;
    cursor: pointer;
}
.VipQYNumTitle{
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 40px;
    text-align: center;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #000;
    letter-spacing: 0.1em;
}
.VipQYNumTitleIco{
    float: left;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('http://oss.asfcyy.com/richTextImage/dbbf7c38726711ed9dc800163e3521a2.png');
}
.VipQYNumList{
    width: 900px;
    height: 148px;
    background-color: #ffffff;
    border: 1px solid #707070;
    border-radius: 13px;
    box-shadow: 0 4.75px 3.16px 0 rgba(0,0,0,0.16);
    margin: 10px auto;
}
.VipQYNumListImg{
    float: left;
    width: auto;
    max-width: 300px;
    overflow: hidden;
    height: 138px;
    margin: 5px 10px 0 10px;
}
.VipQYNumListCon{
    float: right;
    width: 560px;
    height: 148px;
    margin: 0 20px 0 0;
}
.VipQYNumListConLi{
    width: 100%;
    height: 70px;
    margin: 2px 0;
    line-height: 70px;
    font-size: 24px;
    text-align: right;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    color: #000;
    letter-spacing: 0.1em;
}




















.NoVipClose{
    position: absolute;
    top: -6px;
    right: -6px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    color: #ff0000;
    cursor: pointer;
    z-index: 999;
}
.icon-close::before {
    width: 90%;
    border-top: 6px solid;
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
}
.icon-close::after {
    height: 90%;
    border-left: 6px solid;
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
}



.icon-caret::before {
    border: .3em solid transparent;
    border-top-color: currentColor;
    -ms-transform: translate(-50%, -25%);
    transform: translate(-50%, -25%);
}






