.ExamAccountTitBox{
    width: 970px;
    height: 48px;
    margin: 0 auto;
    border-bottom: 2px solid #E5E5E5;
}

.NotExamConBox{
    width: auto;
    height: auto;
    min-height: 100px;
    margin: 112px auto;
}
.NotExamConBoxTit{
    width: 100%;
    height: 18px;
    line-height: 18px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #868686;
    margin: 0 0 44px 0;
}
.ExamStateBtn{
    display: block;
    width: auto;
    min-width: 169px;
    height: 43px;
    line-height: 42px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    border: 0;
    outline: none;
    color: #FFFFFF;
    background-color: #6EC2FF;
    border-radius:10px;
    cursor: pointer;
    margin: 0 auto;
    padding: 0 16px;
}



.ExamAccountName{
    float: left;
    width: auto;
    max-width: 700px;
    height: 20px;
    line-height: 20px;
    text-indent: 1em;
    text-align: left;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #000000;
    margin: 20px 10px 0 0;
    overflow: hidden;
    text-overflow:ellipsis;
}
.ExamMainBoxes{
    width: 800px;
    height: auto;
    margin: 0 auto;
}
.ExamMainBoxesTit{
    width: auto;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #242424;
}
.ExamTimeBox{
    width: 100%;
    height: 40px;
}
.ExamItemTitName{
    float: left;
    width: auto;
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #3E3E3E;
}
.ExamTimeActBox{
    float: left;
    width: auto;
    height: 24px;
    margin: 8px 0 0 16px;
}

.ExamTimeSiteBox{
    height: 124px;
    border: 1px solid #000000;
}
.ExamTimeListBox{
    width: 694px;
    height: 24px;
    margin: 26px auto;
}
.ExamTimeChBox{
    float: left;
    width: 16px;
    height: 16px;
    border: 2px solid #0068B7;
    margin: 2px 12px 0 0;
    cursor: pointer;
}
.ExamTimeSelectedBox{
    width: 10px;
    height: 10px;
    background-color: #0068B7;
    margin: 3px;
}
.ExamTimeListWord{
    float: left;
    width: auto;
    height: 24px;
    line-height: 24px;
    text-align: left;
    font-size: 16px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    letter-spacing: 0.15em;
    color: #3E3E3E;
}
.ExamPeopleNumBox{
    float: left;
    width: 80px;
    height: 22px;
    border: 1px solid #D5D5D5;
    border-radius: 5px;
}
.ExamPeopleNum{
    display: block;
    width: 68px;
    height: 22px;
    border: 0;
    outline: none;
    padding: 0 0 0 14px;
    text-align: left;
    line-height: 22px;
    font-size: 12px;
    font-weight: 500;
    font-family: MicrosoftYaHeiBold,sans-serif;
    color: #000000;
    letter-spacing: 0.15em;
    background-color: rgba(255,255,255,0);
}
.StartTimeBox{
    position: relative;
    float: left;
    width: 100px;
    height: 24px;
    background-color: #D5D5D5;
    border-radius:5px;
    cursor: pointer;
    line-height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: left;
    text-indent: 1.5em;
    letter-spacing: 0.1em;
    color: #3E3E3E;
}
.TimeActiveBox{
    position: absolute;
    top: 25px;
    left: 2px;
    width: 96px;
    height: auto;
    min-height: 60px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
    z-index: 99;
}
.TimeActiveList{
    width: 92px;
    height: auto;
    margin: 2px auto;
}
.TimeActiveList:hover{
    background-color: #6EC2FF;
}




.PoZheHao{
    float: left;
    width: 38px;
    height: 2px;
    background-color: #000000;
    margin-top: 12px;
    margin-right: 6px;
}














.ExamSubjectTit{
    width: 566px;
    border-bottom: 1px solid #000000;
}
.ExamSubjectIdBtn{
    float: right;
    width: 232px;
    height: 32px;
    margin: 7px 0 0 0;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
}
.SubjectIdBtnBox{
    float: left;
    width: 115px;
    height: 32px;
    border-left: 1px solid #000000;
    line-height: 32px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.2em;
    color: #000000;
    cursor: pointer;
}
.ExamSubjectActiveBox{
    height: auto;
    min-height: 138px;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
}
.SubjectListBoxes{
    width: 795px;
    height: auto;
    min-height: 138px;
    margin: 0 auto;
}
.SubjectListBox{
    float: left;
    width: 265px;
    height: 26px;
    margin: 10px 0;
}
.SubjectListWord{
    float: left;
    width: auto;
    height: 26px;
    line-height: 26px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #3E3E3E;
}
.ExamRoomInputBox{
    float: right;
    width: 250px;
    height: 28px;
    border:1px solid #1C7DC4;
    border-radius:5px;
    margin: 5px 0 0 0;
}
.ExamRoomInput{
    display: block;
    width: 238px;
    height: 28px;
    border: 0;
    outline: none;
    padding: 0 0 0 10px;
    text-align: left;
    line-height: 28px;
    font-size: 14px;
    font-weight: 500;
    font-family: MicrosoftYaHeiBold,sans-serif;
    color: #000000;
    letter-spacing: 0.15em;
    background-color: rgba(255,255,255,0);
}
.PageAnnotate{
    width: 100%;
    height: 18px;
    line-height: 18px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #FF1D1D;
    margin: 36px 0 20px 0;
}
.ExamRoomInfoBtn{
    display: block;
    width:268px;
    height:48px;
    line-height: 48px;
    font-size: 22px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    border: 0;
    outline: none;
    cursor: pointer;
    background-color: #1C7DC4;
    box-shadow:0 2px 10px 0 rgba(0, 0, 0, 0.82);
    border-radius:5px;
    margin: 0 auto;
}

.ExamHaveHeader{
    width: 100%;
    height: 18px;
    padding: 18px 0;
    line-height: 18px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    letter-spacing: 0.15em;
    color: #868686;
}
.ExamHaveHeaderList{
    float: left;
}
.ExamHaveCon{
    width: 100%;
    height: 86px;
    margin: 18px 0;
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    letter-spacing: 0.15em;
    color: #000000;
}
.ExamHaveConList{
    float: left;
    word-break: break-all;
    overflow: hidden;
    text-overflow:ellipsis;
}
.AuditStateWord{
    float: left;
    width: auto;
    line-height: 20px;
    text-align: center;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #3E3E3E;
}
.AuditStateIco{
    width: 20px;
    height: 20px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../style/image/Checkmark.png");
    margin-left: 10px;
}
.CanceledIco{
    width: 20px;
    height: 20px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../style/image/cross.png");
    margin: 0 auto;
    cursor: pointer;
}






/*个人测试部分*/
.DropDownMenuBox{
    float: left;
    width:268px;
    height:28px;
    border:1px solid #1B7DC3;
    border-radius:5px;
    margin: 6px 0 0 14px;
}
.DropDownMenuWord{
    float: left;
    width:224px;
    height:28px;
    line-height: 28px;
    text-align: left;
    font-size: 12px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-indent: 1em;
    letter-spacing: 0.04em;
    color: #9B9B9B;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.DropDownMenuBtn{
    position: relative;
    float: right;
    width:44px;
    height:28px;
    background-color: #6DC1FF;
    border-radius:0 5px 5px 0;
    cursor: pointer;
}
.DropDownMenuListBox{
    position: absolute;
    left: -224px;
    top: 29px;
    width: 224px;
    height: auto;
    max-height: 186px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
    z-index: 99;
    overflow-y: auto;
}
.DropDownMenuList{
    width: 216px;
    height: 24px;
    margin: 2px auto;
    line-height: 24px;
    text-align: left;
    font-size: 12px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-indent: 1em;
    letter-spacing: 0.04em;
    color: #3E3E3E;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.DropDownMenuList:hover{
    background-color: #6DC1FF;
}
.ExamAddInfoBox{
    width: 795px;
    height: 30px;
    padding: 12px 0;
    margin: 0 auto;
}
.ExamAddInfoBtn{
    display: block;
    width:auto;
    min-width: 80px;
    height:30px;
    line-height: 28px;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.2em;
    color: #FFFFFF;
    border: 0;
    outline: none;
    cursor: pointer;
    background-color: #6DC1FF;
    border-radius:5px;
    padding: 0 16px;
    margin-left: 28px;
}
.ExamListContentBox{
    width: 100%;
    height: 222px;
    overflow-y: auto;
}
.ExamListTotalBox{
    width: 100%;
    height: 64px;
    background-color: #E4E4E4;
}
.ExamListTotal{
    float: left;
    width: auto;
    min-width: 200px;
    line-height: 64px;
    text-align: left;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-indent: 2em;
    letter-spacing: 0.2em;
    color: #000000;
}
.SettlementBoxes{
    width: 100%;
    height: 48px;
}
.SettlementWord{
    float: left;
    width: auto;
    min-width: 252px;
    max-width: 270px;
    line-height: 20px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.2em;
    color: #000000;
    margin-top: 28px;
}
.SettlementWordTotal{
    float: left;
    width: auto;
    max-width: 200px;
    line-height: 22px;
    text-align: left;
    font-size: 20px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.2em;
    color: #000000;
    margin-top: 26px;
    margin-left: 10px;
}
.SettlementBtnBox{
    float: right;
    width: 320px;
    height: 48px;
}
.SettlementBtn{
    display: block;
    width:158px;
    height:48px;
    line-height: 46px;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    border: 0;
    outline: none;
    cursor: pointer;
    background-color: #DBDBDB;
}

/*申请测试列表页面*/
.ExamHeaderListCheckbox{
    width: 18px;
    height: 16px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    cursor: pointer;
}











/*添加个人信息页面*/
.ExamAddPeopleBox{
    width: 762px;
    height: auto;
    border:1px solid #3F3F3F;
    margin: 10px auto;
}
.ExamAddPeopleConTit{
    width: 750px;
    height: 42px;
    border:1px solid #3F3F3F;
    margin: 5px auto;
    line-height: 42px;
    font-size: 20px;
    font-family:SimHei,sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.15em;
    color: #3F3F3F;
}
.ExamAddPeopleConBox{
    width: 750px;
    height: auto;
    border:1px solid #3F3F3F;
    margin: 5px auto;
}
.ExamAddPeopleInfoBox{
    width: 100%;
    height: 193px;
    border-bottom: 1px solid #3F3F3F;
}
.ExamAddPeopleInfo{
    float: left;
    width: 374px;
    height: 193px;
}
.ExamAddPeopleItemTit{
    width: auto;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0.15em;
    color: #009944;
    margin-left: 12px;
}
.ExamAddPeInfoBox{
    width: 100%;
    height: 143px;
    margin-top: 10px;
}
.ExamAddPeInfoPhotoBox{
    float: left;
    width: 95px;
    height: 127px;
    margin-left: 38px;
}
.ExamAddPeInfoPhotoBox img{
    display: block;
    width: 100%;
    height: 100%;
}
.ExamAddPeInfoWordBox{
    float: right;
    width: 240px;
    height: 100px;
    margin-top: 20px;
}
.ExamAddPeInfoWord{
    width: 100%;
    height: 40px;
    line-height: 40px;
}
.ExamAddPeInfoWordTit{
    float: left;
    width: 90px;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: right;
    letter-spacing: 0.15em;
    color: #868686;
}
.ExamAddPeInfoWordCon{
    float: right;
    width: 150px;
    font-size: 16px;
    font-family:SimHei,sans-serif;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0.15em;
    color: #3F3F3F;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.ExamAddPeopleSeparate{
    width:624px;
    height: 3px;
    margin: 0 auto;
    background-color: #E5E5E5;
}
.ExamAddPeopleSelectSubjectBox{
    width:100%;
    height: auto;
    max-height:328px;
    margin: 0 auto;
}
.ExAddPeSeHeaderBox{
    width: 100%;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.15em;
    color: #868686;
}
.ExAddPeSeHeaderList{
    float: left;
}
.ExAddPeSeContentBox{
    width: 100%;
    height: auto;
    max-height: 296px;
    overflow-y: auto;
}
.ExAddPeSeContentListBox{
    width: 100%;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    font-family:SimHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.15em;
    color: #3F3F3F;
}
.ExamAddPeopleRadioIco{
    float: left;
    width: 14px;
    height: 14px;
    border:2px solid #00B7EE;
    border-radius:50%;
    margin: 15px 20px 0 130px;
    cursor: pointer;
}
.ExamVoucherTitleBox{
    width: 740px;
    height: 40px;
    margin: 0 auto;
    background-color: #E5E5E5;
}
.VoucherTitleIco{
    float: left;
    width: 16px;
    height: 16px;
    background-color: #000000;
    border-radius:50%;
    margin: 12px 20px;
}
.VoucherTitleWord{
    float: left;
    width: auto;
    line-height: 40px;
    font-size: 20px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0.2em;
    color: #181818;
}
.ExamVoucherContentBox{
    width: 740px;
    height: 132px;
    margin: 10px auto;
}
.ExamVoucherBox{
    position: relative;
    width: 432px;
    height: 132px;
    margin: 0 auto;
}
.ExamVoucherNotWord{
    line-height: 120px;
    font-size: 24px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.15em;
    color: #868686;
}
.ExamVoucherBgImg{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./examimage/ExamVoucherBgImg.png");
}
.ExamVoucherBtn{
    position: absolute;
    top: 57px;
    width: 21px;
    height: 25px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}
.LeftBtn{
    left: -84px;
    background-image: url("../../style/image/leftbtn.png");
}
.RightBtn{
    right: -84px;
    background-image: url("../../style/image/rightbtn.png");
}
.VoucherInfoMoney{
    float: left;
    width: auto;
    max-width: 148px;
    line-height: 132px;
    font-size: 78px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: 500;
    text-align: center;
    color: #222222;
    margin-left: 24px;
}
.VoucherTitPosition{
    position: absolute;
    top: 33px;
    left: 168px;
    width: auto;
    height: 20px;
    line-height: 20px;
    font-size: 18px;
    font-family:PingFangRegular,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.2em;
    color: #4B4B4B;
}
.Voucher￥Position{
    position: absolute;
    top: 76px;
    left: 160px;
    width: auto;
    height: 20px;
    line-height: 20px;
    font-size: 18px;
    font-family:PingFangBold,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.2em;
    color: #222222;
}
.VoucherRuleBox{
    float: right;
    width: auto;
    height: 40px;
    margin: 64px 20px 0 0;
}
.VoucherRuleList{
    width: auto;
    height: 20px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.02em;
}
.VoucherRuleTitle{
    float: left;
    width: auto;
    font-size: 10px;
    font-family:PingFangRegular,sans-serif;
    color: #4B4B4B;
    margin-right: 10px;
}
.VoucherRuleContent{
    float: left;
    width: auto;
    font-size: 13px;
    font-family:MicrosoftYaHei,sans-serif;
    color: #000000;
}
.ExAddPeTotalMoney{
    float: right;
    width: auto;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.15em;
    color: #EB3D00;
    margin-right: 10px;
}
.ExAddPe￥Money{
    float: right;
    width: auto;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.15em;
    color: #FF6E02;
    margin-right: 10px;
}
.ExAddPeTotalWord{
   margin: 0;
    color: #000000;
}
.ExamAddPeopleBtnBox{
    display: table;
    width: auto;
    min-width: 200px;
    height: 48px;
    margin: 18px auto;
}
.ExamAddPeopleBtn{
    display: block;
    float: left;
    width: 126px;
    height: 48px;
    line-height: 46px;
    font-size: 20px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.2em;
    color: #000000;
    border: 0;
    outline: none;
    cursor: pointer;
    background-color: #6EC2FF;
    border-radius: 10px 0 0 10px;
}
.ExamCancelBtn{
    border: 2px solid #6EC2FF;
    background-color: #FFFFFF;
    border-radius: 0 10px 10px 0;
}
.DeleteCheckBtnBox{
    position: absolute;
    top: -44px;
    right: 0;
    width: auto;
    min-width: 112px;
    height: 40px;
    background-color: #FF6E6E;
    border-radius:10px;
    line-height: 40px;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    cursor: pointer;
}
.ExamPeInfoListBottomBox{
    width: 100%;
    height: 76px;
    margin: 0 auto;
    background-color: #E5E5E5;
}
.ExamPeInfoListPayBtn{
    display: block;
    float: right;
    width: 166px;
    height: 48px;
    line-height: 46px;
    font-size: 20px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    border: 0;
    outline: none;
    cursor: pointer;
    background-color: #1C7EC4;
    border-radius:24px;
    margin: 14px 36px 0 36px;
}


/*变更信息编辑页面*/
.ChangeEditorBox{
    width: 100%;
    height: auto;
    min-height: 200px;
    margin: 40px 0;
}
.ChangeEditorMainBox{
    float: left;
    width: 426px;
    height: auto;
    min-height: 200px;
}
.ChangeEditorListBox{
    width: 100%;
    height: 30px;
}
.ChangeEditorListTit{
    float: left;
    width: auto;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.15em;
    color: #3E3E3E;
    margin-right: 14px;
}
.ChangeEditorSaveBtn{
    display: block;
    width: 238px;
    height: 48px;
    line-height: 46px;
    font-size: 20px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    border: 0;
    outline: none;
    cursor: pointer;
    background-color: #1C7EC4;
    box-shadow:1px 2px 10px 1px rgba(0, 0, 0, 0.82);
    border-radius:5px;
    margin: 120px auto;
}


/*团体测试部分*/
.ExamAddInfoTitle{
    float: left;
    width: auto;
    height: 30px;
    line-height: 30px;
    text-align: left;
    font-size: 16px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    letter-spacing: 0.15em;
    color: #3E3E3E;
    margin-left: 28px;
    margin-right: 18px;
}
.InputBoxMenuBoxes{
    float: left;
    width:326px;
    height:28px;
    border:1px solid #1B7DC3;
    border-radius:5px;
}
.InputBoxMenu{
    display: block;
    float: left;
    width:256px;
    height:26px;
    line-height: 26px;
    text-align: left;
    font-size: 14px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    text-indent: 1em;
    letter-spacing: 0.15em;
    color: #8D8D8D;
    border: 0;
    outline: none;
    border-radius:5px;
}
.InputBoxMenuBtn{
    display: block;
    float: right;
    width:66px;
    height:28px;
    line-height: 26px;
    text-align: center;
    font-size: 14px;
    font-family:MicrosoftYaHeiBold,sans-serif;
    font-weight: 500;
    letter-spacing: 0.2em;
    color: #FFFFFF;
    background-color: #6EC2FF;
    border: 0;
    outline: none;
    border-radius:0 5px 5px 0;
    cursor: pointer;
}
.DropDownMenuBtn{
    position: relative;
    float: right;
    width:44px;
    height:28px;
    background-color: #6DC1FF;
    border-radius:0 5px 5px 0;
    cursor: pointer;
}

























/*添加个人信息绑定教练员*/
.ExamBoundCoach{
    float: right;
    width: 374px;
    height: 193px;
    background-color: #E5E5E5;
}
.ExamBoundCoachTitBox{
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0.15em;
    color: #009944;
}
.ExamBoundCoachTitle{
    float: left;
    margin-left: 12px;
}
.ExamBoundCoachInputId{
    float: left;
    width: 176px;
    height: 26px;
    margin: 6px 0 0 20px;
    padding-left: 10px;
    border: 0;
    outline: none;
    line-height: 26px;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0.15em;
    color: #8B8B8B;
}
.ExamBoundCoachInputBtn{
    display: block;
    float: left;
    width: 66px;
    height: 28px;
    margin: 6px 0 0 0;
    line-height: 26px;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    border: 0;
    outline: none;
    cursor: pointer;
    background-color: #6EC2FF;
}
.ExamBoundCoachNot{
    width: 100%;
    height: 153px;
    line-height: 120px;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.15em;
    color: #393939;
}
.ExamBoundCoachInfoBox{
    width: 100%;
    height: 143px;
    margin-top: 10px;
}
.ExamBoundCoachInfoPhoto{
    float: left;
    width: 95px;
    height: 127px;
    margin-left: 38px;
}
.ExamBoundCoachInfoPhoto img{
    display: block;
    width: 100%;
    height: 100%;
}
.ExamBoundCoachInfo{
    float: right;
    width: 240px;
    height: 94px;
}
.ExamBoundCoachInfoWord{
    width: 100%;
    height: 40px;
    line-height: 40px;
}
.ExamBoundCoachInfoWordTit{
    float: left;
    width: 90px;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: right;
    letter-spacing: 0.15em;
    color: #868686;
}
.ExamBoundCoachInfoWordCon{
    float: right;
    width: 150px;
    font-size: 16px;
    font-family:SimHei,sans-serif;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0.15em;
    color: #3F3F3F;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.ExamBoundCoachBtn{
    display: block;
    width: 66px;
    height: 28px;
    margin: 0 auto;
    border-radius:5px;
    line-height: 26px;
    font-size: 14px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.3em;
    color: #FFFFFF;
    border: 0;
    outline: none;
    cursor: pointer;
    background-color: #6EC2FF;
}













.ExAddPeSeContentBox::-webkit-scrollbar,
.DropDownMenuListBox::-webkit-scrollbar,
.ExamListContentBox::-webkit-scrollbar,
.ExamMainOverflow::-webkit-scrollbar{
    width: 4px;
    height: 0;
}
.ExAddPeSeContentBox::-webkit-scrollbar-track-piece,
.DropDownMenuListBox::-webkit-scrollbar-track-piece,
.ExamListContentBox::-webkit-scrollbar-track-piece,
.ExamMainOverflow::-webkit-scrollbar-track-piece{
    width: 2px;
    background-color: #FFFFFF;
}
.ExAddPeSeContentBox::-webkit-scrollbar-thumb,
.DropDownMenuListBox::-webkit-scrollbar-thumb,
.ExamListContentBox::-webkit-scrollbar-thumb,
.ExamMainOverflow::-webkit-scrollbar-thumb{
    background-color: #00B7EE;
}
.ExAddPeSeContentBox::-webkit-scrollbar-thumb:hover,
.DropDownMenuListBox::-webkit-scrollbar-thumb:hover,
.ExamListContentBox::-webkit-scrollbar-thumb:hover,
.ExamMainOverflow::-webkit-scrollbar-thumb:hover{
    border-radius: 0;
}








/*后补2020.08.13*/
.ExamTrainIcoBox{
    position: relative;
    float: right;
    width: 28px;
    height: 28px;
    border-radius: 0 5px 5px 0;
    background-color: #1C7DC4;
    cursor: pointer;
}
.ExamTrainTitle{
    float: left;
    width: 630px;
    height: 28px;
    padding: 0 0 0 10px;
    text-align: left;
    line-height: 28px;
    font-size: 14px;
    font-weight: 500;
    font-family: MicrosoftYaHeiBold,sans-serif;
    color: #000000;
    letter-spacing: 0.15em;
    background-color: rgba(255,255,255,0);
}
.ExamTrainDownBox{
    position: absolute;
    top: 30px;
    left: 4px;
    width: 636px;
    height: auto;
    max-height: 180px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
    z-index: 99;
}
.ExamTrainList{
    width: 630px;
    height: 28px;
    margin: 2px auto;
    text-align: left;
    line-height: 26px;
    font-size: 16px;
    font-weight: 500;
    font-family: MicrosoftYaHeiBold,sans-serif;
    color: #000000;
    letter-spacing: 0.15em;
}
.ExamTrainList:hover{
    background-color: #6EC2FF;
}





/*后补2020.10.09*/
.ExamMoreListBox{
    width: 100%;
    height: 32px;
    margin: 10px auto;
    line-height: 30px;
    font-size: 16px;
    font-family: SimSun,sans-serif;
    font-weight:bold;
    color:#555555;
    cursor: pointer;
    transition: all 0.2s linear;
    text-align: center;
}
.ExamMoreListBox:hover{
    color: #FB611D;
}
.ExamMoreList{
    float: left;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    word-break:break-all
}
.ExamMoreRoomInfoTitle{
    width: 100%;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    font-family: MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.1em;
    color:#333333;
}
.ExamMoreRoomInfoBox{
    float: right;
    width: 500px;
    height: auto;
    margin-right: 20px;
    margin-top: 28px;
}
.ExamMoreRoomInfoListBox{
    width: 100%;
    height: auto;
    min-height: 32px;
    margin: 20px 0;
}
.ExamMoreRoomInfoListTitle{
    float: left;
    width: 120px;
    height: auto;
    line-height: 32px;
    text-align: right;
    font-size: 16px;
    font-family: MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.1em;
    color:#333333;
}
.ExamMoreRoomInfoListContent{
    float: right;
    width: 360px;
    height: auto;
    line-height: 32px;
    text-align: left;
    font-size: 14px;
    font-family: MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.1em;
    color:#000000;
}
















