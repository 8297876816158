.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}
.AuthBox {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('http://oss.asfcyy.com/richTextImage/70c4a2c853ec11eb9dc700163e3521a2.png');
}

/*页面公共部分*/
.AuthBg {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 162px);
}
.AuthBgColor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 440px;
}
.LoginAndForgetBox {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 876px;
  height: 438px;
}
.LoginAndForgetLogo {
  position: relative;
  float: left;
  width: 438px;
  height: 438px;
  background-color: rgba(255, 255, 255, 0.3);
}
.FSLogo {
  position: absolute;
  top: 97px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 190px;
  height: 207px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('http://oss.asfcyy.com/richTextImage/ee6938d05bbf11eb9dc700163e3521a2.png');
}
.FSTitle {
  position: absolute;
  right: 32px;
  bottom: 23px;
  width: auto;
  height: 14px;
  line-height: 14px;
  text-align: center;
  font-size: 12px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  color: #ffffff;
  letter-spacing: 0.2em;
}

/*登录页面*/
.LoginAndForgetContent {
  position: relative;
  float: left;
  width: 438px;
  height: 438px;
  background-color: #ffffff;
}
.LoginAccount,
.LoginPassword,
.LoginVerCode {
  width: 248px;
  height: 30px;
  margin: 0 auto;
}
.LoginAccount {
  margin-top: 72px;
  margin-bottom: 35px;
}
.LoginPassword {
  margin-bottom: 44px;
  position: relative;
}
.passwordIcon {
  width: 28px;
  height: 29px;
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}
.LoginAccountIco,
.LoginPasswordIco {
  float: left;
  width: 15px;
  height: 16px;
  margin: 7px 13px 7px 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.LoginAccountIco {
  background-image: url('./authimage/AccountIco.png');
}
.LoginPasswordIco {
  background-image: url('./authimage/PasswordIco.png');
}
.LoginAccountInput,
.LoginPasswordInput {
  display: block;
  float: left;
  width: 208px;
  height: 28px;
  border: 0;
  border-bottom: 2px solid #888888;
  outline: none;
  padding: 0 0 0 12px;
  text-align: left;
  line-height: 28px;
  font-size: 12px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  color: #888888;
  caret-color: #195786;
  background-color: rgba(255, 255, 255, 0);
}
.LoginVerCodeInput {
  display: block;
  float: left;
  width: 82px;
  height: 28px;
  margin: 0 6px 0 28px;
  border: 0;
  border-bottom: 2px solid #888888;
  outline: none;
  padding: 0 0 0 8px;
  text-align: left;
  line-height: 28px;
  font-size: 14px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  color: #888888;
  caret-color: #195786;
  background-color: rgba(255, 255, 255, 0);
}
.LoginVCBox {
  float: left;
  width: 90px;
  height: 30px;
}
.LoginVCBox img {
  display: flex;
  width: 100%;
  height: 100%;
}
.VCRefresh {
  float: right;
  width: 22px;
  height: 20px;
  margin: 5px 0 5px 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('./authimage/Refresh.png');
  cursor: pointer;
  transition: all 0.6s linear 0.1s;
}
.LoginButton {
  display: block;
  width: 200px;
  height: 36px;
  margin: 0 auto;
  border: 0;
  outline: none;
  background-color: #1c7dc4;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.82);
  border-radius: 5px;
  cursor: pointer;
  line-height: 34px;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.2em;
  color: #ffffff;
}
.LoginButton {
  margin-top: 48px;
  margin-bottom: 22px;
}
.ForgetBtn {
  display: table;
  width: auto;
  height: 16px;
  margin: 0 auto;
  line-height: 16px;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  font-weight: bold;
  color: #303030;
  cursor: pointer;
  transition: all 0.2s linear;
}
.ToRegister {
  position: absolute;
  right: 20px;
  bottom: 24px;
  width: auto;
  height: 16px;
  line-height: 16px;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  font-weight: bold;
  color: #303030;
  cursor: pointer;
  transition: all 0.2s linear;
}
.ForgetBtn:hover,
.ToRegister:hover {
  color: rgba(48, 48, 48, 0.8);
}

/*忘记密码页面*/
.ForgetTitle {
  width: 100%;
  height: 58px;
  background-color: #0068b7;
}
.ForgetTitleCenter {
  width: 130px;
  height: 26px;
  margin: 0 auto;
  padding: 16px 0;
}
.ForgetTitleIco {
  float: left;
  width: 24px;
  height: 26px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('./authimage/ForgetTitleIco.png');
}
.ForgetTitleWord {
  float: right;
  width: auto;
  line-height: 26px;
  font-size: 18px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  color: #ffffff;
}
.ForgetStepOne,
.ForgetStepTwo {
  width: 220px;
  height: auto;
  margin: 26px auto;
}
.ForgetStepThree {
  width: 100%;
  height: auto;
  margin: 26px auto;
}
.IdtBox {
  width: 174px;
  height: 40px;
  margin: 0 auto;
}
.IdtTitle {
  width: 164px;
  height: 40px;
  margin: 0 auto;
}
.IdtBox,
.IdtTitle {
  margin-bottom: 40px;
}
.IdtPeople,
.IdtGroup {
  float: left;
  width: 82px;
  height: 39px;
  line-height: 36px;
  text-align: center;
  font-size: 18px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #888888;
  border-bottom: 1px solid #888888;
  cursor: pointer;
}
.IdtEmpty {
  float: left;
  width: 10px;
  height: 39px;
  border-bottom: 1px solid #888888;
}
.IdOrComInput,
.PhoneInput {
  display: block;
  width: 212px;
  height: 28px;
  margin: 0 0 20px 0;
  border: 0;
  border-bottom: 2px solid #888888;
  outline: none;
  padding: 0 0 0 8px;
  text-align: left;
  line-height: 28px;
  font-size: 12px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  color: #888888;
  caret-color: #195786;
  background-color: rgba(255, 255, 255, 0);
}
.ForgetCodeBox {
  width: 100%;
  height: 28px;
  margin-bottom: 50px;
}
.ForgetCodeInput {
  display: block;
  float: left;
  width: 126px;
  height: 26px;
  border: 0;
  border-bottom: 2px solid #888888;
  outline: none;
  padding: 0 0 0 8px;
  text-align: left;
  line-height: 28px;
  font-size: 12px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  color: #888888;
  caret-color: #195786;
  background-color: rgba(255, 255, 255, 0);
}
.ForgetCodeBtn {
  display: block;
  float: left;
  width: 86px;
  height: 28px;
  border: 0;
  outline: none;
  background-color: #6ec2ff;
  cursor: pointer;
  line-height: 26px;
  font-size: 12px;
  font-family: SimSun, sans-serif;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #ffffff;
}
.ForgetStepBtn {
  display: block;
  width: 200px;
  height: 36px;
  border: 0;
  outline: none;
  margin: 20px auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.82);
  border-radius: 5px;
  background-color: #1c7dc4;
  cursor: pointer;
  line-height: 34px;
  font-size: 16px;
  font-family: SiMicrosoftYaHeiBoldmSun, sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  color: #ffffff;
}
.ReturnLogin {
  display: table;
  width: auto;
  height: 16px;
  margin: 0 auto;
  line-height: 16px;
  font-size: 14px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: bold;
  text-decoration: underline;
  letter-spacing: 0.15em;
  color: #888888;
  cursor: pointer;
  transition: all 0.2s linear;
}
.ReturnLoginLeft {
  float: left;
  color: #888888;
  text-decoration: underline;
  transition: all 0.2s linear;
}
.ReturnLoginRight {
  float: right;
  color: #888888;
  text-decoration: underline;
  transition: all 0.2s linear;
}
.ReturnLogin:hover,
.ReturnLoginLeft:hover,
.ReturnLoginRight:hover {
  text-decoration: none;
  color: rgba(136, 136, 136, 0.8);
}
.IdtTitlePeIco,
.IdtTitleComIco {
  float: left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.IdtTitlePeIco {
  width: 20px;
  height: 22px;
  margin: 9px 0 9px 0;
  background-image: url('./authimage/IdtTitlePeIco.png');
}
.IdtTitleComIco {
  width: 32px;
  height: 24px;
  margin: 8px 0 8px 0;
  background-image: url('./authimage/IdtTitleComIco.png');
}
.IdtTitleWord {
  float: right;
  width: auto;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: bold;
  color: #000000;
  letter-spacing: 0.15em;
}
.ForgetPassword {
  display: block;
  width: 212px;
  height: 28px;
  margin: 0 0 46px 0;
  border: 0;
  border-bottom: 2px solid #888888;
  outline: none;
  padding: 0 0 0 8px;
  text-align: left;
  line-height: 28px;
  font-size: 12px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  color: #888888;
  caret-color: #195786;
  background-color: rgba(255, 255, 255, 0);
}
.ForgetPasswordResult {
  display: table;
  width: auto;
  height: 18px;
  line-height: 18px;
  margin: 100px auto;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #888888;
}

/*注册公共部分*/
.RegisterPeAndCom {
  width: 1200px;
  height: auto;
  min-height: calc(100vh - 162px);
  margin: 0 auto;
  background-color: #ffffff;
}
.RegisterTypeTit {
  width: auto;
  height: 22px;
  line-height: 22px;
  font-size: 16px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: bold;
  color: #3e3e3e;
  padding: 0 0 0 342px;
}
.RegisterTypeBg {
  width: 100%;
  height: 68px;
  background-color: #e5e5e5;
}
.RegisterTypeBox {
  width: 344px;
  height: 54px;
  margin: 0 auto;
  padding-top: 14px;
}
.RegisterType {
  float: left;
  width: 162px;
  height: 54px;
  cursor: pointer;
}
.RegisterEmpty {
  float: left;
  width: 20px;
  height: 54px;
}
.RePeIco,
.ReComIco {
  float: left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.RePeIco {
  width: 20px;
  height: 22px;
  margin: 15px 0 17px 16px;
  background-image: url('./authimage/IdtTitlePeIco.png');
}
.ReComIco {
  width: 30px;
  height: 22px;
  margin: 16px 0 16px 12px;
  background-image: url('./authimage/IdtTitleComIco.png');
}
.ReWord {
  float: right;
  width: auto;
  height: 54px;
  margin: 0 12px 0 0;
  line-height: 54px;
  font-size: 22px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #3e3e3e;
}
.ReContentOdd {
  width: 560px;
  height: auto;
  margin: 0 auto;
}
.ReContentComplex {
  width: 1200px;
  height: auto;
  margin: 0 auto;
}
.InfoBoxOdd {
  width: 560px;
  height: 42px;
}
.InfoTitBox {
  float: left;
  width: 130px;
  height: 42px;
}
.InfoTit {
  float: right;
  width: 100px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #3e3e3e;
}
.ReInfoInputOdd {
  display: block;
  float: right;
  width: 408px;
  height: 38px;
  padding-left: 10px;
  border: 1px solid #1c7dc4;
  border-radius: 5px;
  outline: none;
  text-align: left;
  line-height: 38px;
  font-size: 16px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
  background-color: rgba(255, 255, 255, 0);
}
.ReInfoInputOddBox {
  float: right;
  width: 422px;
  height: 42px;
}
.NextButton {
  display: block;
  width: 268px;
  height: 48px;
  margin: 0 auto;
  background-color: #1c7dc4;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.82);
  border: 0;
  border-radius: 5px;
  outline: none;
  text-align: center;
  line-height: 48px;
  font-size: 22px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.2em;
  color: #ffffff;
  cursor: pointer;
}
.LastButton {
  display: block;
  width: 98px;
  height: 48px;
  margin: 0 auto;
  background-color: #ffffff;
  border: 0;
  outline: none;
  text-align: center;
  text-decoration: underline;
  line-height: 48px;
  font-size: 22px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.2em;
  color: #575757;
  cursor: pointer;
}
.NextButton {
  margin-bottom: 20px;
}
.ReVrCodeInput {
  display: block;
  float: left;
  width: 110px;
  height: 38px;
  padding-left: 10px;
  border: 1px solid #1c7dc4;
  border-radius: 5px;
  outline: none;
  text-align: left;
  line-height: 38px;
  font-size: 16px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #000000;
  background-color: rgba(255, 255, 255, 0);
}
.ReVrCodeBtn {
  display: block;
  float: left;
  width: 138px;
  height: 42px;
  margin: 0 0 0 10px;
  background-color: #6ec2ff;
  border: 0;
  border-radius: 5px;
  outline: none;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ffffff;
  cursor: pointer;
}
.MustTip {
  width: 100%;
  height: 42px;
  text-align: center;
  line-height: 42px;
  font-size: 16px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #ff1d1d;
}
.AgreeBox {
  float: right;
  width: 16px;
  height: 16px;
  border: 1px solid #000000;
  margin: 12px 0 12px 0;
  cursor: pointer;
}
.AgreeBoxOk {
  width: 10px;
  height: 10px;
  margin: 3px;
  background-color: #1c7dc4;
}
.AgreeCon {
  float: right;
  width: auto;
  height: 42px;
  margin: 0 0 0 10px;
  text-align: center;
  line-height: 42px;
  font-size: 12px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #3e3e3e;
}
.AgreeCon a {
  text-decoration: none;
  color: #fb611d;
}
.ReSuccessBox {
  width: 330px;
  height: 54px;
  margin: 0 auto;
}
.ReSuccessIco {
  float: left;
  width: 54px;
  height: 54px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('./authimage/ReSuccessIco.png');
}
.ReSuccessWord {
  float: right;
  width: auto;
  height: 54px;
  line-height: 54px;
  font-size: 24px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: bold;
  letter-spacing: 0.15em;
  color: #888888;
}

/*个人身份证注册部分*/
.CardType {
  position: relative;
  float: right;
  width: 114px;
  height: 42px;
  background-color: #6ec2ff;
  border-radius: 5px;
  cursor: pointer;
}
.CardTypeShow {
  float: left;
  width: auto;
  height: 42px;
  margin: 0 0 0 8px;
  text-align: center;
  line-height: 42px;
  font-size: 16px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #3e3e3e;
}
.CardTypeConBox {
  position: absolute;
  top: 42px;
  left: 0;
  width: 114px;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
}
.IdCard,
.OtherCard {
  width: 114px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #3e3e3e;
}
.IdCard:hover,
.OtherCard:hover {
  background-color: #8dcefd;
}

/*个人其他证件注册部分*/
.SexBox {
  float: left;
  width: 40px;
  height: 42px;
  margin: 0 0 0 48px;
  cursor: pointer;
}
.SexBoxIcoHave {
  float: left;
  width: 14px;
  height: 14px;
  margin: 14px 0;
  border-radius: 50%;
  background-color: #0068b7;
}
.SexBoxIcoNot {
  float: left;
  width: 12px;
  height: 12px;
  margin: 14px 0;
  border: 1px solid #0068b7;
  border-radius: 50%;
  background-color: #ffffff;
}
.SexBoxWord {
  float: right;
  width: auto;
  line-height: 42px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  letter-spacing: 0.15em;
  color: #3e3e3e;
}
.ToExamineBox {
  width: 1086px;
  height: auto;
  margin: 0 auto;
}
.ToExamineIco {
  width: 120px;
  height: 116px;
  margin: 0 auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('./authimage/ToExamineIco.png');
}
.ToExamineWordBox {
  width: 150px;
  height: 64px;
  margin: 52px auto;
}
.ToExamineWord {
  width: 150px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #575757;
}
.ToExamineStep {
  width: 100%;
  height: 3px;
  border: 1px solid #535353;
}
.ToExamineStepHave {
  float: left;
  width: 435px;
  height: 3px;
  background-color: #22ac38;
}
/*团体注册部分*/
.ReInfoGroupOddBox {
  position: relative;
  float: right;
  width: 418px;
  height: 42px;
  border: 1px solid #999999;
  border-radius: 5px;
  line-height: 42px;
  text-align: left;
  font-size: 16px;
  font-family: MicrosoftYaHeiBold, sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-indent: 1em;
  color: #999999;
}
.RightDownBox {
  position: relative;
  float: right;
  width: 56px;
  height: 42px;
  background-color: #6ec2ff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
.GroupNatureBox {
  position: absolute;
  top: 44px;
  left: 2px;
  width: 414px;
  height: auto;
  background-color: #ffffff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.GroupNatureList {
  width: 100%;
  height: 28px;
  line-height: 28px;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  text-indent: 2em;
  letter-spacing: 0.15em;
  color: #3e3e3e;
  cursor: pointer;
}
.GroupNatureList:hover {
  background-color: #8dcefd;
}
.GroupNatureName {
  float: left;
  width: 362px;
  height: 42px;
  line-height: 42px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  font-family: MicrosoftYaHeiBold, sans-serif;
  text-indent: 0.5em;
  letter-spacing: 0.15em;
  color: #3e3e3e;
  cursor: pointer;
}

/*关于登录INPUT的个性化设置*/
input.LoginAccountInput:focus,
input.LoginPasswordInput:focus,
input.IdOrComInput:focus,
input.PhoneInput:focus,
input.ForgetCodeInput:focus,
input.ForgetPassword:focus,
input.LoginVerCodeInput:focus {
  border-bottom: 2px solid #195786;
}
input.LoginAccountInput::-webkit-input-placeholder,
input.LoginVerCodeInput::-webkit-input-placeholder,
input.LoginPasswordInput::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #888888;
}
input.LoginAccountInput:-moz-placeholder,
input.LoginVerCodeInput:-moz-placeholder,
input.LoginPasswordInput:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #888888;
}
input.LoginAccountInput::-moz-placeholder,
input.LoginVerCodeInput::-moz-placeholder,
input.LoginPasswordInput::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #888888;
}
input.LoginAccountInput:-ms-input-placeholder,
input.LoginVerCodeInput:-ms-input-placeholder,
input.LoginAccountInput:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #888888;
}
input:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset !important;
}

/*其他*/
.SchoolLevelLinkList {
  width: 380px;
  height: 25px;
  margin: 4px auto;
  padding-left: 20px;
  line-height: 23px;
  font-size: 12px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: bold;
  text-align: left;
  letter-spacing: 0.1em;
  color: #575757;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #ffffff;
}
.SchoolLevelLinkList:hover {
  background-color: #6ec2ff;
}
.SchoolLinkListBox {
  position: absolute;
  top: 44px;
  left: 2px;
  width: 414px;
  height: auto;
  max-height: 152px;
  background-color: #ffffff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
  z-index: 97;
  overflow-y: auto;
}
.SchoolWordBox {
  width: 380px;
  float: left;
  height: 42px;
  line-height: 42px;
  font-size: 12px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1em;
  color: #3e3e3e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.SchoolLinkBox {
  position: relative;
  float: left;
  width: 100%;
  height: 42px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #d5d5d5;
}
.SchoolLinkBox:focus {
  background-color: #6ec2ff;
}
.ReSuccessId {
  width: auto;
  min-width: 10px;
  height: 48px;
  margin: 0 auto;
  line-height: 48px;
  font-size: 22px;
  font-family: MicrosoftYaHei, sans-serif;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.15em;
  color: #888888;
}

/*2024.01.11密码修改部分*/
.SetupPasswordWord {
  float: left;
  width: 406px;
  height: 38px;
  line-height: 38px;
  text-align: left;
  text-indent: 1.5em;
  font-size: 15px;
  font-family: PingFangRegular, sans-serif;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: #ff4d4f;
  cursor: pointer;
}
