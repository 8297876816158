.clearfix:after{
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

/*弹窗部分*/
.PopUpBoxBg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 520px;
    background-color: rgba(0,0,0,0.6);
    z-index: 999999;
}
.PopUpBox{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 400px;
    height: 400px;
    border-radius: 10px;
}
.PopUpTopImage{
    position: relative;
    width: 401px;
    height: 186px;;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.TypeOk{
    background-image: url("./roundimage/TypeOk.png");
}
.TypeFail{
    background-image: url("./roundimage/TypeFail.png");
}
.TypeError{
    background-image: url("./roundimage/TypeError.png");
}
.TypeTip{
    background-image: url("./roundimage/TypeTip.png");
}
.CrossBtn{
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
}
.PopUpConBox{
    position: relative;
    width: 400px;
    height: 214px;
    background-color: #FFFFFF;
    border-radius: 0 0 10px 10px;
}
.PopUpContentBox{
    position: relative;
    width: 352px;
    height: 116px;
    padding: 24px;
}
.ErrorConBox,
.ContentBox{
    display: table;
    width: auto;
    min-width: 64px;
    height: auto;
    margin: 0 auto;
}
.ContentWord{
    float: left;
    width: auto;
    max-width: 352px;
    height: auto;
    min-height: 116px;
    overflow: hidden;
    text-align: left;
    line-height: 42px;
    font-size: 16px;
    font-weight: bold;
    font-family: MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.2em;
    color: #3E3E3E;
    word-break:break-all;
}
.ErrorIco{
    float: left;
    width: 42px;
    height: 42px;
    margin: 0 20px 0 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./roundimage/ErrorIco.png");
}
.ErrorWord{
    float: left;
    width: auto;
    max-width: 290px;
    height: auto;
    text-align: left;
    line-height: 42px;
    font-size: 16px;
    font-weight: bold;
    font-family: MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.2em;
    color: #F54746;
    word-break:break-all;
}
.ButtonBox{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 400px;
    height: 50px;
    border-radius: 0 0 10px 10px;
}
.OneBtnOk{
    display: block;
    width: 400px;
    height:50px;
    border: 0;
    border-radius: 0 0 10px 10px;
    outline: none;
    text-align: center;
    line-height: 48px;
    font-size: 18px;
    font-weight: bold;
    font-family: MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.2em;
    color: #FFFFFF;
    cursor: pointer;
}
.TwoBtnOk{
    display: block;
    float: right;
    width: 200px;
    height:50px;
    border: 0;
    border-radius: 0 0 10px 0;
    outline: none;
    text-align: center;
    line-height: 48px;
    font-size: 18px;
    font-weight: bold;
    font-family: MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.2em;
    color: #FFFFFF;
    cursor: pointer;
}
.TwoBtnOut{
    display: block;
    float: left;
    width: 200px;
    height:50px;
    border: 0;
    border-radius: 0 0 0 10px;
    outline: none;
    text-align: center;
    line-height: 48px;
    font-size: 18px;
    font-weight: bold;
    font-family: MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.2em;
    color: #FFFFFF;
    background-color: #DCDCDC;
    cursor: pointer;
}
.InfoPopUp{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 400px;
    height: 480px;
    background-color: #FFFFFF;
}
.InfoPopUpCrossBtn{
    position: absolute;
    top: 3px;
    right: 3px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./roundimage/cross.png");
}
.InfoPopUpImgBox{
    width: 96px;
    height: 130px;
    margin: 20px auto;
}
.InfoPopUpImgBox img{
    display: block;
    width: 100%;
    height: 100%;
}
.InfoPopUpListBox{
}
.InfoPopUpList{
    width: 360px;
    height: 24px;
    margin: 10px auto;
}
.InfoPopUpListTit{
    float: left;
    width: 160px;
    height: 24px;
    line-height: 24px;
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    font-family: MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.15em;
    color: #030303;
}
.InfoPopUpListCon{
    float: right;
    width: 200px;
    height: 24px;
    line-height: 24px;
    text-align: left;
    text-indent: 1em;
    font-size: 16px;
    font-weight: bold;
    font-family: MicrosoftYaHeiBold,sans-serif;
    letter-spacing: 0.15em;
    color: #030303;
}
.InfoPopUpBtnBox{
    position: absolute;
    bottom: 40px;
    left: 20px;
    width: 360px;
    height: 30px;
}
.InfoPopUpBtn{
    display: block;
    width: auto;
    min-width: 80px;
    height:30px;
    line-height: 28px;
    font-size: 16px;
    text-align: center;
    font-family:MicrosoftYaHei,sans-serif;
    letter-spacing: 0.25em;
    border: 0;
    outline: none;
    border-radius:5px;
    cursor: pointer;
    margin: 0 20px;
}















/*注册步骤部分*/
.RegisterStepBox{
    position: relative;
    width: 1084px;
    height: 34px;
    margin: 40px auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.ReStepBox1{
    background-image: url("./roundimage/ReStep1.png");
}
.ReStepBox2{
    background-image: url("./roundimage/ReStep2.png");
}
.ReStepBox3{
    background-image: url("./roundimage/ReStep3.png");
}
.ReStepBox4{
    background-image: url("./roundimage/ReStep4.png");
}
.ReStepWordBox{
    position: absolute;
    top: 0;
    width: 246px;
    height: 34px;
    line-height:32px;
    font-size:20px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight:bold;
    text-align: center;
    letter-spacing: 0.02em;
    color:#999999;
}
.ReStepOne{
    left: 0;
}
.ReStepTwo{
    left: 278px;
}
.ReStepThree{
    left: 556px;
}
.ReStepFour{
    left: 834px;
}




/*注册的省市县三级联动*/
.ThreeLevelLinkageBox{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.LevelLinkBox{
    position: relative;
    float: left;
    height: 100%;
    border-radius: 5px;
    cursor: pointer;
    background-color: #D5D5D5;
}
.LevelLinkBox:focus{
    background-color: #6EC2FF;
}
.LevelLinkWordBox{
    float: left;
    height: 100%;
    font-size:12px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight:bold;
    text-align: center;
    letter-spacing: 0.1em;
    color:#3E3E3E;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.LevelLinkListBox{
    position: absolute;
    top: 44px;
    left: 2px;
    width:210px;
    height: auto;
    max-height:172px;
    background-color: #FFFFFF;
    box-shadow:0 3px 8px 0 rgba(0, 0, 0, 0.5);
    z-index: 99;
    overflow-y:auto;

}
.LevelLinkList{
    width: 188px;
    height: 25px;
    margin: 0 auto;
    line-height: 24px;
    font-size:12px;
    font-family:MicrosoftYaHei,sans-serif;
    font-weight:bold;
    text-align: left;
    text-indent: 1em;
    letter-spacing: 0.1em;
    color:#575757;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    background-color: #FFFFFF;
}
.LevelLinkList:hover{
    background-color: #6EC2FF;
}
.LevelLinkListBox::-webkit-scrollbar{
    width: 10px;
    height: 10px;
}
.LevelLinkListBox::-webkit-scrollbar-track-piece{
    width: 2px;
    background-color: #DCDCDC;
}
.LevelLinkListBox::-webkit-scrollbar-thumb{
    background-color: #AAAAAA;
}
.LevelLinkListBox::-webkit-scrollbar-thumb:hover{
    border-radius: 0;
}


/*分页器的轮子*/
.PaginationBox{
    display: table;
    width: auto;
    margin: 0 auto;
    height: 36px;
}
.PageNoMore,
.PageListBox,
.ActivePage{
    float: left;
    display: block;
    width: 32px;
    height: 32px;
    line-height: 30px;
    font-size:12px;
    font-family:MicrosoftYaHei,sans-serif;
    text-align: center;
    color:#000000;
    cursor: pointer;
    border-radius:5px;
    margin: 0 10px;
}
.PageNoMore{
    cursor: not-allowed;
    border: 2px solid #AAAAAA;
    opacity: 0.5;
}
.PageListBox{
    border: 2px solid #AAAAAA;
    background-color: #FFFFFF;
}
.ActivePage{
    border: 2px solid #6EC2FF;
    background-color: #6EC2FF;
}
.PrevIco{
    width: 10px;
    height: 12px;
    margin: 10px 0 0 11px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./roundimage/PrevIco.png");
}
.NextIco{
    width: 10px;
    height: 12px;
    margin: 10px 0 0 11px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./roundimage/NextIco.png");
}



/*自定义上传组件*/
.UploadBox{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}
.Upload{
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.FileNameBox{
    width: 100%;
    min-width: 200px;
    height: 32px;
}

.FileRemove{
    float: right;
    width: 20px;
    height: 20px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("./roundimage/RemoveBtn.png");
    cursor: pointer;
    margin: 6px 0 0 0;
}
.FileName{
    float: left;
    width: 150px;
    height: 32px;
    margin-left: 10px;
    line-height: 32px;
    font-size:12px;
    font-family:MicrosoftYaHei,sans-serif;
    text-align: left;
    letter-spacing: 0.1em;
    color:#000000;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}


.UploadBtn{
    display: block;
    width: auto;
    min-width:140px;
    height:30px;
    line-height: 28px;
    font-size: 16px;
    text-align: center;
    font-family:MicrosoftYaHei,sans-serif;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    background-color: #6EC2FF;
    border: 0;
    outline: none;
    border-radius:5px;
    cursor: pointer;
    margin: 20px auto;
}




/*单选框*/
.RadioSelect{
    display: table;
    width: auto;
    height: 32px;
    margin: 10px auto;
}
.RadioList{
    float: left;
    width: auto;
    height: 32px;
    margin: 0 60px;
}
.RadioIcoNot{
    float: left;
    width: 16px;
    height: 16px;
    border: 2px solid #60C7EF;
    border-radius: 50%;
    cursor: pointer;
    margin: 6px;
    background-color: #FFFFFF;
}
.RadioWord{
    float: left;
    width: auto;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 16px;
    font-family: MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.15em;
    color: #030303;
    cursor: pointer;
}
.RadioIcoHave{
    width: 10px;
    height: 10px;
    margin: 3px;
    border-radius: 50%;
    background-color: #FFFFFF;
}
.RadioList:hover .RadioIcoHave{
    background-color: #60C7EF;
}



/*GetURL*/
.GetURLRoundBox{
    width: 800px;
    height: 56px;
}
.GetURLTitAndBtn{
    width: 100%;
    height: 28px;
}
.GetURLTitle{
    float: left;
    width: auto;
    height: 28px;
    line-height: 28px;
    text-align: left;
    font-size: 14px;
    font-family: MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #030303;
}
.GetURLButton{
    position: relative;
    float: left;
    width: 120px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 14px;
    font-family: MicrosoftYaHei,sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #030303;
    background-color: #60C7EF;
    cursor: pointer;
    margin: 2px 4px 2px 0;
}
.GetURLLinkBox{
    width: 798px;
    height: 26px;
    border: 1px solid #ddd;
    line-height: 26px;
    text-align: left;
    text-indent: 1em;
    font-size: 12px;
    font-family: MicrosoftYaHei,sans-serif;
    font-weight: 500;
    letter-spacing: 0.06em;
    color: #030303;
}





/*用户信息弹窗20230414*/
.AccountInfoBox{
    position: relative;
    width: 540px;
    height: 260px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 1.88px 1.88px 3.75px 0 rgba(0,0,0,0.16);
    margin: 30px auto;
}
.AccountInfoPhoto{
    position: absolute;
    top: 40px;
    left: 20px;
    width: 140px;
    height: 180px;
    border: 1px solid #000;
}
.AccountInfoPhoto img{
    display: block;
    width: 100%;
    height: 100%;
}
.AccountInfoName{
    position: absolute;
    top: 30px;
    left: 200px;
    width: auto;
    height: 40px;
    color: #000;
    line-height: 40px;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    font-family: PingFangRegular,sans-serif;
}
.AccountInfoNum{
    position: absolute;
    top: 76px;
    left: 200px;
    width: auto;
    height: 30px;
    color: #000;
    line-height: 30px;
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    font-family: PingFangRegular,sans-serif;
}
.AccountInfoBtnBox{
    display: table;
    margin: 0 auto;
    height: 60px;
}
.AccountInfoBtn{
    float: left;
    width: 140px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    font-family: PingFangRegular,sans-serif;
    letter-spacing: 0.1em;
    border-radius: 12px;
    cursor: pointer;
    margin: 0 10px;
}






/*分页器的轮子2*/
.icon-arrow-left::before {
    height: .65em; width: .65em;
    border-style: solid;
    border-width: 2px 0 0 2px;
}


.PaginationBox2{
    width: 92%;
    height: 36px;
    margin: 0 auto;
}
.PrevIco2{
    float: left;
    margin: 8px;
    transform: rotate(-45deg);
}
.NextIco2{
    float: left;
    margin: 8px;
    transform: rotate(130deg);

}


.PageNoMore2{
    float: right;
    width: 36px;
    height: 36px;
    cursor: not-allowed;
    color: #999;
    margin: 0 16px;
}
.ActivePage2Box{
    float: right;
    display: block;
    width: auto;
    min-width: 120px;
    height: 36px;
}
.ActivePage2{
    float: left;
    width: auto;
    min-width: 48px;
    height: 28px;
    border: 2px solid #c6c6c6;
    margin: 2px 1px;
    border-radius: 4px;
    line-height: 28px;
    font-size:15px;
    font-family:PingFangBold,sans-serif;
    text-align: center;
    font-weight: bold;
    color: #222;
}
.ActivePageBorder{
    float: left;
    width: auto;
    height: 32px;
    margin: 2px 12px;
    line-height: 32px;
    font-size:17px;
    font-family:PingFangBold,sans-serif;
    text-align: center;
    font-weight: bold;
    color: #222;
}
.ActivePageAll{
    float: left;
    width: auto;
    min-width: 44px;
    height: 32px;
    margin: 2px 1px;
    line-height: 32px;
    font-size:17px;
    font-family:PingFangBold,sans-serif;
    text-align: center;
    font-weight: bold;
    color: #222;
}
.PageListBox2{
    float: right;
    width: 36px;
    height: 36px;
    cursor: pointer;
    color: #111;
    margin: 0 16px;
}









